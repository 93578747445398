import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import { FeedbackType } from "@/pages/threatslg/threatslg_types";

export function FeedbackButtons({
  onClick,
  enabled,
}: {
  onClick: (feedbackType: FeedbackType) => void;
  enabled: boolean;
}) {
  return (
    <ButtonGroup
      color="neutral"
      variant="outlined"
      disabled={!enabled}
      sx={{ flex: "0 0 auto", marginRight: "10px" }}
    >
      <Button
        onClick={() => onClick("spam")}
        title="Archive this threat(s) if containing possibly spam element"
      >
        <BlockOutlinedIcon />
        &nbsp; Spam
      </Button>
      <Button
        onClick={() => onClick("safe")}
        title="Archive this threat(s) if not representing any danger"
      >
        <CheckCircleRoundedIcon htmlColor="#50CE4C" />
        &nbsp; Safe
      </Button>
      <Button
        onClick={() => onClick("suspicious")}
        title="Archive this threat(s) if containing possibly suspicious elements"
      >
        <QuestionMarkOutlinedIcon htmlColor="orange" />
        &nbsp; Suspicious
      </Button>
      <Button
        onClick={() => onClick("unsafe")}
        title="Archive this threat(s) if containing threatening elements without any doubt"
      >
        <PriorityHighOutlinedIcon htmlColor="red" />
        &nbsp; Unsafe
      </Button>
    </ButtonGroup>
  );
}

export default FeedbackButtons;
