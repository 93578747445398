import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface MailsWithBannerChartData {
  date: string;
  bannerInfo: number;
  bannerSuspicious: number;
  bannerVerySuspicious: number;
}

export function BannerMailsWithBannerBarChart({
  dataset,
}: {
  dataset: Array<MailsWithBannerChartData>;
}) {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={dataset}
        margin={{ left: 0, top: 10, right: 10, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray={5} vertical={false} />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend
          formatter={(value) => (
            <span style={{ color: "rgb(102, 102, 102)" }}>{value}</span>
          )}
        />
        <Bar
          name="Informative"
          dataKey="bannerInfo"
          fill="hsl(240,10%,75%)"
          stackId="a"
        />
        <Bar
          name="Suspicious"
          dataKey="bannerSuspicious"
          fill="hsl(36,80%,60%)"
          stackId="a"
        />
        <Bar
          name="Very suspicious"
          dataKey="bannerVerySuspicious"
          fill="hsl(3.5,80%,60%)"
          stackId="a"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
