import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { useDispatch } from "react-redux";
import { subscribeAll } from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { useSnackbar } from "material-ui-snackbar-provider";
import { extractErrorMessage } from "@/utils/misc";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useTranslation } from "react-i18next";

export const SubscribeAllModal = ({
  openModal,
  setOpenModal,
  filteredCourse,
}) => {
  const { current_company } = useUserContext();
  const [subscribeAllLoading, setSubscribeAllLoading] = useState(false);
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const user = useUserContext();
  const { t } = useTranslation();

  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleConfirm = async () => {
    setSubscribeAllLoading(true);
    const courseIds = filteredCourse.map((course) => course.id);
    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_LEARNING +
          "/courses/subscriptions/multiple",
        {
          company_id: current_company.id,
          courses_id: courseIds,
        },
      );
      dispatch(subscribeAll(courseIds));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
    setSubscribeAllLoading(false);
    setOpenModal(false);
  };
  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle variant={"h3"} sx={{ fontSize: "24px" }}>
        {t("Subscribe all displayed courses")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography sx={{ fontSize: "14px" }}>
          {t(
            "You're about to assign {{nb_courses}} courses to all {{company}} employees. They will receive the courses gradually according to the delay between courses you specified.",
            {
              nb_courses: filteredCourse.length,
              company: user.current_company.name,
            },
          )}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={subscribeAllLoading}
          variant={"contained"}
          onClick={handleConfirm}
        >
          {t("Save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
