// On some navigators (IE), userLanguage is used
import { useEffect } from "react";

type AllNavigator = Navigator & { userLanguage?: string };
export function browserLanguage() {
  const nav = navigator as AllNavigator;
  const language = nav?.language || nav?.userLanguage;
  return language.split("-")[0].toLowerCase() === "fr" ? "fr" : "en";
}

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useOnlyOnceEffect = (func) => useEffect(func, []);
