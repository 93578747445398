import React, { useState } from "react";
import {
  Button,
  Card,
  ClickAwayListener,
  List,
  ListItem,
  Popper,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SelectOrderByComponent from "@/components/sort/SelectOrderByComponent";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { ColumnSort } from "@/pages/threatslg/threatslg_types";

type SortingColumn = {
  label: string;
  name: string;
};

const columns: SortingColumn[] = [
  { label: "Report Date", name: "report_date" },
  { label: "Banner level", name: "banner_level" },
  { label: "Source", name: "source" },
  { label: "Sender", name: "sender" },
  { label: "Recipient", name: "recipient" },
];

type SortToolsProps = {
  value: ColumnSort[];
  onChange: (value: ColumnSort[]) => void;
};

export const SortTools = ({
  value = [],
  onChange = () => {},
}: SortToolsProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLBaseElement | null>(null);
  const [listOrderBy, setListOrderBy] = useState<ColumnSort[]>(value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(!isModalOpen);
  };

  const handleAddSort = () => {
    setListOrderBy((prev) => [...prev, { direction: "asc", column: "" }]);
  };

  const handleRemoveItem = (index) => {
    setListOrderBy((prev) => prev.filter((_, i) => i !== index));
  };

  const handleOrderChange = (index, event) => {
    setListOrderBy((prev) =>
      prev.map((_, i) =>
        i === index ? { ...prev[i], direction: event.target.value } : prev[i],
      ),
    );
  };

  const handleOrderLabelChange = (index, value) => {
    setListOrderBy((prev) =>
      prev.map((_, i) =>
        i === index ? { ...prev[i], column: value } : prev[i],
      ),
    );
  };

  const applyFilter = () => {
    onChange(listOrderBy.filter((v) => v.column !== ""));
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        endIcon={isModalOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size={"medium"}
        variant={"outlined"}
        onClick={handleClick}
      >
        <ImportExportIcon />
      </Button>
      {isModalOpen && (
        <ClickAwayListener onClickAway={applyFilter}>
          <Popper
            open={isModalOpen}
            anchorEl={anchorEl}
            placement={"bottom-start"}
            style={{ zIndex: 120 }}
          >
            <Card sx={{ width: "400px" }} elevation={1}>
              <List dense>
                {listOrderBy.map((orderItem, index) => {
                  return (
                    <SelectOrderByComponent
                      index={index}
                      key={index}
                      orderChoice={columns.map((column) => column.name)}
                      handleOrderChange={handleOrderChange}
                      handleOrderLabelChange={handleOrderLabelChange}
                      orderItem={orderItem}
                      handleRemoveItem={handleRemoveItem}
                    />
                  );
                })}
                <ListItem>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddSort}
                    size={"small"}
                    sx={{ textTransform: "none" }}
                  >
                    {t("Add Sort")}
                  </Button>
                </ListItem>
                <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant={"contained"}
                    size={"small"}
                    onClick={applyFilter}
                  >
                    {t("Apply")}
                  </Button>
                </ListItem>
              </List>
            </Card>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
