import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import TrashIcon from "@/components/icons/TrashIcon";
import CopyIcon from "@/components/icons/CopyIcon";
import LanguageTabs from "./LanguagesTabs";
import { DeleteCourseModal } from "./DeleteCourseModal";
import { useCloneCourseMutation } from "@/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  selectCourseId,
  selectEditMode,
  selectIsProvidedByMantra,
} from "./courseSlice";
import { ButtonSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

const CourseEditorHeader = () => {
  const courseId = useSelector(selectCourseId);
  const editMode = useSelector(selectEditMode);
  const isProvidedByMantra = useSelector(selectIsProvidedByMantra);
  const [displayDeleteCourseModal, setDisplayDeleteCourseModal] =
    useState(false);
  const [cloneCourse, { isLoading: isLoadingCloneCourse }] =
    useCloneCourseMutation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const onClickCloneCourse = async () => {
    if (isLoadingCloneCourse) {
      return;
    }
    const response = await cloneCourse(courseId);

    // @ts-ignore
    if (response?.data?.id == null) {
      snackbar.showMessage("Error unable to clone course");
    }

    // @ts-ignore
    const id = response.data.id;
    snackbar.showMessage(t("course successfully cloned"));
    navigate(`/awareness/courses/${id}?cloned=true`);
  };

  return (
    <Box
      sx={{
        gap: "1rem",
        display: "flex",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <LanguageTabs />
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {!editMode ? (
          <Button
            size="small"
            variant="outlined"
            color="neutral"
            startIcon={isLoadingCloneCourse ? <ButtonSpinner /> : <CopyIcon />}
            disabled={isLoadingCloneCourse}
            onClick={onClickCloneCourse}
            sx={{ whiteSpace: "nowrap" }}
          >
            {t("Clone course")}
          </Button>
        ) : (
          ""
        )}
        {!isProvidedByMantra ? (
          <>
            <Button
              size="small"
              variant="outlined"
              color="neutral"
              startIcon={<TrashIcon />}
              onClick={() => setDisplayDeleteCourseModal(true)}
              sx={{ whiteSpace: "nowrap" }}
            >
              {t("Delete Course")}
            </Button>
            <DeleteCourseModal
              show={displayDeleteCourseModal}
              close={() => setDisplayDeleteCourseModal(false)}
            />
          </>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default CourseEditorHeader;
