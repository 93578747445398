import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const CollapseDepartmentAutocomplete = ({
  openCollapse,
  deployedDepartments,
  setDeployedDepartments,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const fetchOption = useCallback(async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const endpoint_url =
      import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/departments";
    const result = await axios.get(endpoint_url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const selectedDepartmentsId = deployedDepartments.map(
      (selectedDepartment) => selectedDepartment.id,
    );
    const departments = result.data.filter(
      (department) => !selectedDepartmentsId.includes(department.id),
    );
    setOptions(departments);
    setLoading(false);
  }, [getAccessTokenSilently, deployedDepartments]);

  const debouncedUpdate = useMemo(() => {
    return debounce(fetchOption, 500);
  }, [fetchOption]);

  const removeSelectedDepartment = (id) => {
    const tmp = deployedDepartments.filter(
      (department) => department.id !== id,
    );
    setDeployedDepartments(tmp);
  };

  const handleChange = (event, newValue) => {
    setDeployedDepartments(newValue);
  };
  const onSearchChange = (value) => {
    setLoading(true);
    debouncedUpdate();
  };

  return (
    <Collapse
      in={openCollapse}
      timeout="auto"
      sx={{ paddingLeft: "7rem", marginTop: "1rem" }}
      unmountOnExit
      key={"users_subset_collapsed"}
    >
      <Autocomplete
        size="small"
        multiple
        options={options}
        getOptionLabel={(option) => option.name}
        value={deployedDepartments}
        freeSolo={true}
        loading={loading}
        onOpen={() => {
          onSearchChange("");
        }}
        loadingText={t("Loading") + "..."}
        onChange={handleChange}
        onInputChange={(event, value) => onSearchChange(value)}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            sx={{ width: "80%" }}
            {...params}
            label={t("Department")}
          />
        )}
      />
      {deployedDepartments.map((value) => (
        <StyledBox sx={{ justifyContent: "unset" }}>
          <IconButton
            onClick={() => {
              removeSelectedDepartment(value.id);
            }}
          >
            <CloseIcon sx={{ width: "0.8em" }} />
          </IconButton>
          <Typography
            sx={{
              px: "1.5rem",
              width: "60%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color={"text.secondary"}
          >
            {value.name}
          </Typography>
        </StyledBox>
      ))}
    </Collapse>
  );
};
