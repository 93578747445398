import { BackendSuspense } from "./BackendSuspense";
import { useTranslation } from "react-i18next";
import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

export const CourseCompletedCard = ({ data, error, isPending }) => {
  const completedCourses =
    data?.course_completion_rundown.slice(-1)[0]?.completed_courses;
  const courseCount = data?.course_count;
  const width =
    courseCount && courseCount > 0
      ? Math.round((completedCourses / courseCount) * 100)
      : 0;
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        avatar={
          <SchoolOutlinedIcon sx={{ fontSize: "32px", color: "#7567FE" }} />
        }
        title={t("Courses Completed")}
      />
      <CardContent>
        <BackendSuspense
          hasData={courseCount > 0}
          error={error}
          isPending={isPending}
        >
          <div style={{ backgroundColor: "#E9EDF0" }}>
            <div style={{ width: width + "%", backgroundColor: "#7576FE" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "25px" }}
              >
                <Typography color="white">{width} %</Typography>
              </Box>
            </div>
          </div>
          <Stack direction="row">
            <div>0</div>
            {completedCourses > 0 && completedCourses < courseCount ? (
              <Box display="flex" justifyContent="end" width={`${width}%`}>
                <div>{completedCourses}</div>
              </Box>
            ) : (
              ""
            )}
            <Box marginLeft="auto">{courseCount}</Box>
          </Stack>
        </BackendSuspense>
      </CardContent>
    </Card>
  );
};
