import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import css from "@/pages/support/Support.module.css";
import { Button, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { Documentation } from "./Documentation";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Support() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [priority, setPriority] = useState("normal");
  const [product, setProduct] = useState("");
  const { t } = useTranslation();

  const { user, getAccessTokenSilently } = useAuth0();
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [outboundRequest, setOutboundRequest] = useState(false);
  const [showFormInvalid, setShowFormInvalid] = useState(false);

  const send = async () => {
    if (isFormValid()) {
      setShowFormInvalid(false);
      try {
        setOutboundRequest(true);
        if (priority === "normal") {
          await sendMessageToSupportTeam();
        } else if (priority === "critical") {
          await createPagerDutyIncident();
        }

        setOutboundRequest(false);
        setShowMessageSent(true);
        setErrorMessage(null);
      } catch (error) {
        setShowMessageSent(false);
        setErrorMessage(extractErrorMessage(error));
        setOutboundRequest(false);
      }
    } else {
      setShowFormInvalid(true);
    }
  };

  const createPagerDutyIncident = async () => {
    await axios.post(
      "https://api.eu.pagerduty.com/incidents",
      {
        incident: {
          type: "incident",
          title: `Frontend -  ${subject}`,
          service: {
            id: "P5B328F",
            type: "service_reference",
          },
          body: {
            type: "incident_body",
            details: `Subject: ${subject}\nCustomer description: ${details}\nCustomer email: ${user?.email}\nCustomer phone: ${phoneNumber}.`,
          },
          escalation_policy: {
            id: "PY5Y75M",
            type: "escalation_policy_reference",
          },
        },
      },
      {
        // I am aware that it is bad practice to put api tokens without protection, but it makes the system work even if the simulation backend is down.
        headers: {
          Authorization: "Token token=e+TK_tkdsP7Z1GmopyEw",
          "Content-Type": "application/json",
          From: "guillaume@mantra.ms",
          Accept: "application/vnd.pagerduty+json;version=2",
        },
      },
    );
  };

  const sendMessageToSupportTeam = async () => {
    const accessToken = await getAccessTokenSilently();
    await axios.post(
      `${
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION
      }/support/send_support_team_message`,
      {
        subject: subject,
        phone_number: phoneNumber,
        product: product,
        details: details,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
  };

  const isFormValid = (): boolean => {
    return (
      phoneNumber !== "" &&
      priority !== "" &&
      product !== "" &&
      subject !== "" &&
      details !== ""
    );
  };

  return (
    <div className={css.wrapper}>
      <Paper className={css.card}>
        <Typography variant="h4">{t("Status page")}</Typography>
        <div>
          <Link to="https://status.mantra.ms/" target="_blank" rel="noopener">
            https://status.mantra.ms/
          </Link>
        </div>
      </Paper>
      <Paper className={css.card}>
        <Typography variant="h4">{t("Documentation")}</Typography>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: 0,
            mx: "auto",
            padding: "2rem",
            gap: 3,
            width: "100%", // Take up full width
          }}
        >
          <Documentation />
        </Paper>
      </Paper>
      <Paper className={css.card}>
        <Typography variant="h4">{t("Get support")}</Typography>
        <div className={css.card}>
          <TextField
            fullWidth
            id="phone-number"
            label={t("Your phone number")}
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          <TextField
            select
            fullWidth
            id="product"
            label={t("Product")}
            variant="outlined"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          >
            <MenuItem value="browser">Browser Defender</MenuItem>
            <MenuItem value="awareness">Cyber Awareness</MenuItem>
            <MenuItem value="simulation">Phishing Simulation</MenuItem>
            <MenuItem value="banners">Smart Banners</MenuItem>
          </TextField>

          <TextField
            select
            fullWidth
            id="priority"
            label={t("Priority")}
            variant="outlined"
            value={priority}
            defaultValue="normal"
            onChange={(e) => setPriority(e.target.value)}
          >
            <MenuItem value="normal">
              {t("P2 - Normal (business hours response)")}
            </MenuItem>
            <MenuItem value="critical">
              {t("P1 - Critical (24/7 emergency response)")}
            </MenuItem>
          </TextField>

          <TextField
            fullWidth
            id="subject"
            label={t("Subject")}
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <TextField
            multiline
            rows={5}
            fullWidth
            id="details"
            label={t("Details")}
            variant="outlined"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />

          <Button variant="contained" onClick={send} disabled={outboundRequest}>
            {t("Send")}
          </Button>

          {showMessageSent && (
            <div style={{ color: "green" }} className="mt-3">
              {t("Message sent to support team.")}
            </div>
          )}
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          {showFormInvalid && (
            <div style={{ color: "red" }}>
              {t("Please complete all form fields")}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
}
