import { AttachmentsIcon } from "@/components/icons/AttachmentsIcon";
import { PermissionsIcon } from "@/components/icons/PermissionsIcon";
import { DownloadsIcon } from "@/components/icons/DownloadsIcon";
import { CredentialsIcon } from "@/components/icons/CredentialsIcon";
import DataPerUserCollapseComponent from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUserCollapseComponent";
import { LevelCell } from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/LevelCell";
import { Box, Grid, IconButton, Typography } from "@mui/material";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CompromisingAction from "./CompromisingAction";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const StyledBox = styled(Box)`
  background-color: #f9f9f9;
  margin-right: 5px;
  width: max-content;
  height: Hug (24px);
  padding: 2px 8px 2px 10px;
  border-radius: 4px;
  border: 1px;
  gap: 3px;
`;

export const TableCellWithoutBorder = styled(TableCell)`
  border-bottom: 0;
`;

const RateRender = ({ data, dataRate }) => {
  return (
    <React.Fragment>
      <Typography display={"inline"}>{data}</Typography>
      <Typography
        display={"inline"}
        style={{ color: "#757575", fontSize: "10.5px" }}
      >
        ({(parseFloat(dataRate) * 100).toFixed(0)}%)
      </Typography>
    </React.Fragment>
  );
};

export default function DataPerUsersRow({ rowData }) {
  const { t } = useTranslation();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  return (
    <React.Fragment>
      <TableRow
        key={rowData.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          border: "none",
        }}
        hover={true}
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        <TableCellWithoutBorder
          sx={{ paddingRight: "8px", paddingLeft: "8px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCellWithoutBorder>
        <TableCellWithoutBorder sx={{ paddingLeft: "0px" }}>
          <Typography>{rowData.firstname}</Typography>
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          <Typography>{rowData.lastname}</Typography>
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          <Typography>
            <Link to={{ pathname: `/users/${rowData.id}` }}>
              {rowData.email}
            </Link>
          </Typography>
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          <Typography>{rowData.sent}</Typography>
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          <LevelCell value={rowData.level} />
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          {/* Click percentage is not working for Office users.*/}
          {rowData.is_office ? (
            <Typography>{rowData.opened}</Typography>
          ) : (
            <RateRender data={rowData.opened} dataRate={rowData.opened_rate} />
          )}
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          {/* Click percentage is not working for Office users.*/}
          {rowData.is_office ? (
            <Typography>{rowData.reported}</Typography>
          ) : (
            <RateRender
              data={rowData.reported}
              dataRate={rowData.reported_rate}
            />
          )}
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          {/* Click percentage is not working for Office users.*/}
          {rowData.is_office ? (
            <Typography>{rowData.clicked}</Typography>
          ) : (
            <RateRender data={rowData.clicked} dataRate={rowData.click_rate} />
          )}
        </TableCellWithoutBorder>
        <TableCellWithoutBorder>
          <Grid container sx={{ marginTop: "unset", minWidth: "190px" }}>
            <CompromisingAction
              data={rowData.credential}
              icon={<CredentialsIcon />}
              tooltipValue={t("Credentials")}
            />
            <CompromisingAction
              data={rowData.attachment}
              icon={<AttachmentsIcon />}
              tooltipValue={t("Attachments")}
            />
            <CompromisingAction
              data={rowData.download}
              icon={<DownloadsIcon />}
              tooltipValue={t("Downloads")}
            />
            <CompromisingAction
              data={rowData.permission}
              icon={<PermissionsIcon />}
              tooltipValue={t("Permissions")}
            />
          </Grid>
        </TableCellWithoutBorder>
      </TableRow>
      <DataPerUserCollapseComponent
        rowData={rowData}
        openCollapse={openCollapse}
      />
    </React.Fragment>
  );
}
