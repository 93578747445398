import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { StyledTypo } from "@/pages/awareness/setup/Teams/StyledTypo";
import { TeamsIcon } from "@/pages/awareness/setup/Teams/TeamsIcon";
import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AWARENESS_SCRIBEHOW_INSTALL_ALEX_BOT_TEAMS } from "@/utils/constants";

export const DocumentationComponent = ({ botName }) => {
  const openTeamsDocumentation = () => {
    window.open(AWARENESS_SCRIBEHOW_INSTALL_ALEX_BOT_TEAMS, "_blank");
  };
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <StyledTypo variant={"h2"} sx={{ py: "2rem" }}>
        {t("Install bot on Teams")}
      </StyledTypo>
      <StyledBox>
        <StyledBox sx={{ justifyContent: "unset", width: "600px" }}>
          <Button
            sx={{ width: "11rem" }}
            variant={"outlined"}
            onClick={openTeamsDocumentation}
            startIcon={<TeamsIcon />}
          >
            {t("Add to Teams")}
          </Button>
          <Typography sx={{ paddingLeft: "3rem" }} color={"text.secondary"}>
            {t("Add the {{botName}} app to your company Microsoft Teams", {
              botName: botName,
            })}
          </Typography>
        </StyledBox>
      </StyledBox>
      <Typography
        sx={{ marginTop: "1rem", marginBottom: "2rem" }}
        color={"text.secondary"}
      >
        {t("Chatbot name")}: {botName}
      </Typography>
    </React.Fragment>
  );
};
