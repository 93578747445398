import React from "react";

export default function SplashScreen() {
  return (
    <div id="splash-screen" className="kt-splash-screen">
      <img
        src="/media/logos/logo_mantra_loading_screen_small.png"
        alt="Mantra logo"
        width="300"
        height="52"
      />
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    </div>
  );
}
