import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteAnswer, selectEditMode } from "./courseSlice";
import TrashIconButton from "@/components/buttons/TrashIconButton";
import {
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  usePatchAnswerContentMutation,
} from "@/api";
import { useDebounceRequest } from "./hooks";
import { useSnackbar } from "material-ui-snackbar-provider";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)(
  () =>
    `
   & input.Mui-disabled:disabled {
    color: #000;
    -webkit-text-fill-color: #000;
  }
  & .MuiFormHelperText-root {
    background: rgb(244, 244, 246);
    margin: 0;
    padding: 4px 14px;
  }
  `,
);

const Answer = ({ interaction, answer, index }) => {
  const editMode = useSelector(selectEditMode);
  const [patch, { isError: isErrorPatch }] = usePatchAnswerContentMutation();
  const [create] = useCreateAnswerMutation();
  const [deleteAnswerMutation] = useDeleteAnswerMutation();
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const reactionRef = useRef(null);
  const isCorrectRef = useRef(null);
  const { t } = useTranslation();

  const debouncedPatch = useDebounceRequest(patch, {
    successMessage: t("Answer correctly updated"),
    errorMessage: t("An error occurred while updating the answer"),
  });
  const debouncedCreate = useDebounceRequest(create, {
    successMessage: t("Answer correctly added"),
    errorMessage: t("An error occurred while adding the answer"),
  });
  const snackbar = useSnackbar();
  const [shouldShowErrorStatus, setShouldShowErrorStatus] = useState(false);

  useEffect(() => {
    if (isErrorPatch) {
      setShouldShowErrorStatus(true);
    }
  }, [isErrorPatch]);

  useEffect(() => {
    contentRef.current.value = answer.content;
    reactionRef.current.value = answer.reaction;
    isCorrectRef.current.checked = answer.is_correct;
  }, [
    contentRef,
    reactionRef,
    isCorrectRef,
    answer.content,
    answer.reaction,
    answer.is_correct,
    editMode,
  ]);

  const onUpdate = (field: "content" | "reaction" | "is_correct") => (e) => {
    setShouldShowErrorStatus(false);
    const value = field === "is_correct" ? e.target.checked : e.target.value;
    const content = field === "content" ? value : contentRef.current.value;
    const reaction = field === "reaction" ? value : reactionRef.current.value;
    const isCorrect =
      field === "is_correct" ? value : isCorrectRef.current.checked;
    if (answer.id) {
      debouncedPatch({
        interactionId: interaction.id,
        answerId: answer.id,
        is_correct: isCorrect,
        content,
        reaction,
      });
    } else {
      debouncedCreate({
        placeholderId: answer.placeholderId,
        interactionId: interaction.id,
        is_correct: isCorrect,
        content,
        reaction,
      });
    }
  };

  const onDelete = async () => {
    if (answer.id) {
      await deleteAnswerMutation({
        interactionId: interaction.id,
        answerId: answer.id,
      });
      snackbar.showMessage(t("Answer successfully deleted"));
    } else if (answer.placeholderId) {
      dispatch(
        deleteAnswer({
          interactionId: interaction.id,
          answerId: answer.id,
          placeholderId: answer.placeholderId,
        }),
      );
    }
  };

  return (
    <Stack direction="row" gap={2}>
      <Box sx={{ flexGrow: 1 }}>
        {index === 0 ? (
          <Box sx={{ marginBottom: "10px" }}>{t("Answer")}</Box>
        ) : (
          ""
        )}
        <StyledTextField
          sx={{
            background: "#ffffff",
          }}
          inputProps={{
            maxlength: 75,
          }}
          inputRef={contentRef}
          defaultValue={answer.content}
          fullWidth
          disabled={!editMode}
          error={shouldShowErrorStatus}
          size="small"
          onInput={onUpdate("content")}
          helperText={
            shouldShowErrorStatus ? t("Unable to save an empty answer") : ""
          }
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {index === 0 ? (
          <Box sx={{ marginBottom: "10px" }}>{t("Reaction")}</Box>
        ) : (
          ""
        )}
        <StyledTextField
          sx={{
            background: "#ffffff",
          }}
          inputRef={reactionRef}
          defaultValue={answer.reaction}
          fullWidth
          error={shouldShowErrorStatus}
          disabled={!editMode}
          size="small"
          onInput={onUpdate("reaction")}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          minWidth: "50px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {index === 0 ? (
          <Box sx={{ marginBottom: "10px" }}>{t("Correct")}</Box>
        ) : (
          ""
        )}
        <Checkbox
          sx={{
            cursor: editMode ? "pointer" : "initial",
          }}
          inputRef={isCorrectRef}
          defaultChecked={answer.is_correct}
          disabled={!editMode}
          disableRipple={true}
          size="small"
          onInput={onUpdate("is_correct")}
        />
      </Box>
      {editMode ? (
        <Box
          sx={{
            flexGrow: 0,
            minWidth: "50px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {index === 0 ? (
            <Box sx={{ marginBottom: "10px" }}>{t("Delete")}</Box>
          ) : (
            ""
          )}
          <TrashIconButton onClick={onDelete} />
        </Box>
      ) : (
        ""
      )}
    </Stack>
  );
};

export default Answer;
