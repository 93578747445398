import { Paper } from "@mui/material";
import React from "react";
import { WhitelistingScenarios } from "@/pages/phishing-simulation/setup/WhitelistingScenarios";
import { WhitelistingTestLog } from "@/pages/phishing-simulation/setup/WhitelistingTestLog";
import { SendAnySimulation } from "./SendAnySimulation";
import { OnlyVisibleByMantra } from "@/components/layout/OnlyVisibleByMantra";

export function DeliveryTest() {
  return (
    <Paper sx={{ py: "2rem", px: "1rem" }}>
      <WhitelistingScenarios />
      <WhitelistingTestLog />
      <OnlyVisibleByMantra>
        <SendAnySimulation />
      </OnlyVisibleByMantra>
    </Paper>
  );
}
