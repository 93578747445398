import SelectOrderByComponent from "@/components/sort/SelectOrderByComponent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import {
  Button,
  Card,
  ClickAwayListener,
  List,
  ListItem,
  Popper,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#061123",
          marginLeft: "10px",
          borderColor: "#0611233A",
        },
      },
    },
  },
});

export default function TableSort({ setOrderBy, columns, defaultOrder = [] }) {
  const { t } = useTranslation();

  const orderChoice = columns;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [listOrderBy, setListOrderBy] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(!isModalOpen);
  };

  const handleAddSort = () => {
    const tempListSort = [...listOrderBy];
    tempListSort.push({
      id: tempListSort.length,
      label: "",
      order: "asc",
      column: null,
    });
    setListOrderBy(tempListSort);
  };

  const handleRemoveItem = (index) => {
    const tempListSort = [...listOrderBy];
    tempListSort.splice(index, 1);
    setListOrderBy(tempListSort);
  };

  const handleOrderChange = (index, event) => {
    const tempListSort = [...listOrderBy];
    tempListSort[index].order = event.target.value;
    setListOrderBy(tempListSort);
  };

  const handleOrderLabelChange = (index, value) => {
    const tempListSort = [...listOrderBy];
    tempListSort[index].label = value ? value.name : "";
    tempListSort[index].column = value;
    setListOrderBy(tempListSort);
  };

  const applyFilter = () => {
    let orderByFormatted = [];
    for (const orderItem of listOrderBy) {
      if (orderItem.label) {
        orderByFormatted.push({
          column: orderItem.label,
          direction: orderItem.order === "asc" ? 1 : -1,
        });
      }
    }

    setOrderBy(orderByFormatted.length > 0 ? orderByFormatted : defaultOrder);
    setIsModalOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        startIcon={<FilterListIcon />}
        endIcon={isModalOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size={"medium"}
        variant={"outlined"}
        onClick={handleClick}
      >
        {t("Sort")}
      </Button>
      {isModalOpen && (
        <ClickAwayListener onClickAway={applyFilter}>
          <Popper
            open={isModalOpen}
            anchorEl={anchorEl}
            placement={"bottom-start"}
            style={{ zIndex: 120 }}
          >
            <Card sx={{ width: "400px" }} elevation={1}>
              <List dense>
                {listOrderBy.map((orderItem, index) => {
                  return (
                    <SelectOrderByComponent
                      index={index}
                      key={index}
                      orderChoice={orderChoice}
                      handleOrderChange={handleOrderChange}
                      handleOrderLabelChange={handleOrderLabelChange}
                      orderItem={orderItem}
                      handleRemoveItem={handleRemoveItem}
                    />
                  );
                })}
                <ListItem>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddSort}
                    size={"small"}
                    sx={{ textTransform: "none" }}
                  >
                    {t("Add Sort")}
                  </Button>
                </ListItem>
                <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant={"contained"}
                    size={"small"}
                    onClick={applyFilter}
                  >
                    {t("Apply")}
                  </Button>
                </ListItem>
              </List>
            </Card>
          </Popper>
        </ClickAwayListener>
      )}
    </ThemeProvider>
  );
}
