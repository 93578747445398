import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { Spinner } from "@/components/Spinner";

const StatsCard = ({
  title,
  icon,
  data,
  isPending,
  action,
  error,
  height,
  link,
}) => {
  const cardHeader = (
    <CardHeader avatar={icon} action={action} title={title}></CardHeader>
  );

  return (
    <Card>
      {link ? (
        <Link style={{ color: "inherit" }} to={link}>
          {cardHeader}
        </Link>
      ) : (
        cardHeader
      )}
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        <Stack spacing={2}>
          {error && "an error occured"}
          {isPending ? (
            <Box sx={{ marginBottom: "15px" }}>
              <Spinner />
            </Box>
          ) : (
            <Typography variant="h3" sx={{ marginBottom: "15px" }}>
              {data}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
