import React, { useState } from "react";
import { TimeZoneSelector } from "@/pages/general-settings/UsersManagement/csvImport/timezone/TimeZoneSelector";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { groupBy, find } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import axios from "axios";

export function TimeZoneWizard({ setStep, users, setUsers }) {
  // Based on the user list, set the mappings
  const getInitialMapping = () => {
    const locations = Object.getOwnPropertyNames(groupBy(users, "location"));
    return locations.map((location) => {
      return { location: location, timezone: "Europe/Paris" };
    });
  };

  // Set the mappings
  const [mappings, setMappings] = useState(getInitialMapping());
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  // Update the mapping when a selection is changed
  const updateTimezone = (index, timezone) => {
    const newMappings = [...mappings];
    newMappings[index].timezone = timezone;
    setMappings(newMappings);
  };

  const planImport = async (users) => {
    setError("");

    // Call api to get the import plan
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/users/import/plan",
        users,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;

      if (data.error) {
        setError(data.error);
      } else {
        // Overwrite the user with the plan
        setUsers(data.data);
        // Go to next step
        setStep(3);
      }
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const btnNextClicked = async () => {
    // Add timezones to users
    users.map((user) => {
      user.timezone = find(mappings, { location: user.location }).timezone;
      return null;
    });
    // Bubble up the users
    setUsers(users);
    // Call the import plan api
    await planImport(users);
  };

  return (
    <Card>
      <CardHeader title="Import Users - Step 2/4: Timezone selection" />
      <CardContent>
        <div>
          Would you mind telling us on which timezones are theses locations? It
          will help us to send emails during business hours.
          <br />
        </div>
        <div className="row">
          <div className="col-md-4 no-left-padding no-right-padding ">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Location</th>
                  <th scope="col">Timezone</th>
                </tr>
              </thead>
              <tbody>
                {mappings.map((mapping, index) => (
                  <TimeZoneSelector
                    key={index}
                    index={index}
                    mapping={mapping}
                    updateTimezone={updateTimezone}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {error !== "" && <div style={{ color: "red" }}>{error}</div>}
        <div>
          <Button variant="contained" onClick={btnNextClicked}>
            Next
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
