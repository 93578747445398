import {
  Autocomplete,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useSimulationDepartments } from "@/utils/misc";

export type AudienceSelectionMode = "users" | "departments" | "everyone";

export type AudienceSelectionDepartment = {
  id: number;
  name: string;
};

type DepartmentSelectionOption = {
  label: string;
  department: AudienceSelectionDepartment;
};

export type AudienceSelectionUser = {
  email: string;
};

type UserSelectionOption = {
  label: string;
  user: AudienceSelectionUser;
};

export const AudienceSelector = ({
  audienceSelectionMode,
  setAudienceSelectionMode,
  setSelectedUsers,
  setSelectedDepartments,
  userList,
  fetchUsers,
}) => {
  const { departments } = useSimulationDepartments();
  const { t } = useTranslation();

  const refetchUsers = useMemo(() => {
    return debounce(fetchUsers, 300);
  }, [fetchUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  return (
    <RadioGroup
      onChange={(e) => setAudienceSelectionMode(e.target.value)}
      value={audienceSelectionMode}
    >
      <FormControlLabel value="users" control={<Radio />} label={t("Users")} />
      <Collapse in={audienceSelectionMode === "users"} timeout="auto">
        <Autocomplete
          disableCloseOnSelect
          multiple
          onChange={(event, value) => setSelectedUsers(value)}
          options={userList.map((user: AudienceSelectionUser) => ({
            label: user.email,
            user: user,
          }))}
          loadingText={t("Loading") + "..."}
          isOptionEqualToValue={(
            option: UserSelectionOption,
            value: UserSelectionOption,
          ) => {
            return option.user === value.user;
          }}
          sx={{ padding: "20px 0", width: "300" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Users")}
              onChange={(e) => refetchUsers(e)}
            />
          )}
        ></Autocomplete>
      </Collapse>
      <FormControlLabel
        value="departments"
        control={<Radio />}
        label={t("Departments")}
      />
      <Collapse in={audienceSelectionMode === "departments"} timeout="auto">
        <Autocomplete
          disableCloseOnSelect
          multiple
          onChange={(event, value) => setSelectedDepartments(value)}
          renderInput={(params) => (
            <TextField {...params} label={t("Departments")} />
          )}
          loadingText={t("Loading") + "..."}
          isOptionEqualToValue={(
            option: DepartmentSelectionOption,
            value: DepartmentSelectionOption,
          ) => {
            return option.department.id === value.department.id;
          }}
          options={departments.map(
            (department: AudienceSelectionDepartment) => ({
              label: department.name,
              department: department,
            }),
          )}
        ></Autocomplete>
      </Collapse>
      <FormControlLabel
        value="everyone"
        control={<Radio />}
        label={t("Everyone")}
      />
    </RadioGroup>
  );
};
