import React, { useState } from "react";
import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import PhishingDetailsHeader from "@/pages/browser-defender/passwordMisuse/phishing-attack/PhishingDetailsHeader";
import PhishingDetailsFooter from "@/pages/browser-defender/passwordMisuse/phishing-attack/PhishingDetailsFooter";
import { useTranslation, Trans } from "react-i18next";

export default function PotentialPhishingDetails({ row, close }) {
  const [clipboardCopy, setClipboardCopy] = useState(false);
  const { t } = useTranslation();

  function copyUrl() {
    navigator.clipboard.writeText(row.url);
    setClipboardCopy(true);
    setTimeout(() => setClipboardCopy(false), 2000);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        height: "100%",
      }}
    >
      <PhishingDetailsHeader domain={row.domain} />
      <Divider />
      <Box sx={{ flex: 1, overflow: "auto", padding: "3rem 1.5rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            mb: "3rem",
          }}
        >
          <Typography>URL:</Typography>
          <Typography
            noWrap
            style={{ textDecoration: "underline", margin: "0 0.5rem" }}
          >
            {row.url}
          </Typography>
          {clipboardCopy ? (
            <DoneIcon />
          ) : (
            <ContentCopyIcon onClick={copyUrl} style={{ cursor: "pointer" }} />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            mb: "1rem",
          }}
        >
          <PrivacyTipIcon style={{ color: "#4445F4", marginRight: "1rem" }} />
          <Typography style={{ fontWeight: "bold" }}>
            {t("Attention points")}
          </Typography>
        </Box>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem sx={{ display: "list-item" }}>
            <Trans
              i18nKey="potentialPhishingDetailsIntentionPointsLogin"
              values={{ brand: row.impersonated_brand }}
            >
              The page resembles a &nbsp;
              <Typography style={{ fontWeight: "bold", display: "inline" }}>
                {row.impersonated_brand}
              </Typography>
              credentials page
            </Trans>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Trans
              i18nKey="potentialPhishingDetailsIntentionPointsDomain"
              values={{ brand: row.impersonated_brand, domain: row.domain }}
            >
              <Typography
                style={{
                  textDecoration: "underline",
                  display: "inline",
                  fontWeight: "bold",
                }}
              >
                {row.domain}
              </Typography>{" "}
              &nbsp; does not appear to be a legitimate&nbsp;
              <Typography style={{ fontWeight: "bold", display: "inline" }}>
                {row.impersonated_brand}
              </Typography>{" "}
              &nbsp; domain
            </Trans>
          </ListItem>
          {row.user_action && (
            <ListItem sx={{ display: "list-item" }}>
              {t("The user opted to disregard the alert")}
            </ListItem>
          )}
        </List>
      </Box>
      <Divider />
      <PhishingDetailsFooter event_id={row.id} close={close} />
    </Box>
  );
}
