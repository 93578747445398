import React from "react";

import { DocBlock } from "@/pages/support/DocBlock";
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { useUserContext } from "@/utils/contexts/UserContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import {
  BANNERS_SCRIBEHOW_ON_OFFICE_URL,
  AWARENESS_SCRIBEHOW_SETUP_TEAMS,
  SIMULATION_SCRIBEHOW_SETUP_MICROSOFT_SSO_API_SYNC,
  SIMULATION_SCRIBEHOW_SETUP_GOOGLE_SSO_API_SYNC,
  AWARENESS_SCRIBEHOW_SETUP_SLACK,
  BANNERS_SCRIBEHOW_ON_GOOGLE_URL,
  MANTRA_SCRIBEHOW_COMMON_ACTIONS,
} from "@/utils/constants";

export function Documentation() {
  const userContext = useUserContext();
  const mailHost = userContext?.current_company?.mailHost ?? "";
  const { t } = useTranslation();

  const Office = [
    {
      title: t("Setup Phishing Simulation"),
      description: t("How to set Phishing Simulation"),
      link: SIMULATION_SCRIBEHOW_SETUP_MICROSOFT_SSO_API_SYNC,
    },
    {
      title: t("Setup Awareness"),
      description: t("How to set Awareness with Teams"),
      link: AWARENESS_SCRIBEHOW_SETUP_TEAMS,
    },
    {
      title: t("Setup Smart Banners"),
      description: t("How to set Smart Banners in your mailbox"),
      link: BANNERS_SCRIBEHOW_ON_OFFICE_URL,
    },
  ];

  const Gmail = [
    {
      title: t("Setup Phishing Simulation"),
      description: t("How to set Phishing Simulation"),
      link: SIMULATION_SCRIBEHOW_SETUP_GOOGLE_SSO_API_SYNC,
    },
    {
      title: t("Setup Awareness"),
      description: t("How to set Awareness with Slack"),
      link: AWARENESS_SCRIBEHOW_SETUP_SLACK,
    },
    {
      title: t("Setup Smart Banners"),
      description: t("How to set Smart Banners in your mailbox"),
      link: BANNERS_SCRIBEHOW_ON_GOOGLE_URL,
    },
  ];
  const docCommon = [
    {
      title: t("Common Actions"),
      description: "",
      link: MANTRA_SCRIBEHOW_COMMON_ACTIONS,
    },
  ];

  return (
    <>
      {mailHost === "OFFICE"
        ? Office.map((doc) => (
            <Accordion
              sx={{
                borderTop: 0,
                borderRadius: "6px",
                "&::before": {
                  opacity: 0,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{doc.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DocBlock doc={doc} />
              </AccordionDetails>
            </Accordion>
          ))
        : Gmail.map((docG) => (
            <Accordion
              sx={{
                borderTop: 0,
                borderRadius: "6px",
                "&::before": {
                  opacity: 0,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{docG.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DocBlock doc={docG} />
              </AccordionDetails>
            </Accordion>
          ))}
      {docCommon.map((doc) => (
        <Accordion
          sx={{
            borderTop: 0,
            borderRadius: "6px",
            "&::before": {
              opacity: 0,
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{doc.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocBlock doc={doc} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
