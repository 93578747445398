import React, { useEffect, useReducer } from "react";
import { createContext } from "react";
import { DateRange, Period, PeriodDateRange } from "./DateRange";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { DateRangePickerException } from "./exceptions";

export type DateRangePickerContextType = {
  periods: PeriodDateRange[];
  onChange: (period: Period, dateRange: DateRange) => void;
};

type DateRangePickerInternalStateType = DateRangePickerContextType & {
  currentDateRange: DateRange;
  currentPeriod: Period;
  updateCurrentPeriod: (newPeriod: Period) => void;
  updateCurrentDateRange: (newDateRange: DateRange) => void;
};

export const DateRangePickerContext =
  createContext<DateRangePickerInternalStateType>(null);

const handlePeriodAction = (
  state: DateRangePickerInternalStateType,
  action: Period,
) => {
  const newState = cloneDeep(state);
  const periodIndex = state.periods.findIndex(
    (el: PeriodDateRange) => el.period === action,
  );
  newState.currentPeriod = state.periods[periodIndex].period;
  newState.currentDateRange = state.periods[periodIndex].dateRange;
  return newState;
};

const handleDateRangeAction = (
  state: DateRangePickerInternalStateType,
  action: DateRange,
) => {
  const newState = cloneDeep(state);
  const newDate = action as DateRange;
  const periodIndex = state.periods.findIndex((el: PeriodDateRange) => {
    return (
      action.from.isSame(el.dateRange.from) && action.to.isSame(el.dateRange.to)
    );
  });
  if (periodIndex !== -1) {
    newState.currentPeriod = state.periods[periodIndex].period;
    newState.currentDateRange = state.periods[periodIndex].dateRange;
  } else {
    newState.currentPeriod = "Custom";
    newState.currentDateRange = {
      from: dayjs(newDate.from),
      to: dayjs(newDate.to),
    };
  }
  return newState;
};

const dateRangePickerReducer = (
  state: DateRangePickerInternalStateType,
  action: Period | DateRange,
) => {
  let newState: DateRangePickerInternalStateType;
  if (typeof action === "string") {
    newState = handlePeriodAction(state, action as Period);
  } else {
    newState = handleDateRangeAction(state, action as DateRange);
  }
  return newState;
};

type DateRangePickerContextProviderProps = DateRangePickerContextType &
  React.PropsWithChildren;

const DateRangePickerContextProvider = (
  props: DateRangePickerContextProviderProps,
) => {
  const { periods, onChange } = props;
  if (props.periods.length === 0) {
    throw new DateRangePickerException(
      "Unable to create DateRangePicker with no periods",
    );
  }
  const [value, dispatch] = useReducer(dateRangePickerReducer, {
    periods: periods,
    onChange: onChange,
    currentDateRange: props.periods[0].dateRange,
    currentPeriod: props.periods[0].period,
    updateCurrentPeriod: (newPeriod) => dispatch(newPeriod),
    updateCurrentDateRange: (newDateRange) => dispatch(newDateRange),
  });

  useEffect(() => {
    if (
      value.currentDateRange.from &&
      value.currentDateRange.to &&
      value.currentPeriod
    ) {
      onChange(value.currentPeriod, value.currentDateRange);
    }
  }, [
    value.currentDateRange,
    value.currentDateRange.from,
    value.currentDateRange.to,
    value.currentPeriod,
    onChange,
  ]);

  return (
    <DateRangePickerContext.Provider
      value={{
        ...value,
      }}
    >
      {props.children}
    </DateRangePickerContext.Provider>
  );
};

export default DateRangePickerContextProvider;
