import { alpha, Chip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React from "react";
import ThreatStatusIcon from "@/pages/threatslg/components/listpane/list/ThreatStatusIcon";
import MailboxText from "@/pages/threatslg/common/MailboxText";
import { Threat } from "@/pages/threatslg/threatslg_types";
import Box from "@mui/material/Box";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {
  SelectionCheckbox,
  SelectionListType,
} from "@/components/SelectionCheckbox";

type ThreatRowProps = {
  threat: Threat;
  displayThreat: (threatId: number) => void;
  isDisplayed: boolean;
  selectionList: SelectionListType;
};

const ThreatRow = ({
  threat,
  displayThreat,
  isDisplayed,
  selectionList,
}: ThreatRowProps) => {
  const isSelected = selectionList.isSelected(threat.id);

  const reportDate = threat.reportDate.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const subjectColor = {
    info: "black",
    suspicious: "orange",
    "very-suspicious": "red",
  }[threat.bannerLevel];

  const userChip = (
    <UserSourceChip
      style={{
        visibility: threat.reporter != null ? "visible" : "hidden",
      }}
    />
  );

  const warningIcon =
    threat.bannerLevel === "suspicious" ||
    threat.bannerLevel === "very-suspicious" ? (
      <ReportProblemOutlinedIcon sx={{ marginRight: "10px" }} />
    ) : null;

  return (
    <TableRow
      hover
      key={threat.id}
      sx={{
        "& .MuiTableCell-body": {
          fontSize: "1rem",
          borderBottom: "1px #e9e9e9 solid",
        },
      }}
      onClick={() => displayThreat(threat.id)}
      style={{
        background: isDisplayed
          ? "#e0e1ff"
          : isSelected
            ? "#eff0ff"
            : "transparent",
      }}
    >
      <TableCell style={{ width: "10px", padding: 0 }}>
        <SelectionCheckbox
          color="primary"
          objectId={threat.id}
          selectionList={selectionList}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ flex: 2 }}>
            <Box
              sx={{
                color: subjectColor,
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {warningIcon}
              {threat.subject}
            </Box>
            <Box>
              <MailboxText label="From" mailbox={threat.sender} />
            </Box>
            <Box>
              <MailboxText label="To" mailbox={threat.receiver} />
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ paddingRight: "10px" }}>
                <AttachmentOutlinedIcon
                  visibility={threat.hasAttachments ? "visible" : "hidden"}
                />
              </Box>
              <Box>{reportDate}</Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                {userChip}
              </Box>
              <ThreatStatusIcon feedback={threat.feedback} />
            </Box>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const UserSourceChip = ({ style }: { style: { [key: string]: any } }) => {
  return (
    <Chip
      style={style}
      variant="outlined"
      size={"small"}
      sx={{
        backgroundColor: alpha("#7566FE", 0.1),
        borderColor: "#7566FE",
        color: "#7566FE",
        fontWeight: "normal",
      }}
      label="User reported"
    />
  );
};

export default ThreatRow;
