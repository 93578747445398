import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useUserContext } from "@/utils/contexts/UserContext";
import {
  useAcknowledgeGmailConsentMutation,
  useStatusQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import { Link } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { BANNERS_SCRIBEHOW_ON_GOOGLE_GRANT_CONSENT_URL } from "@/utils/constants";

const GmailProvisionning: React.FC = () => {
  const {
    data,
    isLoading,
    isError,
    isFetching: isStatusFetching,
  } = useStatusQuery();
  const [acknowledgeGmailConsent, { isLoading: isAckLoading }] =
    useAcknowledgeGmailConsentMutation();

  if (isError) return <>Error</>;

  if (isLoading) return <>Loading</>;

  if (data.banner_controller_infos.gmail_service_account_id === null) {
    return (
      <Box display="flex" flexDirection="row" gap="1rem">
        <Typography>User Service Account not setup</Typography>
        <WarningAmberOutlinedIcon sx={{ fill: "#fe9534" }} />
      </Box>
    );
  } else {
    return (
      <>
        <Box>
          <Box display="flex" flexDirection="row" gap="1rem">
            <Typography>User Service Account setup</Typography>
            <CheckCircleOutlinedIcon color="success" />
            <Typography>
              AccountID: {data.banner_controller_infos.gmail_service_account_id}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography>
            Follow the documentation:{" "}
            <Link
              to={BANNERS_SCRIBEHOW_ON_GOOGLE_GRANT_CONSENT_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="contained">How to grant Consent</Button>
            </Link>
          </Typography>
        </Box>

        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.banner_controller_infos.gmail_consent}
                  disabled={isAckLoading || isStatusFetching}
                  onChange={(e) => {
                    acknowledgeGmailConsent(e.target.checked);
                  }}
                />
              }
              label="I confirm I have given access to the service account."
            />
          </FormGroup>
        </Box>
      </>
    );
  }
};
const OfficeProvisionning: React.FC = () => {
  const { data, isLoading, isError } = useStatusQuery();

  const hasProvisioningPermissions = useMemo(
    () => !isLoading && !isError && data.banner_controller_infos.office_consent,
    [data, isLoading, isError],
  );

  if (isError) return <>Error</>;

  if (isLoading) return <>Loading</>;

  return (
    <Box display="flex" alignItems="center" gap="2.5rem">
      <Link
        to={
          hasProvisioningPermissions
            ? "#"
            : "/general-settings#users-provisioning"
        }
        style={
          hasProvisioningPermissions
            ? {
                pointerEvents: "none",
                cursor: "auto",
              }
            : {}
        }
      >
        <Button variant="contained" disabled={hasProvisioningPermissions}>
          Grant Provisioning Permissions
        </Button>
      </Link>
      <Box display="flex" gap="0.5rem">
        {hasProvisioningPermissions ? (
          <>
            <CheckCircleOutlinedIcon color="success" />
            <Typography variant="body_small" color="#2e7d32">
              It works!
            </Typography>
          </>
        ) : (
          <>
            <CancelOutlinedIcon color="error" />
            <Typography color="#ed3b5b">Not connected</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const DemoProvisionning: React.FC = () => {
  return <GmailProvisionning />;
};

type ApiAccessSetupProps = { title: string };

export const ApiAccessSetup: React.FC<ApiAccessSetupProps> = ({
  title,
}: ApiAccessSetupProps) => {
  const { current_company } = useUserContext();
  return (
    <ChecklistPane title={title}>
      <Typography>
        Authorize Mantra to display banners on your emails.
      </Typography>

      {current_company.banners_controller === "GMAIL" ? (
        <GmailProvisionning />
      ) : current_company.banners_controller === "OFFICE" ? (
        <OfficeProvisionning />
      ) : (
        <DemoProvisionning />
      )}
    </ChecklistPane>
  );
};
