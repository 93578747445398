import React, { useEffect } from "react";
import { Card, CardContent, Stack } from "@mui/material";
import CourseEditorHeader from "./CourseEditorHeader";
import CourseEditorToolbar from "./CourseEditorToolbar";
import CourseHeaderFields from "./CourseHeaderFields";
import { Interactions } from "./Interactions";
import { useParams } from "react-router-dom";
import { useFetchCourseByIdQuery } from "@/api";
import { FullPageSpinner } from "@/components/Spinner";
import { useDispatch } from "react-redux";
import { setEditMode } from "./courseSlice";
import StickyHeader from "@/components/StickyHeader";
import { useTranslation } from "react-i18next";

export function CourseInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { error, isFetching, isSuccess } = useFetchCourseByIdQuery(id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && isSuccess) {
      dispatch(setEditMode(false));
    }
  }, [id, dispatch, isSuccess]);

  return (
    <>
      {error ? <div>{t("An error occurred")}</div> : ""}
      {isFetching ? <FullPageSpinner /> : ""}
      {!error && !isFetching ? (
        <>
          <StickyHeader height="120px" paddingTop={"0.5rem"}>
            <CourseEditorHeader />
            <CourseEditorToolbar />
          </StickyHeader>
          <Card sx={{ height: "initial" }}>
            <CardContent>
              <Stack spacing={2} useFlexGap>
                <CourseHeaderFields />
                <Interactions />
              </Stack>
            </CardContent>
          </Card>
        </>
      ) : (
        ""
      )}
    </>
  );
}
