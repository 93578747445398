import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BannerLevel,
  FeedbackLG,
  FeedbackType,
  ListOptions,
  Mailbox,
  NewThreatsUIEnabled,
  Threat,
  ThreatDetails,
  ThreatListResponse,
} from "./threatslg_types";
import { simulationBaseQuery } from "@/utils/rtkQuery";

const buildThreatListUrlParams = (
  startIndex: number,
  pageSize: number,
  listOptions: ListOptions,
): URLSearchParams => {
  const params = new URLSearchParams();
  params.append("offset", startIndex.toString());
  params.append("limit", pageSize.toString());
  params.append("archived", listOptions.archived.toString());
  if (listOptions.sort) {
    const sortParams = listOptions.sort.map(
      (sort) => `${sort.direction === "asc" ? "" : "-"}${sort.column}`,
    );
    sortParams.forEach((sortParam) => params.append("sort", sortParam));
  }
  if (listOptions.searchTerm) {
    params.append("search", listOptions.searchTerm.toString());
  }
  if (listOptions.source) {
    params.append("source", listOptions.source.toString());
  }
  if (listOptions.bannerLevel) {
    params.append("risk_level", listOptions.bannerLevel.toString());
  }
  if (listOptions.hasAttachments != null) {
    params.append("has_attachments", listOptions.hasAttachments.toString());
  }
  return params;
};

type ThreatDTO = {
  id: number;
  details: ThreatDetails | null;
  isSelected: boolean;
  isDisplayed: boolean;
  subject: string;
  bannerLevel: BannerLevel | null;
  sender: Mailbox;
  receiver: Mailbox;
  reportDate: string;
  receivedDate: string;
  hasAttachments: boolean;
  feedback: FeedbackLG | null;
  reporter: Mailbox | null;
};

export const threatsApi = createApi({
  reducerPath: "threats-api",
  baseQuery: simulationBaseQuery(),
  tagTypes: ["threats", "threat"],

  endpoints: (builder) => ({
    threatList: builder.query<
      ThreatListResponse,
      { startIndex: number; pageSize: number; listOptions: ListOptions }
    >({
      query: ({
        startIndex,
        pageSize,
        listOptions,
      }: {
        startIndex: number;
        pageSize: number;
        listOptions: ListOptions;
      }) => ({
        url: "/threats-ng/threats",
        method: "GET",
        params: buildThreatListUrlParams(startIndex, pageSize, listOptions),
      }),
      transformResponse: (
        response: {
          startIndex: number;
          totalCount: number;
          items: ThreatDTO[];
        },
        meta,
        arg,
      ) => {
        // @ts-ignore
        const items: Threat[] = response.items.map((t) => ({
          ...t,
          reportDate: new Date(Date.parse(t.reportDate)),
          receivedDate: new Date(Date.parse(t.receivedDate)),
        }));
        return {
          totalCount: response.totalCount,
          startIndex: response.startIndex,
          items: items,
        };
      },
      providesTags: ["threats"],
    }),

    threatDetails: builder.query<ThreatDetails, number>({
      query: (id) => ({ url: `/threats-ng/threats/${id}`, method: "GET" }),
      providesTags: ["threat"],
    }),

    threatEml: builder.query<{ id: number; payload: string }, number>({
      query: (id) => ({ url: `/threats-ng/threats/${id}/eml`, method: "GET" }),
    }),

    isNewThreatsUIEnabled: builder.query<NewThreatsUIEnabled, void>({
      query: () => ({
        url: `/threats-ng/threats/is-threats-ng-enabled`,
        method: "GET",
      }),
    }),

    unarchiveThreats: builder.mutation<void, number[]>({
      query: (threatIds) => ({
        url: `/threats-ng/threats/batch-unarchive`,
        method: "POST",
        body: { threat_ids: threatIds },
      }),
      invalidatesTags: ["threats", "threat"],
    }),

    deleteThreats: builder.mutation<void, number[]>({
      query: (threatIds) => ({
        url: `/threats-ng/threats/batch-delete`,
        method: "POST",
        body: { threat_ids: threatIds },
      }),
      invalidatesTags: ["threats", "threat"],
    }),

    feedbackThreats: builder.mutation<
      void,
      {
        threatIds: number[];
        feedbackType: FeedbackType;
        feedbackComment: string;
      }
    >({
      query: ({
        threatIds,
        feedbackType,
        feedbackComment,
      }: {
        threatIds: number[];
        feedbackType: FeedbackType;
        feedbackComment: string;
      }) => ({
        url: `/threats-ng/threats/batch-feedback`,
        method: "POST",
        body: {
          threat_ids: threatIds,
          feedback: feedbackType,
          comment: feedbackComment,
        },
      }),
      invalidatesTags: ["threats", "threat"],
    }),
  }),
});

export const {
  useDeleteThreatsMutation,
  useFeedbackThreatsMutation,
  useIsNewThreatsUIEnabledQuery,
  useLazyThreatDetailsQuery,
  useLazyThreatEmlQuery,
  useLazyThreatListQuery,
  useThreatListQuery,
  useUnarchiveThreatsMutation,
} = threatsApi;
