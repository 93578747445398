import { useCallback, useEffect, useState } from "react";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

function useResource(host, name, defaultValue, fetchImmediately) {
  const [resource, setResource] = useState(defaultValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const fetchResource = useCallback(() => {
    let canceled = false;

    getAccessTokenSilently()
      .then(async (accessToken) => {
        setError(null);

        const result = await axios.get(`${host}/${name}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setError(null);

        setLoading(false);

        // to avoid React warning "can't update state of an unmounted component"
        if (!canceled) {
          setResource(result.data);
        }
      })
      .catch((axiosError) => {
        setLoading(false);
        setError({
          message: extractErrorMessage(axiosError),
          code: axiosError?.response?.status,
        });
      });

    return () => {
      canceled = true;
    };
  }, [getAccessTokenSilently, host, name]);

  useEffect(() => {
    if (fetchImmediately) {
      fetchResource();
    }
  }, [fetchImmediately, fetchResource]);

  return [resource, error, loading, fetchResource];
}

export function useSimulationResource(
  name,
  defaultValue,
  fetchImmediately = true,
) {
  return useResource(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION,
    name,
    defaultValue,
    fetchImmediately,
  );
}

export function useLearningResource(
  name,
  defaultValue = null,
  fetchImmediately = true,
) {
  return useResource(
    import.meta.env.VITE_APP_ENDPOINT_LEARNING,
    name,
    defaultValue,
    fetchImmediately,
  );
}

export function usePublicLearningResource(
  name,
  defaultValue,
  fetchImmediately = true,
) {
  const [resource, setResource] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchResource = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/${name}`,
      );
      setResource(response.data);
      setLoading(false);
      setErrorMessage(null);
    } catch (error) {
      setLoading(false);
      setErrorMessage(extractErrorMessage(error));
    }
  }, [name]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    if (fetchImmediately) {
      fetchResource();
    }
  }, [fetchImmediately, fetchResource]);

  return [resource, errorMessage, fetchResource, loading];
}
