import React, { useState } from "react";
import { Button } from "@mui/material";
import { ConsentDialog } from "./ConsentDialog";
import { useTranslation } from "react-i18next";

export function ConsentButton({ email, app, details }) {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const url =
    "https://login.microsoftonline.com/organizations/v2.0/adminconsent";
  const params = {
    client_id: app.clientId,
    scope: "https://graph.microsoft.com/.default",
    redirect_uri: `${
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION
    }/office/consent/${app.name}`,
    state: email,
  };
  const urlParams = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");

  const fullUrl = `${url}?${urlParams}`;

  const handleClickOpen = () => {
    setOpen(true);
    setCopied(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConsent = () => {
    window.open(fullUrl, "_blank");
    handleClose();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(fullUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };
  const getConsentButtonText = () => {
    switch (details) {
      case "Users Sync":
        return t("grantConsentButton.userSync");
      case "Direct Message Injection":
        return t("grantConsentButton.directMessageInjection");
      case "Email Process":
        return t("grantConsentButton.emailProcess");
      default:
        return t("grantConsentButton.userSync");
    }
  };

  return (
    <>
      <Button variant="outlined" color="neutral" onClick={handleClickOpen}>
        {getConsentButtonText()}
      </Button>
      <ConsentDialog
        open={open}
        onClose={handleClose}
        onConsent={handleConsent}
        onCopyLink={handleCopyLink}
        copied={copied}
        fullUrl={fullUrl}
      />
    </>
  );
}
