import React from "react";
import {
  browserLanguage,
  useOnlyOnceEffect,
} from "@/pages/landing-pages/utils";
import { LANDING_PAGE_MESSAGES } from "@/pages/landing-pages/translations";
import { useSearchParams } from "react-router-dom";
import LandingPageLayout, {
  SupportedLanguage,
} from "@/pages/landing-pages/components/LandingPageLayout";
import { useReportThreat } from "@/pages/landing-pages/api";
import SplashScreen from "@/components/SplashScreen";
import css from "@/pages/landing-pages/components/LandingPage.module.css";

type ReportAsThreatPageProps = {
  lang: SupportedLanguage;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

export const ReportAsThreatPage = ({
  lang,
  isError,
  isLoading,
  error,
}: ReportAsThreatPageProps) => {
  const translate = LANDING_PAGE_MESSAGES[lang];
  if (isLoading) {
    return <SplashScreen />;
  }
  return (
    <LandingPageLayout lang={lang}>
      {isError ? (
        error?.response?.status === 403 ? (
          <div>{translate.action_not_allowed}</div>
        ) : (
          <div>{translate.an_error_has_occurred}</div>
        )
      ) : (
        <>
          <p className={css.message}>{translate.report_threat_email_send}</p>
          <p className={css.sub_message}>
            {translate.report_threat_wait_analysis}
          </p>
          <p className={css.sub_message}>
            {translate.report_threat_before_interacting}
          </p>
        </>
      )}
    </LandingPageLayout>
  );
};

export function ReportAsThreat() {
  const [searchParams] = useSearchParams();
  const { reportThreat, isLoading, isError, error } = useReportThreat();
  const browser_language = browserLanguage();

  useOnlyOnceEffect(() => {
    reportThreat({
      report_id: searchParams.get("report_id"),
      report_data: searchParams.get("report_data"),
    });
  });

  return (
    <ReportAsThreatPage
      lang={browser_language}
      isLoading={isLoading}
      isError={isError}
      error={error}
    />
  );
}
