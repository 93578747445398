import React, { useEffect, useState } from "react";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import { useTranslation } from "react-i18next";

export function EnableCheckbox() {
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  // retrieve the groups
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            `/provisioning/current_company/daily_provisioning_status`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setChecked(result.data.enabled);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  const onChange = async () => {
    setChecked(!checked);

    const accessToken = await getAccessTokenSilently();
    try {
      await axios.put(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          `/provisioning/current_company/daily_provisioning_status`,
        {
          enabled: !checked,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      setErrorMessage(null);
      if (!checked) {
        snackbar.showMessage(t("Provisioning enabled"));
      } else {
        snackbar.showMessage(t("Provisioning disabled"));
      }
    } catch (error) {
      setErrorMessage(extractErrorMessage(error));
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        id="provisioningEnabled"
        checked={checked}
        onChange={() => onChange()}
      />
      <label htmlFor="provisioningEnabled">
        &nbsp;&nbsp;&nbsp;{t("Enable daily user provisioning")}
      </label>
      {errorMessage !== null && (
        <Typography style={{ color: "red" }}>{errorMessage}</Typography>
      )}
    </div>
  );
}
