import { useLearningResource } from "@/utils/ResourceGet";

export const useCourses = () => {
  const [response, error] = useLearningResource(`courses`, {
    courses_info: [],
  });

  return [
    response.courses_info.filter((course) => course.published === true),
    error,
  ];
};
