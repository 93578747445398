import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useTranslation } from "react-i18next";

export function useStartReminders() {
  const { getAccessTokenSilently } = useAuth0();
  const user = useUserContext();
  const { t } = useTranslation();

  const startReminders = async (setLoading, refetchState) => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    try {
      await axios
        .post(
          import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/reminders/start",
          {},
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(() => {
          enqueueSnackbar(
            t(
              "Activation complete, {{company_name}} employees will receive their first courses tomorrow",
              { company_name: user.current_company.name },
            ),
            { variant: "success" },
          );
        });
    } catch (error) {
      enqueueSnackbar(t("Failed to activate automatic courses"), {
        variant: "error",
      });
    }
    refetchState();
    setLoading(false);
  };
  return { startReminders };
}
