import React from "react";
import { AllKeyPeople } from "@/pages/phishing-simulation/company/keypeople/AllKeyPeople.js";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "@mui/material";

export function KeyPeople() {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Key People")} />
      <CardContent>
        <div>
          <AllKeyPeople />
        </div>
      </CardContent>
    </Card>
  );
}
