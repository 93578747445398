import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SimulationScheduling } from "@/pages/phishing-simulation/setup/SimulationScheduling";
import { DaysBetweenSimulations } from "@/pages/phishing-simulation/setup/DaysBetweenSimulations";
import { useUserContext } from "@/utils/contexts/UserContext";
import { SimulationAccess } from "@/types/user";
import { SendOnboardings } from "@/pages/phishing-simulation/setup/SendOnboardings";
import { LandingPageOverride } from "@/pages/phishing-simulation/setup/LandingPageOverride";
import { ResetStats } from "@/pages/phishing-simulation/setup/ResetStats";

export function Preferences() {
  const user = useUserContext();
  const simulationAccess = user.current_company?.simulation_access;

  const isTrialMode = simulationAccess === SimulationAccess.TRIAL_MODE;
  const isFullAccess = simulationAccess === SimulationAccess.FULL_ACCESS;
  const componentsAccesses = [
    {
      component: <SendOnboardings />,
      access: isFullAccess,
      index: 0,
    },
    {
      component: <SimulationScheduling />,
      access: isFullAccess,
      index: 1,
    },
    {
      component: <LandingPageOverride />,
      access: isFullAccess || isTrialMode,
      index: 2,
    },
    {
      component: <DaysBetweenSimulations />,
      access: isFullAccess,
      index: 3,
    },
    {
      component: <ResetStats />,
      access: isFullAccess || isTrialMode,
      index: 4,
    },
  ];

  if (simulationAccess === SimulationAccess.NO_ACCESS) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h5" align="center">
          {simulationAccess === SimulationAccess.NO_ACCESS
            ? "Your company doesn't have access to phishing simulations. Ask Mantra to provide full access to display this page."
            : "This page manages automatic scheduling of simulations and is only accessible with a full subscription once your audit has been completed."}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack spacing={3}>
      {componentsAccesses
        .filter(({ access }) => access)
        .sort((a, b) => a.index - b.index)
        .map(({ component }) => component)}
    </Stack>
  );
}
