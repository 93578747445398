import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import {
  selectFilterSharingPolicy,
  selectSearchValue,
  setFilterSharingPolicy,
  setSearch,
} from "@/pages/awareness/courses/corporate-course-sharing/courseSharingSlice";
import { useDispatch, useSelector } from "react-redux";
import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";
import { SearchField } from "@/components/SearchField";

export const CourseSharingHeaderSecondRow = () => {
  const dispatch = useDispatch();

  const sharingPolicy = useSelector(selectFilterSharingPolicy);
  const searchValue = useSelector(selectSearchValue);

  const onChangeSharingPolicy = (e) => {
    dispatch(setFilterSharingPolicy(e.target.value));
  };

  const onSearchChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const commonStyles = {
    width: "250px",
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: "15px",
        gap: "20px",
      }}
    >
      <SearchField
        value={searchValue}
        onChange={onSearchChange}
        sx={commonStyles}
      />
      <FormControl sx={commonStyles}>
        <InputLabel id="course-owner-select-label">Sharing status</InputLabel>
        <Select
          startAdornment={<BookIcon sx={{ marginRight: "10px" }} />}
          labelId="course_select-label"
          id="course-simple-select"
          value={sharingPolicy}
          label="Sharing status"
          size="small"
          onChange={onChangeSharingPolicy}
          displayEmpty
        >
          <MenuItem
            id="select-all_courses"
            value={AllowedSharingPolicy.NO_POLICY}
          >
            All courses
          </MenuItem>
          <MenuItem
            id="select-shared"
            value={AllowedSharingPolicy.COMPANIES_OF_CORPORATE}
          >
            Shared
          </MenuItem>
          <MenuItem
            id="select-not_shared"
            value={AllowedSharingPolicy.COMPANY_ONLY}
          >
            Not shared
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
