import React from "react";
import { Consent } from "@/pages/general-settings/Office/Consent";
import { ExcludedUsers } from "@/pages/general-settings/commonUserProvisioning/ExcludedUsers";
import { EnableCheckbox } from "@/pages/general-settings/commonUserProvisioning/EnableCheckbox";
import { ManualSync } from "@/pages/general-settings/commonUserProvisioning/ManualSync";
import { GroupProvision } from "@/pages/general-settings/GroupProvision";
import { Typography, Paper, Stack, Box } from "@mui/material";
import { UsersWithNoLicenseCheckbox } from "@/pages/general-settings/Office/UsersWithNoLicenseCheckbox";
import { useTranslation } from "react-i18next";

export function OfficeSync() {
  const { t } = useTranslation();

  return (
    <Paper sx={{ padding: "2rem" }}>
      <Stack useFlexGap sx={{ gap: "4rem" }}>
        <Box>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Grant Consent")}
          </Typography>
          <Consent />
        </Box>
        <Box>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Excluded users (optional)")}
          </Typography>
          <ExcludedUsers />
        </Box>
        <Box>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Provisioned groups (optional)")}
          </Typography>
          <GroupProvision />
        </Box>
        <Stack>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Provisioning")}
          </Typography>
          <EnableCheckbox />
          <UsersWithNoLicenseCheckbox />
          <ManualSync />
        </Stack>
      </Stack>
    </Paper>
  );
}
