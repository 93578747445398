import React from "react";
import { alpha } from "@mui/material";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export function TypeCell({ value }) {
  const { t } = useTranslation();

  let color = "#666";
  let label: string = t("Unknown");
  if (value === "Domain") {
    color = "#7566FE";
    label = t("Safe Domain");
  } else if (value === "EmailAddress") {
    color = "#1EC9B7";
    label = t("Safe Senders");
  }

  if (!value) {
    return <></>;
  }
  return (
    <Chip
      variant="outlined"
      size={"medium"}
      sx={{
        backgroundColor: alpha(color, 0.1),
        borderColor: color,
        color: color,
        fontWeight: "bold",
      }}
      label={label}
    />
  );
}
