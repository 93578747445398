import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material";

export function UserDataTable({ users, options, columns }) {
  const getMuiTheme = () => {
    return createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              "&:nth-of-type(2)": {
                width: 70,
              },
            },
          },
        },
      },
    });
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Users"}
          data={users}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
