import React from "react";
import Box from "@mui/material/Box";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";

type AttachmentsProps = {
  attachmentNames: string[];
};

const Attachments = ({ attachmentNames }: AttachmentsProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {attachmentNames.map((name) => (
        <Box
          sx={{
            margin: "4px",
            padding: "3px",
            borderRadius: "3px",
            backgroundColor: "#f7f7f7",
            color: "#737373",
          }}
        >
          <AttachmentOutlinedIcon
            sx={{
              marginRight: "2px",
            }}
          />
          {name}
        </Box>
      ))}
    </Box>
  );
};

export default Attachments;
