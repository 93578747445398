import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { BrowserIcon } from "@/components/icons/BrowserIcon";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { PasswordExposedIcon } from "@/components/icons/PasswordExposedIcon";
import LaunchIcon from "@mui/icons-material/Launch";
import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import TableCell from "@mui/material/TableCell";
import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";
import ExtensionStatusChip from "@/pages/browser-defender/components/ExtensionStatusChip";
import EventsCounter from "@/pages/browser-defender/components/EventsCounter";
import { useTranslation } from "react-i18next";

const filterColumns = [
  "firstname",
  "lastname",
  "email",
  "language",
  "total_events",
];

export default function DataPerUser({ data, setData }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const headers = [
    { label: t("First Name"), alignment: "left" },
    { label: t("Last Name"), alignment: "left" },
    { label: t("Email"), alignment: "left" },
    { label: t("Language"), alignment: "left" },
    { label: t("Departments"), alignment: "left" },
    { label: t("Status"), alignment: "center" },
    { label: t("Events"), alignment: "center" },
  ];

  const sortingColumns = [
    { label: t("First Name"), name: "firstname" },
    { label: t("Last Name"), name: "lastname" },
    { label: t("Email"), name: "email" },
    { label: t("Language"), name: "language" },
    { label: t("Events"), name: "total_events" },
  ];

  useEffect(() => {
    let filteredValues = [...data.values];

    if (!searchTerm) {
      filteredValues = data.values;
    } else {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    if (sorting.length === 0) {
      setSorting([{ column: "total_events", direction: -1 }]);
    } else {
      setSorting(sorting);
    }

    filteredValues.sort((p1, p2) => {
      let condition = 1;
      for (let sortRule of sorting) {
        if (sortRule.column === "total_events") {
          const events1 =
            p1.password_misuse + p1.dangerous_downloads + p1.unsafe_navigation;
          const events2 =
            p2.password_misuse + p2.dangerous_downloads + p2.unsafe_navigation;
          condition *=
            events1 < events2
              ? -1 * sortRule.direction
              : events1 > events2
                ? 1 * sortRule.direction
                : 0;
        } else {
          condition *=
            p1[sortRule.column] < p2[sortRule.column]
              ? -1 * sortRule.direction
              : p1[sortRule.column] > p2[sortRule.column]
                ? 1 * sortRule.direction
                : 0;
        }
      }
      return condition;
    });

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [data.values, searchTerm, sorting, setData]);

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      defaultOrder={[{ column: "total_events", direction: -1 }]}
      rows={data.filteredValues.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {row.firstname}
            </TableCell>
            <TableCell align={headers[1].alignment}>{row.lastname}</TableCell>
            <TableCell align={headers[2].alignment}>
              <Link
                to="/browser-defender/user-details"
                state={{ email: row.email }}
              >
                <LaunchIcon sx={{ fontSize: "15px" }} /> {row.email}
              </Link>
            </TableCell>
            <TableCell align={headers[3].alignment}>{row.language}</TableCell>
            <TableCell align={headers[4].alignment}>
              {row.departments.join(", ")}
            </TableCell>
            <TableCell align={headers[5].alignment}>
              <ExtensionStatusChip
                status={{
                  key: row.protection_status,
                  label: t(row.protection_status_label),
                }}
              />
            </TableCell>
            <TableCell>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <EventsCounter
                    data={row.password_misuse}
                    icon={<PasswordExposedIcon />}
                    tooltipValue={t("Password alerts")}
                  />
                </Grid>
                <Grid item>
                  <EventsCounter
                    data={row.dangerous_downloads}
                    icon={<FolderDownloadIcon />}
                    tooltipValue={t("Dangerous Downloads")}
                  />
                </Grid>
                <Grid item>
                  <EventsCounter
                    data={row.unsafe_navigation}
                    icon={<BrowserIcon />}
                    tooltipValue={t("Dangerous Browsing")}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </StyledTableRow>
        );
      })}
    />
  );
}
