export const LANDING_PAGE_MESSAGES = {
  fr: {
    mark_as_safe_add_to_safe: "a été ajouté à vos contacts vérifiés",
    mark_as_safe_not_display_again:
      "Nous n’afficherons plus de bannières sur les emails de cet expéditeur",
    report_threat_email_send: "L'email a été transmis à votre département IT",
    report_threat_wait_analysis:
      "Nous vous recommandons d'attendre leur analyse",
    report_threat_before_interacting:
      " avant d'interagir avec cet email ou son expéditeur",
    threat_notification_disabled:
      "Les notifications des menaces sont maintenant désactivées.",
    action_not_allowed:
      "Cette action n’est pas autorisée, vous ne pouvez signaler que les emails dont vous êtes le destinataire.",
    an_error_has_occurred: "Une erreur est survenue",
    sender_has_not_been_added:
      "L'expéditeur n'a pas été ajouté a vos contacts vérifiés",
  },
  en: {
    mark_as_safe_add_to_safe: "has been added to your safe senders list",
    mark_as_safe_not_display_again:
      "We won't display any banner on future emails coming from this sender",
    report_threat_email_send:
      "The email has been forwarded to your IT department",
    report_threat_wait_analysis: "We recommend you to wait for their analysis",
    report_threat_before_interacting:
      "before interacting with this email or its sender",
    threat_notification_disabled: "Threat notifications are now disabled.",
    action_not_allowed:
      "This action is not permitted; you can only report emails that you are the recipient of.",
    an_error_has_occurred: "An error has occurred",
    sender_has_not_been_added:
      "The sender has not been added to your safe sender list",
  },
};

export const LANDING_PAGE_LAYOUT = {
  fr: {
    close: "Vous pouvez fermer cet onglet",
    footer: "Pour toute demande, contactez",
  },
  en: {
    footer: "For any inquiry, please contact",
    close: "You can close this tab",
  },
};
