import CustomCampaignDetailsHeader from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignDetails/CustomCampaignDetailsHeader";
import { CustomCampaignTheme } from "@/pages/phishing-simulation/CustomCampaign/theme";
import { useBackend } from "@/utils/misc";
import { Box, Card, CardContent, LinearProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PanelCustomCampaignDetails() {
  const { t } = useTranslation();

  const [queryParams] = useSearchParams();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const id = queryParams.get("id");

  let params = new URLSearchParams();

  if (selectedDepartment) {
    params.append("department_id", selectedDepartment);
  }
  const url =
    "/custom_campaign/details_custom_campaign/" + id + "?" + params.toString();
  const endpointUrl = import.meta.env.VITE_APP_ENDPOINT_SIMULATION + url;

  const { data, isPending } = useBackend(endpointUrl);

  const columns: GridColDef[] = [
    {
      field: "firstname",
      headerName: t("First Name"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      field: "lastname",
      headerName: t("Last Name"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: t("Email"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      field: "departments",
      headerName: t("Department"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      field: "opened",
      headerName: t("Opened"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      field: "reported",
      headerName: t("Reported"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      field: "clicked",
      headerName: t("Clicked"),
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <ThemeProvider theme={CustomCampaignTheme}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data && (
          <CustomCampaignDetailsHeader
            departments={data.departments}
            customCampaignDetails={data.campaigns_info}
            setSelectedDepartment={setSelectedDepartment}
          />
        )}
        <Divider />
        <Box height={"10px"}>{isPending && <LinearProgress />}</Box>
        <CardContent sx={{ height: "100%" }}>
          {data && (
            <DataGrid
              rows={data.details_per_user}
              sx={{ height: "100%" }}
              columns={columns}
              disableColumnMenu
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[10, 20, 50, 100]}
            />
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
