import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Popover,
  PopoverProps,
  Stack,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { DateRange } from "./DateRange";
import { MANTRA_CREATION_DATE } from "@/utils/constants";
import dayjs from "dayjs";
import { t } from "i18next";

type RangeCalendarPopoverProps = {
  dateRange: DateRange;
  updateDateRange: (v: DateRange) => void;
  anchorEl: PopoverProps["anchorEl"];
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
};

const RangeCalendarPopover = (props: RangeCalendarPopoverProps) => {
  const [internalDateRange, setInternalDateRange] = useState({
    from: props.dateRange.from.clone(),
    to: props.dateRange.to.clone(),
  });

  const updateExternalDateRange = () => {
    props.updateDateRange({
      from: internalDateRange.from.clone(),
      to: internalDateRange.to.clone(),
    });
  };

  const onCancel = () => {
    setInternalDateRange({
      from: props.dateRange.from.clone(),
      to: props.dateRange.to.clone(),
    });
    props.setAnchorEl(null);
  };

  const onClickConfirm = () => {
    updateExternalDateRange();
    props.setAnchorEl(null);
  };

  useEffect(() => {
    if (props.anchorEl == null) {
      return;
    }
    setInternalDateRange({
      from: props.dateRange.from.clone(),
      to: props.dateRange.to.clone(),
    });
  }, [props.anchorEl, props.dateRange.from, props.dateRange.to]);

  const isInvalidDate = internalDateRange.to.isBefore(
    internalDateRange.from,
    "day",
  );

  return (
    <>
      <Popover
        anchorEl={props.anchorEl}
        open={props.anchorEl != null}
        onClose={onCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction="row">
          <Stack alignItems="center">
            <Typography my={2} variant="h6">
              {t("Start Date")}
            </Typography>
            <Divider flexItem />
            <DateCalendar
              fixedWeekNumber={6}
              views={["day", "month", "year"]}
              value={internalDateRange.from}
              onChange={(v) =>
                setInternalDateRange((range) => ({ from: v, to: range.to }))
              }
              minDate={MANTRA_CREATION_DATE}
              maxDate={dayjs()}
            />
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack alignItems="center">
            <Typography my={2} variant="h6">
              {t("End Date")}
            </Typography>
            <Divider flexItem />
            <DateCalendar
              fixedWeekNumber={6}
              views={["day", "month", "year"]}
              value={internalDateRange.to}
              onChange={(v) =>
                setInternalDateRange((range) => ({ from: range.from, to: v }))
              }
              minDate={MANTRA_CREATION_DATE}
              maxDate={dayjs()}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="baseline"
          padding={2}
        >
          {isInvalidDate ? (
            <Typography color="red" sx={{ mr: 3 }}>
              {t("End date is earlier than start date")}
            </Typography>
          ) : (
            ""
          )}
          <Button variant="outlined" sx={{ mr: 1 }} onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={onClickConfirm}
            disabled={isInvalidDate}
          >
            {t("Confirm")}
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default RangeCalendarPopover;
