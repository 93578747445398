import React, { PropsWithChildren } from "react";
import Box from "@mui/material/Box";

const Toolbar = (props: PropsWithChildren) => {
  return (
    <Box sx={{ marginTop: "20px", marginBottom: "0.5rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: "0.7rem" }}>{props.children}</Box>
      </Box>
    </Box>
  );
};

export default Toolbar;
