import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { ThreatListTab, ThreatSource } from "@/pages/threatslg/threatslg_types";

type ThreatTabsProps = {
  onChange: (props: { archived: boolean; source: ThreatSource | null }) => void;
};

const ThreatTabs = ({ onChange }: ThreatTabsProps) => {
  const [activeTab, setActiveTab] = useState<ThreatListTab>("active-threats");

  const handleTabChange = (newTab: ThreatListTab) => {
    setActiveTab(newTab);

    switch (newTab) {
      case "active-threats":
        onChange({ archived: false, source: null });
        break;
      case "active-user-threats":
        onChange({ archived: false, source: "user" });
        break;
      case "active-system-threats":
        onChange({ archived: false, source: "system" });
        break;
      case "archived-threats":
        onChange({ archived: true, source: null });
        break;
      default:
        throw new Error(`Invalid tab: '${newTab}'`);
    }
  };

  return (
    <Tabs
      indicatorColor="primary"
      textColor="inherit"
      className="builder-tabs"
      value={activeTab}
      onChange={(_, nextTab) => handleTabChange(nextTab)}
      sx={{ minHeight: "45px", height: "45px" }}
    >
      <Tab disableRipple label="All threats" value="active-threats" />
      <Tab disableRipple label="User Reports" value="active-user-threats" />
      <Tab disableRipple label="Banner alerts" value="active-system-threats" />
      <Tab disableRipple label="Archived" value="archived-threats" />
    </Tabs>
  );
};

export default ThreatTabs;
