import React, {
  ClipboardEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TrashIconButton from "../buttons/TrashIconButton";
import { useSnackbar } from "material-ui-snackbar-provider";
import { debounce } from "lodash";
import { ButtonSpinner } from "../Spinner";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { CancelIcon } from "../stepper/icons/CancelIcon";

export type Item = {
  value: string;
  key: any;
};

export type AddItemErrorMessagesProps = {
  itemErrorMessage: string | null;
  itemSuccessMessage: string | null;
  bulkAddErrorMessage: string | null;
  bulkSomeDuplicatedEntriesMessage: (validEntries: number) => void | null;
};

export type AddItemFieldProps = {
  addItem: (item: string) => Promise<void>;
  bulkAddItems: (items: string[]) => Promise<void>;
  newItemPlaceholder: string;
};

export type AddItemProps = {
  validate: (item: string, key?: never) => boolean;
} & AddItemErrorMessagesProps &
  AddItemFieldProps;

const AddItem = ({
  validate,
  addItem,
  newItemPlaceholder,
  itemErrorMessage,
  itemSuccessMessage,
  bulkAddItems,
  bulkAddErrorMessage,
  bulkSomeDuplicatedEntriesMessage,
}: AddItemProps) => {
  const [newItem, setNewItem] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();

  const onClick = async () => {
    setIsLoading(true);
    try {
      await addItem(newItem);
      setNewItem("");
      snackbar.showMessage(itemSuccessMessage);
    } catch {
      snackbar.showMessage(itemErrorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  /*
   * add new entry when clicking enter
   */
  const onKeyUp: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    if (validate(newItem)) {
      onClick();
    }
  };

  /*
   * Bulk update on paste
   */
  const onPaste: ClipboardEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let textToPaste = e.clipboardData.getData("text");
    if (textToPaste.length <= 0) {
      return;
    }

    if (newItem.length > 0) {
      setNewItem((newItem) => newItem + textToPaste);
      return;
    }

    const values = textToPaste
      .split(/\n|,/)
      .map((value) => value.trim())
      .filter((value) => value.length > 0);

    if (values.length === 1) {
      setNewItem(values[0]);
      return true;
    }

    const filteredValues = values.filter((value) => validate(value));

    if (filteredValues.length === 0) {
      snackbar.showMessage(bulkAddErrorMessage);
    } else if (values.length !== filteredValues.length) {
      if (bulkSomeDuplicatedEntriesMessage instanceof Function) {
        snackbar.showMessage(
          (bulkSomeDuplicatedEntriesMessage as Function)(filteredValues.length),
        );
      }
    }

    try {
      setIsLoading(true);
      await bulkAddItems(filteredValues);
    } catch (e) {
      snackbar.showMessage(e.toString());
    } finally {
      setIsLoading(false);
    }
    return true;
  };

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        variant="outlined"
        size="small"
        sx={{
          marginRight: "1rem",
          width: "200px",
        }}
        placeholder={newItemPlaceholder}
        value={newItem}
        disabled={isLoading}
        onChange={(e) => {
          setNewItem(e.target.value);
        }}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
      />
      <Button
        variant="outlined"
        color="neutral"
        onClick={onClick}
        disabled={!validate(newItem) || isLoading}
      >
        {isLoading ? <ButtonSpinner /> : <AddIcon />}
      </Button>
    </Stack>
  );
};

export type SingleItemFieldProps = {
  item: Item;
  validate: (value: string, key: any | null) => boolean;
  updateValue: (value: string, key: any) => Promise<void>;
  deleteItem: () => void;
  editErrorMessage: string | null;
};

enum FieldState {
  Initial,
  Created,
  Updated,
  Error,
  Pending,
}

const SingleItemField = (props: SingleItemFieldProps) => {
  const [itemValue, setItemValue] = useState(props.item.value);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenSuccessfullyUpdated, setHasBeenSuccessfullyUpdated] =
    useState(false);

  let fieldState = useMemo(() => {
    if (isLoading) {
      return FieldState.Pending;
    } else if (hasBeenSuccessfullyUpdated) {
      return FieldState.Updated;
    } else if (!isValid && !hasBeenSuccessfullyUpdated) {
      return FieldState.Error;
    } else {
      return FieldState.Initial;
    }
  }, [isLoading, hasBeenSuccessfullyUpdated, isValid]);

  let fieldStateDom: JSX.Element;
  switch (fieldState) {
    case FieldState.Initial:
      fieldStateDom = <></>;
      break;
    case FieldState.Pending:
      fieldStateDom = <ButtonSpinner />;
      break;
    case FieldState.Updated:
      fieldStateDom = <CheckCircleOutlinedIcon color="success" />;
      break;
    case FieldState.Error:
      fieldStateDom = (
        <Tooltip title={props.editErrorMessage} arrow>
          <CancelIcon />
        </Tooltip>
      );
      break;
  }

  const requestUpdateValue = useCallback(
    async (value: string, key: any) => {
      if (!props.validate(value, key)) {
        setIsValid(false);
        return;
      }
      try {
        setIsLoading(true);
        await props.updateValue(value, key);
        setHasBeenSuccessfullyUpdated(true);
      } catch (e) {
        setIsValid(false);
      } finally {
        setIsLoading(false);
      }
    },
    [props],
  );

  const debouncedUpdateValue = useMemo(
    () => debounce(requestUpdateValue, 500),
    [requestUpdateValue],
  );

  return (
    <Stack direction="row" alignItems="center">
      <FormControl sx={{ marginRight: "1rem", width: "200px" }}>
        <Input
          size="small"
          value={itemValue}
          error={!isValid}
          onChange={(e) => {
            setHasBeenSuccessfullyUpdated(false);
            setIsValid(true);
            setItemValue(e.target.value);
            debouncedUpdateValue(e.target.value, props.item.key);
          }}
        />
      </FormControl>
      <Stack minWidth="64px" justifyContent="center">
        <TrashIconButton onClick={props.deleteItem} />
      </Stack>
      {fieldStateDom}
    </Stack>
  );
};

export type ListItemsFormFieldProps = {
  items: Item[];
  validate: (item: string, key: any) => boolean;
  deleteItem: (key: any) => Promise<void>;
  setItem: (value: string, key: any) => Promise<void>;
  tooltipPlaceholder: JSX.Element | undefined;
  editErrorMessage: string | null;
} & AddItemErrorMessagesProps &
  AddItemFieldProps;

const ListItemsFormField = (props: ListItemsFormFieldProps) => {
  const scrollableBox = useRef<HTMLDivElement>();
  const previousLengthItems = useRef<number>(0);
  const lengthItems = useMemo(() => props.items.length, [props.items]);

  useEffect(
    function scrollToBottom() {
      if (previousLengthItems.current < lengthItems) {
        scrollableBox.current.scrollIntoView({ block: "end" });
      }
      previousLengthItems.current = lengthItems;
    },
    [lengthItems, previousLengthItems],
  );

  return (
    <>
      <Stack minWidth="220px" maxWidth="350px">
        <Stack maxHeight="300px" overflow="scroll">
          {props.items.map((item: Item) => {
            return (
              <SingleItemField
                key={item.key}
                item={item}
                updateValue={props.setItem}
                validate={props.validate}
                deleteItem={() => props.deleteItem(Number(item.key))}
                editErrorMessage={props.editErrorMessage}
              />
            );
          })}
          <Box ref={scrollableBox}></Box>
        </Stack>
        <Stack marginTop="1rem" direction="row" alignItems="center">
          <AddItem
            itemErrorMessage={props.itemErrorMessage}
            itemSuccessMessage={props.itemSuccessMessage}
            bulkAddErrorMessage={props.bulkAddErrorMessage}
            bulkAddItems={props.bulkAddItems}
            {...props}
          />
          {props.tooltipPlaceholder}
        </Stack>
      </Stack>
    </>
  );
};

export default ListItemsFormField;
