import { CollapseAutocomplete } from "@/pages/awareness/setup/Teams/CollapseAutocomplete";
import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CollapseDepartmentAutocomplete } from "@/pages/awareness/setup/Teams/CollapseDepartmentAutocomplete";
import { useTranslation } from "react-i18next";

const CustomLabelFormController = ({ title, text }) => {
  return (
    <Box sx={{ paddingLeft: "5rem" }}>
      <Typography variant={"h5"}>{title}</Typography>
      <Typography color={"text.secondary"}>{text}</Typography>
    </Box>
  );
};

export const RadioFormControl = ({
  deploymentType,
  setDeploymentType,
  deployedUsers,
  setDeployedUsers,
  setDeployedDepartments,
  deployedDepartments,
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openDepartmentCollapse, setOpenDepartmentCollapse] = useState(false);

  useEffect(() => {
    if (deploymentType === "users") {
      setOpenCollapse(true);
    } else {
      setOpenCollapse(false);
    }

    if (deploymentType === "departments") {
      setOpenDepartmentCollapse(true);
    } else {
      setOpenDepartmentCollapse(false);
    }
  }, [deploymentType]);

  const handleDeploymentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDeploymentType((event.target as HTMLInputElement).value);
  };
  const { t } = useTranslation();

  return (
    <StyledBox sx={{ minWidth: "80%" }}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={deploymentType}
          onChange={handleDeploymentTypeChange}
        >
          <Box
            sx={{
              width: "600px",
              borderRadius: "16px",
              padding: "2rem",
              ...(deploymentType === "company" && { border: 2 }),
              borderColor: "primary.main",
            }}
          >
            <FormControlLabel
              value="company"
              control={<Radio />}
              label={
                <CustomLabelFormController
                  title={t("Company-wide")}
                  text={t("Deploy Alex on all your organisations")}
                />
              }
            />
          </Box>
          <Box
            sx={{
              borderRadius: "16px",
              padding: "2rem",
              ...(deploymentType === "departments" && { border: 2 }),
              borderColor: "primary.main",
            }}
          >
            <FormControlLabel
              value="departments"
              control={<Radio />}
              label={
                <CustomLabelFormController
                  title={t("On selected departments")}
                  text={t(
                    "Deploy Alex on all users of the selected departments",
                  )}
                />
              }
            />
            <CollapseDepartmentAutocomplete
              openCollapse={openDepartmentCollapse}
              deployedDepartments={deployedDepartments}
              setDeployedDepartments={setDeployedDepartments}
            />
          </Box>
          <Box
            sx={{
              borderRadius: "16px",
              padding: "2rem",
              ...(deploymentType === "users" && { border: 2 }),
              borderColor: "primary.main",
            }}
          >
            <FormControlLabel
              value="users"
              control={<Radio />}
              label={
                <CustomLabelFormController
                  title={t("On selected users")}
                  text={t("Deploy Alex on all selected users")}
                />
              }
            />
            <CollapseAutocomplete
              openCollapse={openCollapse}
              deployedUsers={deployedUsers}
              setDeployedUsers={setDeployedUsers}
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </StyledBox>
  );
};
