import React, { useEffect, useMemo, useState } from "react";
import {
  Slider,
  Typography,
  Box,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { debounce } from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

export const DaysBetweenSimulations = () => {
  const [days, setDays] = useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSimulationPeriod = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const url = `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/company/simulation_period`;
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setDays(response.data.simulation_period);
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error));
      }
    };

    fetchSimulationPeriod();
  }, [snackbar, getAccessTokenSilently]);

  const marks = [
    { value: 1, label: "1" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
  ];

  const updateDaysBetweenSimulations = useMemo(
    () =>
      debounce(async (days) => {
        const accessToken = await getAccessTokenSilently();
        try {
          await axios.put(
            `${
              import.meta.env.VITE_APP_ENDPOINT_SIMULATION
            }/company/simulation_period`,
            { simulation_period: days },
            { headers: { Authorization: `Bearer ${accessToken}` } },
          );
          snackbar.showMessage(`Updated to ${days} days`);
        } catch (error) {
          snackbar.showMessage(extractErrorMessage(error));
        }
      }, 1000),
    [getAccessTokenSilently, snackbar],
  );

  const handleChange = (newValue) => {
    const value = Number(newValue);
    setDays(value);
    if (value > 0 && value <= 30) {
      updateDaysBetweenSimulations(value);
    }
  };

  return (
    <Card>
      <CardHeader title={t("Minimum duration between two simulations")} />
      <CardContent>
        <div>
          <Slider
            aria-labelledby="discrete-slider-small-steps"
            step={1}
            marks={marks}
            min={1}
            max={30}
            valueLabelDisplay="auto"
            value={days}
            onChange={(event, newValue) => handleChange(newValue)}
          />
          <Box display="flex" alignItems="center">
            <Typography>
              {t("The duration between two simulations")}{" "}
              <strong>{t("ranges randomly from")}</strong>
            </Typography>
            <Box mx={1}>
              <input
                type="number"
                id="daysBetweenSimulationInput"
                size={5}
                min="1"
                max="30"
                value={days}
                onChange={(event) => handleChange(event.target.value)}
                style={{ width: "50px" }}
              />
            </Box>
            <Typography>
              <strong>{t("to {{days}} days", { days: days * 2 })}</strong>
              <Tooltip
                title={t("For an email every month on average, select 20 days")}
                arrow
              >
                <InfoIcon
                  sx={{
                    ml: 1,
                    fontSize: "medium",
                    cursor: "pointer",
                    verticalAlign: "middle",
                    color: "darkgray",
                  }}
                />
              </Tooltip>
            </Typography>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};
