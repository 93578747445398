import React from "react";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useLazyDownloadLeaderboardQuery } from "./LeaderboardApi";
import { ButtonSpinner } from "@/components/Spinner";
import { Typography } from "@mui/material";
import DownloadButton from "@/components/buttons/DownloadButton";
import { DateRange } from "@/components/controls/DateRangePicker/DateRange";

export const _downloadCSV = (data: string, dateRange: DateRange) => {
  const filename = `leaderboard_${dateRange.from.toISOString()}_${dateRange.to.toISOString()}.csv`;
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(data),
  );
  link.setAttribute("download", filename);
  link.click();
};

export const DownloadLeaderboardButton = ({
  dateRange,
  dataHandlingCallback = _downloadCSV,
}: {
  dateRange: DateRange;
  dataHandlingCallback?: (data: string, dateRange: DateRange) => void;
}) => {
  const { t } = useTranslation();
  const { current_company } = useUserContext();
  const requestParams = {
    company_id: current_company.id,
    dateRange: dateRange,
  };

  const [doQuery, { isFetching, error }] = useLazyDownloadLeaderboardQuery();

  const query = async () => {
    const response = await doQuery(requestParams);
    dataHandlingCallback(response.data, dateRange);
  };

  if (isFetching) {
    return <ButtonSpinner />;
  }

  return (
    <>
      {error ? (
        <Typography variant="helper_text" color="error">
          {t("An error occured")}
        </Typography>
      ) : (
        ""
      )}
      <DownloadButton
        title={t("Download current leaderboard as CSV file")}
        onClick={query}
      />
    </>
  );
};
