import React from "react";
import { useTranslation } from "react-i18next";

export function GroupList({ title, groups }) {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-md-6">
        <h6>{title}</h6>
        {(groups || []).length === 0 && (
          <>
            {t("None")}
            <br />
            <br />
          </>
        )}
        {(groups || []).length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => {
                return (
                  <tr key={group.email}>
                    <td>{group.name}</td>
                    <td>{group.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
