import React, { useState } from "react";
import SelectedCount from "@/pages/threatslg/components/details_pane/toolbar/SelectedCount";
import ThreatDeleteConfirmationDialog from "@/pages/threatslg/components/ThreatDeleteConfirmationDialog";
import ThreatFeedbackDialog from "@/pages/threatslg/components/ThreatFeedbackDialog";
import { FeedbackType, Threat } from "@/pages/threatslg/threatslg_types";
import Box from "@mui/material/Box";
import UnarchiveButton from "@/pages/threatslg/components/details_pane/toolbar/UnarchiveButton";
import DeleteButton from "@/pages/threatslg/components/details_pane/toolbar/DeleteButton";
import FeedbackButtons from "@/pages/threatslg/components/details_pane/toolbar/FeedbackButtons";

type DetailPaneHeaderProps = {
  enabled?: boolean;
  selectedThreats: Threat[];
  areThreatsArchived: boolean;

  onDelete?: () => void;
  onUnarchive?: () => void;
  onSendFeedback?: (
    feedbackType: FeedbackType,
    feedbackComment: string,
  ) => void;
};

const DetailPaneHeader = ({
  enabled = true,
  selectedThreats = [],
  areThreatsArchived = false,
  onDelete = () => {},
  onUnarchive = () => {},
  onSendFeedback = () => {},
}: DetailPaneHeaderProps) => {
  const [threatsToDelete, setThreatsToDelete] = useState<Threat[]>([]);
  const [confirmFeedbackType, setConfirmFeedbackType] =
    useState<FeedbackType | null>(null);

  const showDeleteDialog = () => setThreatsToDelete(selectedThreats);

  const handleDeleteThreatsConfirmation = () => {
    onDelete();
    setThreatsToDelete([]);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <SelectedCount count={selectedThreats.length} />
        {areThreatsArchived ? (
          <UnarchiveButton onClick={onUnarchive} enabled={enabled} />
        ) : (
          <FeedbackButtons onClick={setConfirmFeedbackType} enabled={enabled} />
        )}
        <DeleteButton onClick={showDeleteDialog} enabled={enabled} />
      </Box>
      <ThreatDeleteConfirmationDialog
        onDelete={handleDeleteThreatsConfirmation}
        onCancel={() => setThreatsToDelete([])}
        threats={threatsToDelete}
      />
      <ThreatFeedbackDialog
        onConfirm={(feedbackType: FeedbackType, comment?: string) => {
          onSendFeedback(feedbackType, comment);
          setConfirmFeedbackType(null);
        }}
        onCancel={() => setConfirmFeedbackType(null)}
        action={confirmFeedbackType}
        threatCount={selectedThreats.length}
      />
    </>
  );
};

export default DetailPaneHeader;
