import React from "react";
import { Mailbox } from "@/pages/threatslg/threatslg_types";

type MailboxTextProps = {
  label: string;
  mailbox: Mailbox;
};

const MailboxText = ({ label, mailbox }: MailboxTextProps) => {
  if (mailbox.name) {
    return (
      <div>
        {label}: <strong>{mailbox.name}</strong> ({mailbox.email})
      </div>
    );
  } else {
    return (
      <div>
        {label}: <strong>{mailbox.email}</strong>
      </div>
    );
  }
};

export default MailboxText;
