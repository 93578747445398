import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "@/utils/contexts/UserContext";
import SplashScreen from "@/components/SplashScreen";
import { useAuth0 } from "@auth0/auth0-react";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";

type AuthenticatedComponentProps = {
  children: React.ReactNode;
  permissions?: Permission[];
};

export function AuthenticatedComponent({
  children,
  permissions,
}: AuthenticatedComponentProps) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { is_authenticated: isUserReady, permissions: userPermission } =
    useUserContext();

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname + window.location.search,
      },
    });
  }

  if (!isUserReady) {
    return <SplashScreen />;
  }
  if (
    permissions !== undefined &&
    !permissions.some((x) => userPermission.includes(x))
  ) {
    return <Navigate to="/my-progress" />;
  } else {
    return <>{children}</>;
  }
}
