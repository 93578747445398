import React from "react";
import { Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const DeleteButton = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  return (
    <Button
      variant="outlined"
      color="neutral"
      sx={{ flex: "0 0 auto" }}
      onClick={onClick}
      disabled={!enabled}
      title="delete one or more threat"
    >
      <DeleteOutlineIcon />
    </Button>
  );
};

export default DeleteButton;
