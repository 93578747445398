import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ToolbarImportJsonButton from "./CourseEditorImportJsonButton";
import {
  selectAvailablePublishedLanguages,
  selectCourse,
  selectCourseId,
  selectCurrentLanguage,
  selectCurrentLocalizedCourse,
  selectDraftLocalizedCourses,
  selectEditMode,
  selectHasInteractions,
  toggleEditMode,
} from "@/pages/awareness/courses/course-editor/courseSlice";
import { EmailSent } from "@/components/icons/EmailSent";
import EyeIcon from "@/components/icons/EyeIcon";
import SaveIcon from "@/components/icons/SaveIcon";
import PencilIcon from "@/components/icons/PencilIcon";
import {
  useTestCourseCompany,
  useTestCourseDepartments,
  useTestCourseUsers,
  useTestLocalizedCourse,
} from "./hooks";
import { ButtonSpinner } from "@/components/Spinner";
import {
  useCreateDraftFromLocalizedCourseMutation,
  usePublishAllLocalizedCoursesMutation,
  usePublishLocalizedCourseMutation,
} from "@/api";
import { useSnackbar } from "material-ui-snackbar-provider";
import { isEmpty } from "lodash";
import { PublishLocalizedCourseModal } from "./PublishLocalizedCourseModal";
import axios from "axios";
import { selectIsMantraUser } from "@/utils/contexts/AuthSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AudienceSelector } from "@/components/AudienceSelector";
import { useTranslation } from "react-i18next";

const CourseEditorToolbar = () => {
  const editMode = useSelector(selectEditMode);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const course = useSelector(selectCourse);
  const courseId = useSelector(selectCourseId);
  const availablePublishedCourses = useSelector(
    selectAvailablePublishedLanguages,
  );
  const draftLocalizedCourses = useSelector(selectDraftLocalizedCourses);
  const hasInteractions = useSelector(selectHasInteractions);
  const dispatch = useDispatch();
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const isMantraUser = useSelector(selectIsMantraUser);
  const { sendTestCourseUsers } = useTestCourseUsers();
  const { sendTestCourseDepartments } = useTestCourseDepartments();
  const { sendTestCourseCompany } = useTestCourseCompany();
  const { sendTestLocalizedCourse, isTestLocalizedCourseLoading } =
    useTestLocalizedCourse();
  const [publishLocalized, { isLoading: isLoadingPublish }] =
    usePublishLocalizedCourseMutation();
  const [publishAll, { isLoading: isLoadingPublishAll }] =
    usePublishAllLocalizedCoursesMutation();
  const [createDraft, { isLoading: isLoadingDraft }] =
    useCreateDraftFromLocalizedCourseMutation();
  const snackbar = useSnackbar();
  const [showPublishModal, setShowPublishModal] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [courseRecipients, setCourseRecipients] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [openRecipientSelectionDialog, setOpenRecipientSelectionDialog] =
    useState(false);
  const [audienceSelectionMode, setAudienceSelectionMode] = useState("");
  const [
    openCourseExpeditionConfirmationDialog,
    setOpenCourseExpeditionConfirmationDialog,
  ] = useState(false);
  const [userList, setUserList] = useState([]);
  const { t } = useTranslation();

  const canDeployChange =
    hasInteractions &&
    localizedCourse?.name?.length > 0 &&
    localizedCourse?.description?.length > 0;

  const getUsersFromLearning = async (e = null) => {
    let input = e?.target.value;
    if (!input) {
      input = "";
    }

    try {
      let urlParams = new URLSearchParams();
      urlParams.append("email", input);
      urlParams.append("parseUserNotSetup", "True");

      const endpoint_url = `${
        import.meta.env.VITE_APP_ENDPOINT_LEARNING
      }/users/search_by_email?${urlParams.toString()}`;
      const response = await axios.get(endpoint_url, {});
      setUserList(response.data);
    } catch (error) {
      const message =
        error.response?.status === 403
          ? t("Forbidden")
          : t("An error occurred. Tech team has been notified.");
      snackbar.showMessage(message);
    }
  };
  const fetchUsers = useCallback(getUsersFromLearning, [snackbar, t]);

  const doPublishCourse = async (all: boolean = false) => {
    let response;
    try {
      if (all) {
        response = await publishAll({
          id: courseId,
        });
      } else {
        response = await publishLocalized({
          id: localizedCourse.id,
          language: localizedCourse.language,
        });
      }
      if (response.error !== undefined) {
        snackbar.showMessage(t("Unable to publish course"));
      } else {
        snackbar.showMessage(t("Course successfully published"));
      }
    } catch {
      snackbar.showMessage(t("Unable to publish course"));
    }
  };

  const sendCourse = async () => {
    switch (audienceSelectionMode) {
      case "users":
        if (courseRecipients.length === 1) {
          sendTestLocalizedCourse(
            localizedCourse.id,
            courseRecipients[0].user.id,
          );
        } else if (courseRecipients.length > 1) {
          sendTestCourseUsers(
            courseId,
            courseRecipients.map((x) => x.user.id),
          );
        }
        break;
      case "departments":
        sendTestCourseDepartments(
          courseId,
          selectedDepartments.map((x) => x.department.id),
        );
        break;
      case "company":
        sendTestCourseCompany(courseId);
        break;
    }
  };

  const onClickPublishCourse = async () => {
    const draftsNumbers = (Object.keys(draftLocalizedCourses) ?? []).length;
    if (draftsNumbers > 1) {
      setShowPublishModal(true);
    } else {
      doPublishCourse(false);
    }
  };

  const onClickEditContent = async () => {
    const currentLanguageDraft = draftLocalizedCourses[currentLanguage];
    if (!isEmpty(currentLanguageDraft)) {
      dispatch(toggleEditMode());
    } else if (localizedCourse?.id != null) {
      const response = await createDraft({
        id: localizedCourse?.id,
      });
      // @ts-ignore
      if (response.error === undefined) {
        dispatch(toggleEditMode());
        snackbar.showMessage(
          t("New draft of published course successfully created"),
        );
      } else {
        snackbar.showMessage(t("An error occurred. Please retry"));
      }
    } else {
      snackbar.showMessage(t("An error occurred. Please retry"));
    }
  };

  useEffect(() => {
    const fetchUserSelf = async () => {
      try {
        const endpoint_url = `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/user`;
        const response = await axios.get(endpoint_url, {});
        setUserInfo(response.data);
        setSelectedUser(response.data);
      } catch (error) {
        const message =
          error.response?.status === 403
            ? t("Forbidden")
            : t("An error occurred. Tech team has been notified.");
        snackbar.showMessage(message);
      }
    };
    fetchUserSelf();
  }, [snackbar, t]);

  const truncateLength = (sequence: string, char_limit: number) => {
    if (sequence && sequence.length > char_limit) {
      return sequence.substring(0, char_limit) + "...";
    }
    return sequence;
  };

  return (
    <Box sx={{ display: "flex", gap: "10px", paddingTop: "20px" }}>
      {editMode ? (
        <>
          <Tooltip
            title={
              !availablePublishedCourses.includes(currentLanguage)
                ? t(
                    "you need a published version in order to see the current version",
                  )
                : ""
            }
            arrow
          >
            <span>
              <Button
                size="small"
                variant="outlined"
                color="neutral"
                onClick={() => dispatch(toggleEditMode())}
                disabled={!availablePublishedCourses.includes(currentLanguage)}
                startIcon={<EyeIcon />}
              >
                {t("See current version")}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !canDeployChange
                ? t(
                    "a course needs name, description and interactions to be deployed",
                  )
                : ""
            }
            arrow
          >
            <span>
              <Button
                size="small"
                variant="outlined"
                color="neutral"
                startIcon={isLoadingPublish ? <ButtonSpinner /> : <SaveIcon />}
                onClick={onClickPublishCourse}
                disabled={!canDeployChange || isLoadingPublish}
              >
                {t("Deploy changes")}
              </Button>
            </span>
          </Tooltip>
          <PublishLocalizedCourseModal
            show={showPublishModal}
            close={() => setShowPublishModal(false)}
            action={(all) => {
              doPublishCourse(all);
              setShowPublishModal(false);
            }}
            isLoading={isLoadingPublish || isLoadingPublishAll}
          />
        </>
      ) : isMantraUser ||
        (userInfo && course?.owner_id === userInfo.company_id) ? (
        <Button
          size="small"
          variant="outlined"
          color="neutral"
          onClick={onClickEditContent}
          disabled={isLoadingDraft}
          startIcon={isLoadingDraft ? <ButtonSpinner /> : <PencilIcon />}
        >
          {t("Edit content")}
        </Button>
      ) : null}
      {!draftLocalizedCourses[currentLanguage] && <ToolbarImportJsonButton />}
      <div
        style={{
          display: "inline-block",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            size="small"
            style={{
              borderRight: "None",
              borderEndEndRadius: "0",
              borderTopRightRadius: "0",
            }}
            variant="outlined"
            color="neutral"
            startIcon={
              isTestLocalizedCourseLoading ? <ButtonSpinner /> : <EmailSent />
            }
            onClick={() =>
              sendTestLocalizedCourse(localizedCourse.id, userInfo.id)
            }
          >
            <span>{t("Send now to")}</span>
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="neutral"
            style={{
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
            }}
            onClick={() => setOpenRecipientSelectionDialog(true)}
          >
            {selectedUser && userInfo && selectedUser.email === userInfo.email
              ? t("me")
              : truncateLength(selectedUser?.email, 15)}
            <ArrowDropDownIcon />
          </Button>
        </div>
      </div>
      <Dialog
        open={openRecipientSelectionDialog}
        onClose={() => setOpenRecipientSelectionDialog(false)}
      >
        <DialogTitle>{localizedCourse.name}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <Typography>{t("Send now to")}</Typography>
          <AudienceSelector
            audienceSelectionMode={audienceSelectionMode}
            setAudienceSelectionMode={setAudienceSelectionMode}
            setSelectedDepartments={setSelectedDepartments}
            setSelectedUsers={setCourseRecipients}
            fetchUsers={fetchUsers}
            userList={userList}
          />
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={() => setOpenRecipientSelectionDialog(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              disabled={!audienceSelectionMode}
              variant={"contained"}
              onClick={() => {
                setOpenCourseExpeditionConfirmationDialog(true);
                setOpenRecipientSelectionDialog(false);
              }}
            >
              {t("Send")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCourseExpeditionConfirmationDialog}
        onClose={() => setOpenCourseExpeditionConfirmationDialog(false)}
      >
        <DialogContent>
          <DialogContentText>
            {t(
              "You're about to send {{courseName}} {{audience}} users already following a different course will lose their progress to start this one.",
              {
                courseName: localizedCourse.name,
                audience:
                  audienceSelectionMode === "users"
                    ? t("to {{count}} users", {
                        count: courseRecipients.length,
                      }) + ", "
                    : "",
              },
            )}
          </DialogContentText>
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={() => setOpenCourseExpeditionConfirmationDialog(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                sendCourse();
                setOpenCourseExpeditionConfirmationDialog(false);
                setOpenRecipientSelectionDialog(false);
              }}
            >
              {t("Send")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CourseEditorToolbar;
