import { configureStore } from "@reduxjs/toolkit";
import CourseSharingReducer from "@/pages/awareness/courses/corporate-course-sharing/courseSharingSlice";
import CourseReducer from "@/pages/awareness/courses/course-editor/courseSlice";
import CourseCatalogReducer from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import PermissionsReducer from "@/pages/general-settings/permissions-attribution/permissionsSlice";
import AuthReducer from "@/utils/contexts/AuthSlice";
import TemplateReducer from "@/pages/templates/templateSlice";
import SimulationChecklistReducer, {
  simulationSetupChecklistApi,
} from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import AwarenessChecklistReducer from "@/pages/awareness/setup/Checklist/SetupChecklistSlice";
import AwarenessUsersSettingsReducer from "@/pages/awareness/userSlice";
import { courseEditorApi } from "@/api";
import { permissionsApi } from "@/pages/general-settings/permissions-attribution/remoteApis";
import { templateApi } from "@/pages/templates/templateApi";
import { courseSharingApi } from "@/pages/awareness/courses/corporate-course-sharing/courseSharingApi";
import { awarenessUsersSettingsApi } from "@/pages/awareness/userApi";
import {
  bannersSetupChecklistApi,
  bannersSetupChecklistReducer,
} from "@/pages/banners/setup/BannerSetupChecklist";
import { bannersUsersApi } from "@/pages/banners/remote-apis/bannerUsersApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "@/utils/contexts/userApi";
import { threatsApi } from "@/pages/threatslg/api";
import { leaderboardApi } from "./pages/my-progress/leaderboard/LeaderboardApi";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    awarenessChecklist: AwarenessChecklistReducer,
    awarenessUser: AwarenessUsersSettingsReducer,
    bannersChecklist: bannersSetupChecklistReducer,
    courseCatalog: CourseCatalogReducer,
    courseEditor: CourseReducer,
    courseSharing: CourseSharingReducer,
    simulationChecklist: SimulationChecklistReducer,
    template: TemplateReducer,
    usersWithPermissions: PermissionsReducer,
    [awarenessUsersSettingsApi.reducerPath]: awarenessUsersSettingsApi.reducer,
    [bannersSetupChecklistApi.reducerPath]: bannersSetupChecklistApi.reducer,
    [bannersUsersApi.reducerPath]: bannersUsersApi.reducer,
    [courseEditorApi.reducerPath]: courseEditorApi.reducer,
    [courseSharingApi.reducerPath]: courseSharingApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [simulationSetupChecklistApi.reducerPath]:
      simulationSetupChecklistApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [threatsApi.reducerPath]: threatsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [leaderboardApi.reducerPath]: leaderboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      awarenessUsersSettingsApi.middleware,
      bannersSetupChecklistApi.middleware,
      bannersUsersApi.middleware,
      courseEditorApi.middleware,
      courseSharingApi.middleware,
      leaderboardApi.middleware,
      permissionsApi.middleware,
      simulationSetupChecklistApi.middleware,
      templateApi.middleware,
      threatsApi.middleware,
      userApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);

export default store;
