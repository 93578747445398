import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useSimulationResource } from "@/utils/ResourceGet";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ENDPOINT = "company/landing_page_override_status";

export const LandingPageOverride = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [{ enabled: optionEnabled }, readError] = useSimulationResource(
    ENDPOINT,
    false,
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(optionEnabled);
  }, [optionEnabled]);

  async function onChange(): Promise<void> {
    const initialCheckValue = checked;
    const targetCheckValue = !checked;
    try {
      setChecked(targetCheckValue);
      await axios.put(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${ENDPOINT}`,
        { enabled: targetCheckValue },
      );
      snackbar.showMessage(
        t("Landing page override has been {{state}}", {
          state: targetCheckValue ? t("enabled") : t("disabled"),
        }),
      );
    } catch {
      snackbar.showMessage(t("Could not update value."));
      setChecked(initialCheckValue);
    }
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{t("Landing page override")}</Typography>
            <Box sx={{ paddingLeft: ".5em" }}>
              <Switch
                size="small"
                checked={checked}
                onChange={onChange}
                disabled={readError !== null}
              />
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Typography>
          {t(
            "When activated, users that click on a phishing simulation will see an inconspicuous 404 page instead of the corresponding learning module.",
          )}
          <Tooltip
            title={t("This 404 landing page aligns with your SSO provider.")}
            arrow
          >
            <InfoIcon
              sx={{
                ml: 1,
                fontSize: "medium",
                cursor: "pointer",
                verticalAlign: "middle",
                color: "darkgray",
              }}
            />
          </Tooltip>
        </Typography>
        {readError !== null && (
          <Typography style={{ color: "red" }}>
            {t("Could not retrieve data.")}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
