import React, { ReactElement } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { Spinner } from "@/components/Spinner";

type BasicStatCardProps = {
  title: string;
  icon?: ReactElement;
  data: number | string;
  isLoading?: boolean;
  action?: ReactElement;
  isError?: any;
};

export const BasicStatCard = ({
  title,
  icon = <></>,
  data,
  isLoading = false,
  action,
  isError,
}: BasicStatCardProps) => {
  return (
    <Card>
      <CardHeader avatar={icon} action={action} title={title}></CardHeader>
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        <Stack spacing={2}>
          {isError && "an error occurred"}
          {isLoading ? (
            <Box sx={{ marginBottom: "15px" }}>
              <Spinner />
            </Box>
          ) : (
            <Typography variant="h3" sx={{ marginBottom: "15px" }}>
              {data}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
