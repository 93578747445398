import React from "react";
import {
  Container,
  Divider,
  Stack,
  Switch,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { Spinner } from "@/components/Spinner";
import {
  CustomToggleButton,
  PaperHeader,
  StyledPaper,
} from "@/pages/browser-defender/components/StyledElements";

function CustomSwitchWithLabel({
  featureName,
  label,
  checked,
  explanation,
  callback,
  icon = null,
}) {
  function handleChange(event) {
    callback(event.target.name, event.target.checked);
  }

  return (
    <Tooltip title={explanation}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Switch
          checked={checked}
          size="small"
          onChange={handleChange}
          name={featureName}
        />
        <Typography>{label}</Typography>
        {icon}
      </Stack>
    </Tooltip>
  );
}

function CustomToggleButtonGroupWithLabel({
  featureName,
  label,
  explanation,
  selected,
  options,
  callback,
}) {
  const [value, setValue] = React.useState(selected);

  function handleChange(event, value) {
    setValue(value);
    callback(featureName, value);
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Container disableGutters>
        <Tooltip title={explanation} sx={{ textAlign: "left" }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <AutoAwesomeIcon />
            <Typography>{label}</Typography>
          </Stack>
        </Tooltip>
      </Container>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        color="primary"
      >
        {options.map((option) => (
          <CustomToggleButton key={option.key} value={option.key}>
            <Tooltip title={option.explanation}>
              <Stack direction={"row"}>
                <Typography sx={{ marginRight: "0.5rem" }}>
                  {option.label}
                </Typography>
                {option.icon}
              </Stack>
            </Tooltip>
          </CustomToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}

export function AdvancedSettings({ settings, saveAdvancedSettings }) {
  const llmBasedDetectionStates = [
    {
      key: "disabled",
      label: "Disabled",
      explanation:
        "Feature is turned off: AI based detection and prevention will not be used for your users",
      icon: <ReportOffIcon style={{ color: "#F6285F8C" }} />,
    },
    {
      key: "silent_mode",
      label: "Monitoring Mode",
      explanation:
        "The analysis will be performed in the background, without any alerts shown to users. Details will be available in the Admin panel, if the page is considered to be dangerous.",
      icon: <NotificationsOffIcon style={{ color: "#F199498C" }} />,
    },
    {
      key: "alert_mode",
      label: "Alert Mode",
      explanation:
        "The user will be notified an analysis is in progress and they will need to wait for the analysis result before continuing. Details will be available in the Admin panel, if the page is considered to be dangerous.",
      icon: <AnnouncementIcon style={{ color: "#35D9C0E6" }} />,
    },
  ];

  return (
    <GenericCard title={"Advanced settings"}>
      {settings.loading && <Spinner />}
      {!settings.loading && (
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          useFlexGap
        >
          <StyledPaper>
            <PaperHeader>Communication</PaperHeader>

            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <CustomSwitchWithLabel
                featureName="email_alerts_active"
                label="Password related email alerts"
                checked={settings.data.email_alerts_active}
                explanation={
                  "All administrators in your company will receive email alerts when a password related event is detected"
                }
                callback={saveAdvancedSettings}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <CustomSwitchWithLabel
                featureName="send_monthly_digest"
                label="Monthly digest email"
                checked={settings.data.send_monthly_digest}
                explanation={
                  "All administrators in your company will receive a monthly update email with usage statistics"
                }
                callback={saveAdvancedSettings}
              />
            </Stack>
          </StyledPaper>

          <StyledPaper>
            <PaperHeader>Advanced features</PaperHeader>
            <CustomToggleButtonGroupWithLabel
              featureName="llm_based_detection"
              label="AI based detection"
              selected={settings.data.llm_based_detection}
              explanation={"Configure the use of AI in Browser Defender"}
              options={llmBasedDetectionStates}
              callback={saveAdvancedSettings}
            />
            <Divider sx={{ m: "1rem 0" }} />
            <CustomSwitchWithLabel
              featureName="manual_password_setup"
              label="Manual password setup"
              checked={settings.data.manual_password_setup}
              explanation={
                "Please check with Mantra support before activating this feature"
              }
              callback={saveAdvancedSettings}
              icon={<FmdBadIcon style={{ color: "#fd397a" }} />}
            />
          </StyledPaper>
        </Stack>
      )}
    </GenericCard>
  );
}
