import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SelectOrderByComponent({
  index,
  orderChoice,
  handleOrderChange,
  handleOrderLabelChange,
  orderItem,
  handleRemoveItem,
}) {
  const { t } = useTranslation();

  return (
    <ListItem key={index}>
      <Grid container>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ width: "150px" }}
            size={"small"}
            renderInput={(params) => (
              <TextField {...params} label={t("Select a column")} />
            )}
            // getOptionLabel={(option) => option.label}
            value={orderItem.column}
            onChange={(_, value) => handleOrderLabelChange(index, value)}
            options={orderChoice}
            loadingText={t("Loading") + "..."}
          />
        </Grid>
        <Grid item xs={5}>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={orderItem.order}
            onChange={(event) => handleOrderChange(index, event)}
          >
            <FormControlLabel
              value="asc"
              control={<Radio size={"small"} />}
              label={t("Asc.")}
            />
            <FormControlLabel
              value="desc"
              control={<Radio size={"small"} />}
              label={t("Desc.")}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="delete"
            onClick={() => handleRemoveItem(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
}
