import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AutocompleteSelectAll({
  options,
  type,
  selectedOptions,
  setSelectedOptions,
  setSelectAll,
  selectAll,
  error,
  helperText,
}) {
  const { t } = useTranslation();

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setSelectedOptions([...options]);
      else setSelectedOptions([]);
      return !prev;
    });
  };

  const displayElement = (element) => {
    if (type === "user") {
      return element;
    } else if (type === "department") {
      return element.label;
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      fullWidth
      // open
      disableCloseOnSelect
      loadingText={t("Loading") + "..."}
      value={selectedOptions}
      onChange={(_e, value, reason) => {
        if (reason === "clear" || reason === "removeOption")
          setSelectAll(false);
        if (reason === "selectOption" && value.length === options.length)
          setSelectAll(true);
        setSelectedOptions(value);
      }}
      isOptionEqualToValue={(option, value) => {
        if (type === "user") {
          return option === value;
        }
        if (type === "department") {
          return option.id === value.id;
        }
      }}
      filterOptions={(operations, state) => {
        return operations.filter((operation) => {
          if (type === "user") {
            return operation
              .toLowerCase()
              .includes(state.inputValue.toLowerCase());
          }
          if (type === "department") {
            return operation.label
              .toLowerCase()
              .includes(state.inputValue.toLowerCase());
          }
          return 1;
        });
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => (
          <Chip
            variant="outlined"
            color={"primary"}
            label={displayElement(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {displayElement(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          size={"small"}
          {...params}
          error={error}
          helperText={helperText}
        />
      )}
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            <Box
              onMouseDown={(e) => e.preventDefault()} // prevent blur
            >
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault(); // prevent blur
                  handleToggleSelectAll();
                }}
                sx={{ paddingLeft: "16px", marginLeft: 0, mui: 0 }}
                label={t("Select all")}
                control={<Checkbox checked={selectAll} />}
              />
            </Box>
            <Divider />
            {children}
          </Paper>
        );
      }}
    />
  );
}
