import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EmailPreview({ body, sender, displayName, subject }) {
  const { t } = useTranslation();

  return (
    <Card elevation={0} sx={{ border: "thin solid #EAEDF0" }}>
      <CardContent sx={{ backgroundColor: "#E6E7E9" }}>
        <Typography sx={{ fontWeight: "unset" }}>
          <strong>{t("From")}:</strong> {displayName} {"<"}
          {sender}
          {">"}
        </Typography>
        <Typography sx={{ fontWeight: "unset" }}>
          <strong>{t("Subject")}:</strong> {subject}
        </Typography>
      </CardContent>
      <CardContent sx={{ height: "100%" }}>
        <iframe
          sandbox=""
          title="mail Preview"
          srcDoc={body}
          style={{ border: 0 }}
          width="100%"
          height="100%"
        />
      </CardContent>
    </Card>
  );
}
