import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { Users } from "@/pages/general-settings/UsersManagement/Users";
import { GSuiteSync } from "@/pages/general-settings/GSuite/GSuiteSync";
import { OfficeSync } from "@/pages/general-settings/Office/OfficeSync";
import { OktaSync } from "@/pages/general-settings/Okta/OktaSync";
import { useUserContext } from "@/utils/contexts/UserContext";
import PermissionsAttribution from "@/pages/general-settings/permissions-attribution/PermissionsAttribution";
import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  TabObject,
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { capitalize } from "@/utils/strings";
import { useTranslation } from "react-i18next";

export default function GeneralSettings() {
  const { current_company } = useUserContext();
  const provider = current_company.provider;
  const { t } = useTranslation();

  const tabs = useMemo<TabObject[]>(() => {
    const _tabs = [
      {
        label: t("Admins"),
        anchor: "admins",
        component: <PermissionsAttribution />,
      },
      {
        label: t("User management"),
        anchor: "users-management",
        component: <Users />,
      },
    ];

    if (provider === "CSV") {
      const mailHost =
        current_company.mailHost === "GMAIL"
          ? "GOOGLE"
          : current_company.mailHost;
      _tabs.push({
        label: t("{{provider}} Users Provisioning", {
          provider: capitalize(mailHost),
        }),
        anchor: "users-provisioning",
        component: ProvisioningComponents[mailHost],
      });
    } else if (provider in ProvisioningComponents) {
      _tabs.push({
        label: t("{{provider}} Users Provisioning", {
          provider: capitalize(provider),
        }),
        anchor: "users-provisioning",
        component: ProvisioningComponents[provider],
      });
    }

    return _tabs;
  }, [provider, current_company.mailHost, t]);

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <GeneralSettingsPanels />
    </TabsContextProvider>
  );
}

export function GeneralSettingsPanels() {
  const { currentTabComponent } = useTabsContext();
  const { t } = useTranslation();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">{t("General Settings")}</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
}

const ProvisioningComponents = {
  GOOGLE: <GSuiteSync />,
  OFFICE: <OfficeSync />,
  OKTA: <OktaSync />,
};
