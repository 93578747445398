import React from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LabelItem from "@/pages/browser-defender/passwordMisuse/domain-label/LabelItem";

export default function ExtensionActions({
  selectedExtension,
  currentTab,
  onAllow,
  onBlock,
}) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleActionClick = (action) => {
    if (action === "allow") {
      onAllow();
    } else if (action === "block") {
      setConfirmOpen(true);
    } else {
      console.error("Unknown action");
    }
  };

  const isBlocked = currentTab === "blocked";
  const blockExplained =
    "The blocking of extensions works as follows. Within a few minutes after opening their browser, for every impacted user, the extension will be disabled, and they will be requested to uninstall the extension. Users cannot enable the extension as long as it's blocked.";
  const tooltipText = !selectedExtension
    ? "Select an extension first"
    : blockExplained;

  const getDialogText = () => {
    if (!selectedExtension) return "";
    return `Are you sure you want to block ${selectedExtension.name} for all users? ${blockExplained}`;
  };

  return (
    <>
      <Tooltip title={tooltipText}>
        <span>
          <ButtonGroup
            variant="outlined"
            disabled={!selectedExtension}
            sx={{
              "& > button": { mx: 1 },
              "& > button:first-of-type": { ml: 0 },
              "& > button:last-of-type": { mr: 0 },
            }}
          >
            {!isBlocked && (
              <Button size="small" onClick={() => handleActionClick("block")}>
                <LabelItem
                  icon={<BlockIcon style={{ color: "#F19949" }} />}
                  title="Block"
                  description="Block extension for all users."
                  disabled={!selectedExtension}
                />
              </Button>
            )}
            {isBlocked && (
              <Button size="small" onClick={() => handleActionClick("allow")}>
                <LabelItem
                  icon={<CheckCircleIcon style={{ color: "#35D9C0" }} />}
                  title="Allow"
                  description="Allow this extension to be installed and enabled."
                  disabled={!selectedExtension}
                />
              </Button>
            )}
          </ButtonGroup>
        </span>
      </Tooltip>

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm Block</DialogTitle>
        <DialogContent>
          <DialogContentText>{getDialogText()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onBlock();
              setConfirmOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
