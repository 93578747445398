import React from "react";
import { useTheme } from "@mui/material";

export const CircleIcon = ({
  color,
  size,
}: {
  color?: string;
  size: string;
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: color ? color : theme.palette.primary.main,
        width: size,
        height: size,
        border: "2px solid white",
      }}
    ></div>
  );
};
