import {
  APIBannerUser,
  BannerToggleUsers,
} from "@/pages/banners/remote-apis/bannerUsers";
import { createApi } from "@reduxjs/toolkit/query/react";
import { enqueueSnackbar } from "notistack";
import { simulationBaseQuery } from "@/utils/rtkQuery";
import {
  StatsData,
  StatsDataApi,
} from "@/pages/banners/remote-apis/bannerStatistics";

const snackbarError = async (arg, { queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    enqueueSnackbar(error?.error?.data?.error ?? "Unknown Error", {
      variant: "error",
    });
  }
};

export const bannersUsersApi = createApi({
  reducerPath: "banners-users-api",
  baseQuery: simulationBaseQuery(),
  tagTypes: ["UserList", "ActiveUserList", "Stats"],
  endpoints: (builder) => ({
    users: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        method: "GET",
      }),
      providesTags: ["UserList"],
      onQueryStarted: snackbarError,
    }),
    activeUsers: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        params: { status: "active" },
        method: "GET",
      }),
      providesTags: ["ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    engagedUsers: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        params: { status: "engaged" },
        method: "GET",
      }),
      providesTags: ["ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    engageOrDisengage: builder.mutation<void, BannerToggleUsers>({
      query: (body) => ({
        url: "/user/gmail_controller",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    disengageCompany: builder.mutation<void, void>({
      query: () => ({ url: "/company/disengage", method: "POST" }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    batchEngage: builder.mutation<void, BannerToggleUsers[]>({
      query: (body) => ({
        url: "/user/gmail_controller/toggle_users",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    stats: builder.query<StatsData, { startDate: Date; endDate: Date }>({
      query: ({ startDate, endDate }) => ({
        url: "/banners/stats/banners",
        params: {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
        },
      }),
      providesTags: ["Stats"],
      transformResponse: (response: StatsDataApi) => {
        const dailyStatistics = response.stats_per_day.map((stat) => {
          return {
            date: stat.date,
            bannerInfo: stat.with_info,
            bannerSuspicious: stat.with_suspicious,
            bannerVerySuspicious: stat.with_very_suspicious,
            mailsProcessed: stat.processed,
          };
        });

        return {
          dailyStatistics,
          messagesProcessed: response.messages_processed,
          messagesWithBanner: response.messages_with_banner,
          mailboxesMonitored: response.mailboxes_monitored,
          bannerTypes: response.banners_types,
        };
      },
    }),
  }),
});

export const {
  useUsersQuery,
  useActiveUsersQuery,
  useEngagedUsersQuery,
  useEngageOrDisengageMutation,
  useDisengageCompanyMutation,
  useBatchEngageMutation,
  useStatsQuery,
} = bannersUsersApi;
