import React, { useEffect, useState } from "react";
import Attachments from "@/pages/threatslg/components/details_pane/details/Attachments";
import Content from "@/pages/threatslg/components/details_pane/details/content/Content";
import Metadata from "@/pages/threatslg/components/details_pane/details/Metadata";
import WarningBanner from "./WarningBanner";
import { Divider } from "@mui/material";
import { FullPageSpinner } from "@/components/Spinner";
import Box from "@mui/material/Box";
import { Threat } from "@/pages/threatslg/threatslg_types";

type ThreatsDetailsProps = {
  isLoading?: boolean;
  threat?: Threat;
};

const Details = ({ isLoading = false, threat = null }: ThreatsDetailsProps) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, [threat]);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (threat == null) {
    return <NoDataAvailable />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <Metadata threat={threat} />
      <Attachments
        attachmentNames={threat.details.attachments.map((a) => a.name)}
      />
      <Divider />
      <WarningBanner bannerLevel={threat.bannerLevel} />
      <Content threat={threat} expanded={expanded} setExpanded={setExpanded} />
    </Box>
  );
};

const NoDataAvailable = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontSize: "20pt" }}>
        Please select a threat to view its details.
      </Box>
    </Box>
  );
};

export default Details;
