import React from "react";
import { AllKeyCustomers } from "@/pages/phishing-simulation/company/keycustomers/AllKeyCustomers.js";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "@mui/material";

export function KeyCustomers() {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Key Customers")} />
      <CardContent>
        <AllKeyCustomers />
      </CardContent>
    </Card>
  );
}
