import React from "react";
import CompanyGeneralInformations from "@/pages/phishing-simulation/company/CompanyGeneralInformations";
import { GroupsDepartments } from "@/pages/general-settings/commonUserProvisioning/GroupsDepartments/GroupsDepartments";
import { TemplateAttribution } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/TemplateAttribution";
import Tabs, {
  CurrentTabComponent,
  TabObject,
  TabsContextProvider,
} from "@/components/controls/Tabs";
import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CompanyScan = () => {
  const { t } = useTranslation();

  const tabs: Array<TabObject> = [
    {
      label: t("General Informations"),
      anchor: "general-informations",
      component: <CompanyGeneralInformations />,
    },
    {
      label: t("Groups & Departments"),
      anchor: "groups-departments",
      component: <GroupsDepartments />,
    },
    {
      label: t("Template Attribution"),
      anchor: "template-attribution",
      component: <TemplateAttribution />,
    },
  ];

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <StickyHeader height="120px">
        <Typography variant="h2">{t("Company Scan")}</Typography>
        <Tabs />
      </StickyHeader>
      <CurrentTabComponent />
    </TabsContextProvider>
  );
};

export default CompanyScan;
