import React from "react";
import { Stack, Typography } from "@mui/material";
import StickyHeader from "@/components/StickyHeader";
import { TemplateSearchbar } from "./TemplateSearchbar";
import { useTemplateCatalogContext } from "@/pages/templates/TemplateCatalogContextProvider";
import { useTranslation } from "react-i18next";

export const TemplateHeader = () => {
  const catalogContext = useTemplateCatalogContext();
  const { t } = useTranslation();

  return (
    <>
      <StickyHeader height="110px">
        <Stack direction="column" gap={3}>
          <Typography
            variant="h2"
            style={{
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {catalogContext.isInSelectionMode
              ? t("Template Selection")
              : t("Template Catalog")}
          </Typography>
          <TemplateSearchbar />
        </Stack>
      </StickyHeader>
    </>
  );
};
