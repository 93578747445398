import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useCompanyReminderStatus } from "@/pages/awareness/setup/useCompanyReminderStatus";
import { useStopReminders } from "@/pages/awareness/setup/stopReminders";
import { useStartReminders } from "@/pages/awareness/setup/startReminders";
import { useTranslation } from "react-i18next";

export function Reminders() {
  const [autoEngageToggleLoading, setAutoEngageToggleLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();

  const {
    autoEngageEnabled,
    numScheduledReminders,
    isError,
    loading,
    refetch,
  } = useCompanyReminderStatus();
  const { stopReminders } = useStopReminders();
  const { startReminders } = useStartReminders();

  const closeConfirmation = () => {
    setShowConfirmation(false);
    return true;
  };

  const handleToggleChange = (event) => {
    if (event.target.checked) {
      startReminders(setAutoEngageToggleLoading, refetch);
    } else {
      setShowConfirmation(true);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6">
              {t("Automatic course delivery")}
            </Typography>
            <Tooltip
              title={t(
                "Courses are sent on business days between 9:30 - 12:00 and 14:00 - 17:30.",
              )}
              sx={{ marginLeft: "15px" }}
            >
              <InfoIcon />
            </Tooltip>
            <Box sx={{ paddingLeft: ".5em" }}>
              <Switch
                size="small"
                checked={autoEngageEnabled}
                onChange={handleToggleChange}
                disabled={autoEngageToggleLoading || loading || isError}
              />
            </Box>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography>
              {t(
                "When activated, employees will receive courses automatically based on the assignment you made in the",
              )}{" "}
              <Link to={"/awareness/courses"}>{t("course catalogue")}.</Link>
            </Typography>
            <Typography>
              {t("You can choose the number of days between courses below.")}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      <Dialog open={showConfirmation} onClose={closeConfirmation}>
        <DialogTitle>{t("Deactivate automatic course delivery")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "Employees won't receive any courses automatically until you reactivate the automatic course delivery.",
            )}
          </DialogContentText>
          <DialogContentText>
            {t("This will also cancel the")} {numScheduledReminders}{" "}
            {t("courses scheduled to be sent today.")}
          </DialogContentText>
          <DialogContentText>
            {t("Courses that have already been sent will not be cancelled.")}
          </DialogContentText>
          <DialogActions>
            <Button onClick={closeConfirmation}>{t("Cancel")}</Button>
            <LoadingButton
              variant={"contained"}
              onClick={() =>
                stopReminders(
                  setAutoEngageToggleLoading,
                  closeConfirmation,
                  refetch,
                )
              }
              loading={autoEngageToggleLoading || loading}
              disabled={isError}
            >
              {t("Confirm")}
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
