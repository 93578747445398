import React from "react";
import MailboxText from "@/pages/threatslg/common/MailboxText";
import { Threat } from "@/pages/threatslg/threatslg_types";
import Box from "@mui/material/Box";

type MetadataProps = {
  threat: Threat;
};

const Metadata = ({ threat }: MetadataProps) => {
  const receivedDate = threat.receivedDate.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const reportDate = threat.reportDate.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Subject subject={threat.subject} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ marginBottom: "10px" }}>
            <MailboxText label="From" mailbox={threat.sender} />
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <MailboxText label="To" mailbox={threat.receiver} />
          </Box>
          <div>Received date: {receivedDate}</div>
        </Box>
        {threat.reporter && (
          <Box>
            <Box sx={{ marginBottom: "10px" }}>
              <MailboxText label="Reporter" mailbox={threat.reporter} />
            </Box>
            <div>Report date: {reportDate}</div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Subject = ({ subject }: { subject: string }) => {
  return (
    <Box
      sx={{
        fontSize: "18pt",
        fontWeight: "bold",
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      {subject}
    </Box>
  );
};

export default Metadata;
