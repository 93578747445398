import { Box, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useSelector } from "react-redux";
import { selectAwarenessSetupChecklistState } from "../SetupChecklistSlice";
import { capitalize } from "@/utils/strings";
import { useTranslation } from "react-i18next";

const SelectedMessagingPlatform: React.FC = () => {
  const { messagingPlatform } = useSelector(selectAwarenessSetupChecklistState);
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        {t("Check that the messaging platform is selected properly")}
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        {messagingPlatform == null ? (
          <>
            <Box maxWidth="50%">
              {t(
                "No messaging platform is currently selected. Please contact us in order to select a messaging platform for your company",
              )}
              <CancelOutlinedIcon color="error" />
            </Box>
          </>
        ) : (
          <>
            {t("Your messaging platform is")} {capitalize(messagingPlatform)}
            <CheckCircleOutlinedIcon color="success" />
          </>
        )}
      </Box>
    </Box>
  );
};

export default SelectedMessagingPlatform;
