import { BackendSuspense } from "./BackendSuspense";
import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { Box } from "@mui/system";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

export const NbUserCard = ({ usersCount, error, isPending }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Total number of users")}>
      <Card>
        <CardHeader
          title={t("User count")}
          avatar={
            <PeopleOutlinedIcon sx={{ fontSize: "32px", color: "#7567FE" }} />
          }
        />
        <CardContent>
          <Box display="flex" justifyContent="center">
            <BackendSuspense
              hasData={usersCount > 0}
              error={error}
              isPending={isPending}
            >
              <Typography variant="h3">{usersCount}</Typography>
            </BackendSuspense>
          </Box>
        </CardContent>
      </Card>
    </Tooltip>
  );
};
