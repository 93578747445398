import { Tab, Tabs } from "@mui/material";
import React from "react";
import { DetailsTab } from "@/pages/threatslg/threatslg_types";

const DetailsTabs = ({
  onChange,
  activeTab,
}: {
  onChange: (tab: DetailsTab) => void;
  activeTab: DetailsTab;
}) => {
  return (
    <Tabs
      indicatorColor="primary"
      textColor="inherit"
      className="builder-tabs"
      value={activeTab}
      onChange={(_, nextTab) => onChange(nextTab)}
      sx={{ minHeight: "45px", height: "45px" }}
    >
      <Tab disableRipple label="Body" value="body" />
      <Tab disableRipple label="Headers" value="headers" />
    </Tabs>
  );
};

export default DetailsTabs;
