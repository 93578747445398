import React, { PropsWithChildren } from "react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { Spinner } from "@/components/Spinner";

type StatsCardProps = {
  title: string;
  isLoading?: boolean;
};

export const StatsCard = ({
  title,
  isLoading = false,
  children,
}: PropsWithChildren<StatsCardProps>) => {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        {isLoading ? (
          <Box sx={{ marginBottom: "15px" }}>
            <Spinner />
          </Box>
        ) : (
          children
        )}
      </CardContent>
    </Card>
  );
};
