import React from "react";
import {
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

interface SearchFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  label?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  InputProps?: object;
  size?: "small" | "medium";
}

export const SearchField: React.FC<SearchFieldProps> = ({
  value,
  onChange,
  onClear,
  label = null,
  placeholder,
  sx = {},
  InputProps = {},
  size = "small",
  ...props
}) => {
  const { t } = useTranslation();

  if (!label) {
    label = t("Search");
  }

  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      const emptyValue = { target: { value: "" } };
      onChange(emptyValue as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      size={size}
      sx={{
        width: "250px",
        "& .MuiInputBase-root": {
          paddingRight: 1,
        },
        ...sx,
      }}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={handleClear}
              edge="end"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
