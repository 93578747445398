import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function EmailForm({ register, errors, control }) {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography>{t("From Email")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={"sender"}
          {...register("sender")}
          error={!!errors.sender}
          helperText={errors.sender?.message}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>{t("Display Name")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={"display_name"}
          {...register("displayName")}
          error={!!errors.displayName}
          helperText={errors.displayName?.message}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>{t("Subject")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={"subject"}
          {...register("subject")}
          error={!!errors.subject}
          helperText={errors.subject?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("Body")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="body"
          control={control}
          render={({ field }) => (
            <AceEditor
              value={field.value}
              onChange={field.onChange}
              mode="html"
              theme="xcode"
              fontSize={14}
              minLines={25}
              width={"100%"}
              style={{
                border: "thin solid #EAEDF0",
                borderRadius: "2px",
                outline: !!errors.body ? "1px solid #F6285FCC" : "",
              }}
              maxLines={25}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                useWorker: false,
              }}
            />
          )}
        />
        {!!errors.body ? (
          <Box
            sx={{
              color: "#F6285FCC",
              fontSize: "0.75rem",
              fontWeight: 400,
              lineHeight: 1.66,
              letterSpacing: "0.03333em",
              textAlign: "left",
              margin: "4px 14px 0 14px",
            }}
          >
            {errors.body?.message}
          </Box>
        ) : null}
      </Grid>
      <Typography sx={{ fontWeight: "unset" }}>
        {t("To allow Mantra to detect clicks, please add the")}{" "}
        <strong>{"{{click_url}}"}</strong>{" "}
        {t(
          "tag in place of the redirection URL in the click link, as in the example below:",
        )}
      </Typography>
      <SyntaxHighlighter
        language="cshtml"
        style={coy}
        customStyle={{
          background: undefined,
        }}
      >
        {'<a href="{{click_url}}">Click here</a>'}
      </SyntaxHighlighter>
    </Grid>
  );
}
