import React from "react";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useUserContext } from "@/utils/contexts/UserContext";
import i18n from "i18next";

import "@/components/utils/dateSetup";

const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useUserContext();
  let locale = i18n.options.fallbackLng[0] ?? "en";

  if (i18n.languages.includes(user.language)) {
    locale = user.language;
  }

  dayjs.locale(locale);

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      {children}
    </MuiLocalizationProvider>
  );
};

export default LocalizationProvider;
