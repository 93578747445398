import React from "react";
import { Tabs, Tab, TabProps, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLanguage,
  selectAvailablePublishedLanguages,
  selectCurrentLanguage,
  selectIsProvidedByMantra,
  selectAvailableDraftsLanguages,
  selectAllAvailableLanguages,
} from "./courseSlice";
import { languageDisplayName } from "@/utils/misc";
import styled from "@emotion/styled";
import clsx from "clsx";
import NewLanguageSelector from "./NewLanguageSelector";
import {
  selectIsMantraUser,
  selectUsesFormalTone,
} from "@/utils/contexts/AuthSlice";
import { useTranslation } from "react-i18next";

const StyledTab = styled((props: TabProps) => <Tab {...props} />)(() => ({
  fontWeight: 550,
  "&.Mui-disabled": {
    fontWeight: 200,
  },
  "&.AvailableDraftLanguage::before": {
    content: '""',
    position: "absolute",
    top: 5,
    right: 5,
    display: "block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: "#ffa5a5",
  },
  "&.OnlyDraftLanguage": {
    color: "rgb(180, 180, 180)",
  },
}));

const useVisibleLanguageTabs = () => {
  const allAvailableLanguages = useSelector(selectAllAvailableLanguages);
  const availablePublishedLanguages = useSelector(
    selectAvailablePublishedLanguages,
  );
  const availableDraftLanguages = useSelector(selectAvailableDraftsLanguages);
  const useFormalTone = useSelector(selectUsesFormalTone);
  const isMantraUser = useSelector(selectIsMantraUser);

  let language_filter: undefined | ((language: string) => boolean) = undefined;
  if (isMantraUser) {
    language_filter = (language: string) => true;
  } else if (useFormalTone) {
    language_filter = (language: string) => language !== "fr";
  } else {
    language_filter = (language: string) => language !== "fr_FR@formal";
  }

  return {
    allAvailableLanguages: allAvailableLanguages.filter(language_filter),
    availableDraftLanguages: availableDraftLanguages.filter(language_filter),
    availablePublishedLanguages:
      availablePublishedLanguages.filter(language_filter),
  };
};

const LanguageTabs = () => {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const {
    allAvailableLanguages,
    availablePublishedLanguages,
    availableDraftLanguages,
  } = useVisibleLanguageTabs();
  const isProvidedByMantra = useSelector(selectIsProvidedByMantra);
  const isMantraUser = useSelector(selectIsMantraUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const displayRedDot = (language: string) =>
    (!isProvidedByMantra || isMantraUser) &&
    availableDraftLanguages.includes(language);
  const displayOnlyDraftGrey = (language: string) =>
    (!isProvidedByMantra || isMantraUser) &&
    !availablePublishedLanguages.includes(language);

  return (
    <Box
      sx={{
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        maxWidth: "75%",
      }}
    >
      <Tabs
        value={currentLanguage}
        onChange={(e, v) => dispatch(changeLanguage(v))}
      >
        {allAvailableLanguages.map((language: string, index: number) => (
          <StyledTab
            disableRipple
            key={language + index}
            value={language}
            label={languageDisplayName(language, isMantraUser)}
            className={clsx(
              displayRedDot(language) && "AvailableDraftLanguage",
              displayOnlyDraftGrey(language) && "OnlyDraftLanguage",
            )}
          />
        ))}
      </Tabs>
      {(!isProvidedByMantra || isMantraUser) && <NewLanguageSelector />}
      {displayRedDot(currentLanguage) ? (
        <Box
          sx={{
            marginLeft: "1.5rem",
            color: "#ff7070",
            fontWeight: "500",
            fontSize: "0.9rem",
            fontStyle: "italic",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "60%",
          }}
        >
          {t("This language has unsaved changes.")}
        </Box>
      ) : (
        ""
      )}
      {isProvidedByMantra && !isMantraUser ? (
        <Box
          title={t(
            "This is a Mantra course. Clone it if you want to edit content.",
          )}
          sx={{
            marginLeft: "1.5rem",
            fontWeight: "500",
            fontSize: "0.9rem",
            fontStyle: "italic",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "rgb(169, 169, 169)",
            maxWidth: "60%",
          }}
        >
          {t("This is a Mantra course. Clone it if you want to edit content.")}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default LanguageTabs;
