import React, { useEffect } from "react";
import { useUserContext } from "@/utils/contexts/UserContext";

import {
  alpha,
  // Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDepartments,
  updateSubscription,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { SubscriptionsPayload } from "@/pages/awareness/courses/utils/courseTypes";
import theme from "@/theme";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ModalCourseAssignation = ({
  openModal,
  setOpenModal,
  selectedCourse,
  learningUserInfos,
}) => {
  const { t } = useTranslation();
  const [assignmentType, setAssignmentType] = React.useState(
    selectedCourse.subscription_type,
  );
  const userContext = useUserContext();
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [selectedDepartments, setSelectedDepartments] = React.useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const departments = useSelector(selectDepartments);
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const handleClose = () => {
    setAssignmentType("");
    setOpenModal(false);
  };

  const handleAssignmentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAssignmentType((event.target as HTMLInputElement).value);
  };
  useEffect(() => {
    if (assignmentType === "departments") {
      setOpenCollapse(true);
    } else {
      setOpenCollapse(false);
    }
  }, [assignmentType]);

  const onSave = async (event) => {
    event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    const data: SubscriptionsPayload = {
      course_id: selectedCourse.id,
      type: assignmentType,
      departments_id: selectedDepartments.map((department) => department.id),
      company_id: userContext.current_company.id,
    };

    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/courses/subscriptions",
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      dispatch(updateSubscription(data));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }

    setOpenModal(false);
  };

  useEffect(() => {
    setAssignmentType(selectedCourse.subscription_type);
    setSelectedDepartments(
      departments.filter((department) =>
        selectedCourse.subscriptions.some(
          (subscription) => subscription.department === department.id,
        ),
      ),
    );
  }, [departments, selectedCourse]);

  const handleDepartmentsChange = (event, value) => {
    setSelectedDepartments(value);
  };

  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle variant={"h3"} sx={{ fontSize: "24px" }}>
        {t("Edit course audience")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant={"h2"} sx={{ fontSize: "18px" }}>
          {selectedCourse.label_name}
        </Typography>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={assignmentType}
            onChange={handleAssignmentTypeChange}
          >
            <Box
              sx={{
                paddingLeft: "2rem",
                paddingTop: "2rem",
              }}
            >
              <FormControlLabel
                value="not_subscribed"
                control={<Radio />}
                label={t("Don't send")}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                paddingLeft: "2rem",
                paddingTop: "0rem",
              }}
            >
              <FormControlLabel
                value="company"
                control={<Radio />}
                label={t("Assign to all employees")}
              />
            </Box>
            <Box
              sx={{
                paddingLeft: "2rem",
                paddingTop: "0rem",
              }}
            >
              <FormControlLabel
                value="departments"
                control={<Radio />}
                label={t("Assign to selected departments")}
              />
              <Collapse
                in={openCollapse}
                timeout="auto"
                sx={{ paddingBottom: "0rem" }}
                unmountOnExit
                key={"users_subset_collapsed"}
              >
                <Autocomplete
                  sx={{ width: "100%", height: "70px", paddingTop: "0rem" }}
                  multiple
                  options={departments}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  loadingText={t("Loading") + "..."}
                  value={selectedDepartments}
                  onChange={handleDepartmentsChange}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "80%" }}
                      {...params}
                      label={t("Departments")}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index: number) => (
                      <Chip
                        variant={"outlined"}
                        color={"primary"}
                        sx={{
                          marginRight: "5px",
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1,
                          ),
                          width: "auto",
                        }}
                        label={t(option.name)}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </Collapse>
            </Box>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose}>
          {t("Cancel")}
        </Button>
        <Button variant={"contained"} onClick={onSave}>
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
