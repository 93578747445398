import React, { useState } from "react";
import MuiIconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// need to use union type from MUI here.
type IconButtonColors =
  | "inherit"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
type IconButtonProps = {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  children?: React.ReactNode;
};
const IconButton = ({
  children,
  onClick,
  title,
  disabled = false,
}: IconButtonProps) => {
  const [iconColor, setIconColor] = useState<IconButtonColors>("default");

  return (
    <Tooltip title={title && title.charAt(0).toUpperCase() + title.slice(1)}>
      <MuiIconButton
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={() =>
          disabled ? setIconColor("default") : setIconColor("primary")
        }
        onMouseLeave={() => setIconColor("default")}
        color={iconColor}
        sx={{
          alignSelf: "center",
          margin: "0",
        }}
      >
        {children}
      </MuiIconButton>
    </Tooltip>
  );
};

export default IconButton;
