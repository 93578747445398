import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { PaginatedTable } from "@/pages/threatslg/components/listpane/list/PaginatedTable";
import DetailPaneHeader from "@/pages/threatslg/components/details_pane/DetailPaneHeader";
import Details from "@/pages/threatslg/components/details_pane/details/Details";
import ThreatTabs from "@/pages/threatslg/components/listpane/header/tabs/ThreatTabs";
import Toolbar from "@/pages/threatslg/components/listpane/header/toolbar/Toolbar";
import { FilterTools } from "@/pages/threatslg/components/listpane/header/toolbar/FilterTools";
import { SortTools } from "@/pages/threatslg/components/listpane/header/toolbar/SortTools";
import { SearchField } from "@/components/SearchField";
import {
  FeedbackType,
  ListOptions,
  Threat,
} from "@/pages/threatslg/threatslg_types";
import {
  useDeleteThreatsMutation,
  useFeedbackThreatsMutation,
  useLazyThreatDetailsQuery,
  useThreatListQuery,
  useUnarchiveThreatsMutation,
} from "@/pages/threatslg/api";
import TableBody from "@mui/material/TableBody";
import ThreatRow from "@/pages/threatslg/components/listpane/list/ThreatRow";
import { useSelectionList } from "@/components/SelectionCheckbox";
import { usePaginationData } from "@/utils/pagination";
import { styled } from "@mui/system";

const ListPane = styled(Box)({
  flex: 1,
  width: "50%",
  paddingRight: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const DetailsPane = styled(Box)({
  flex: 1,
  width: "50%",
  paddingLeft: "10px",
  color: "black",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

const ThreatsLG = () => {
  const selectionList = useSelectionList();
  const pagination = usePaginationData();

  const [displayThreat, setDisplayThreat] = useState<Threat | null>(null);
  const [onArchivedTab, setOnArchivedTab] = useState(false);

  const [listOptions, setListOptions] = useState<ListOptions>({
    archived: false,
    searchTerm: undefined,
    sort: [],
    source: null,
    bannerLevel: null,
    hasAttachments: null,
  });

  const [triggerFetchDetails, fetchDetailsResult] = useLazyThreatDetailsQuery();
  const [triggerDeleteSelectedThreats] = useDeleteThreatsMutation();
  const [triggerUnarchiveSelectedThreats] = useUnarchiveThreatsMutation();
  const [triggerSendFeedback] = useFeedbackThreatsMutation();

  const {
    data: threatsData,
    isFetching,
    isSuccess,
  } = useThreatListQuery({
    startIndex: pagination.startIndex,
    pageSize: pagination.rowsPerPage,
    listOptions: listOptions,
  });

  const threats = useMemo(
    () => (isSuccess ? threatsData.items : []),
    [threatsData, isSuccess],
  );
  const totalCount = useMemo(
    () => (isSuccess ? threatsData.totalCount : 0),
    [threatsData, isSuccess],
  );

  const getThreatById = (threats: Threat[], id: number) => {
    return threats.find((t) => t.id === id);
  };

  useEffect(() => {
    if (fetchDetailsResult.isSuccess && fetchDetailsResult.data) {
      const threat = getThreatById(threats, fetchDetailsResult.data.id);
      if (threat) {
        setDisplayThreat({ ...threat, details: fetchDetailsResult.data });
      } else {
        setDisplayThreat(null);
      }
    }
  }, [fetchDetailsResult, threats]);

  useEffect(() => {
    setDisplayThreat(null);
  }, [threats]);

  const selectedThreats = useMemo(
    () => threats.filter((t) => selectionList.isSelected(t.id)),
    [threats, selectionList],
  );

  const deleteSelectedThreats = (): void => {
    setDisplayThreat(null);
    triggerDeleteSelectedThreats(selectionList.selectedIds);
  };

  const unarchiveSelectedThreats = (): void => {
    setDisplayThreat(null);
    triggerUnarchiveSelectedThreats(selectionList.selectedIds);
  };

  const sendFeedbackForSelectedThreats = (
    feedbackType: FeedbackType,
    feedbackComment: string,
  ) => {
    setDisplayThreat(null);
    triggerSendFeedback({
      threatIds: selectionList.selectedIds,
      feedbackType,
      feedbackComment,
    });
  };

  const search = (term) => {
    selectionList.resetSelection();
    setListOptions((prev) => ({ ...prev, searchTerm: term }));
  };

  const onFilterChange = (level, attachments) => {
    selectionList.resetSelection();
    setListOptions((prev) => ({
      ...prev,
      bannerLevel: level,
      hasAttachments: attachments,
    }));
  };

  const onSortChange = (sort) => {
    selectionList.resetSelection();
    setListOptions((prev) => ({ ...prev, sort: sort }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 0,
          height: "100%",
        }}
      >
        <ListPane>
          <ThreatTabs
            onChange={({ archived, source }) => {
              pagination.changePage(0);
              selectionList.resetSelection();
              setListOptions((prev) => ({
                ...prev,
                archived: archived,
                source: source,
              }));
              setOnArchivedTab(archived);
            }}
          />
          <Toolbar>
            <SearchField
              value={listOptions.searchTerm}
              onChange={(e) => search(e.target.value)}
            />
            <FilterTools value={listOptions} onChange={onFilterChange} />
            <SortTools value={listOptions.sort} onChange={onSortChange} />
          </Toolbar>
          <PaginatedTable
            data={threats}
            totalCount={totalCount}
            isLoading={isFetching}
            pagination={pagination}
          >
            <TableBody>
              {threats.map((threat) => (
                <ThreatRow
                  key={threat.id}
                  threat={threat}
                  displayThreat={triggerFetchDetails}
                  isDisplayed={displayThreat?.id === threat.id}
                  selectionList={selectionList}
                />
              ))}
            </TableBody>
          </PaginatedTable>
        </ListPane>
        <DetailsPane>
          <DetailPaneHeader
            areThreatsArchived={onArchivedTab}
            enabled={selectionList.selectedIds.length > 0}
            selectedThreats={selectedThreats}
            onDelete={deleteSelectedThreats}
            onUnarchive={unarchiveSelectedThreats}
            onSendFeedback={sendFeedbackForSelectedThreats}
          />
          <Details
            isLoading={fetchDetailsResult.isFetching}
            threat={displayThreat}
          />
        </DetailsPane>
      </Box>
    </>
  );
};

export default ThreatsLG;
