import React from "react";
import { useParams } from "react-router-dom";
import { YourStats } from "@/pages/my-progress/stats/YourStats";
import { UserActivity } from "@/pages/my-progress/activity/UserActivity";
import { useBackend } from "@/utils/misc";
import { useTranslation } from "react-i18next";

export function User() {
  const { t } = useTranslation();

  let { userId } = useParams();
  let endpointUrl =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + `/user/${userId}/activity`;
  const { data, error, isPending } = useBackend(endpointUrl);

  let body;
  if (isPending || !data)
    body = (
      <div>
        <br />
        <div className="row row-full-height">
          <div className="col-md-12">{t("Loading")}...</div>
        </div>
      </div>
    );
  if (error) body = <div>{t("An error occurred")}</div>;
  if (data) {
    body = (
      <div>
        <br />
        <div className="row row-full-height">
          <div className="col-md-3">
            <YourStats userId={userId} />
          </div>
        </div>
        <div className="row row-full-height">
          <div className="col-md-12">
            <UserActivity data={data} admin />
          </div>
        </div>
      </div>
    );
  }

  return body;
}
