import React from "react";
import { useDownloadUsers } from "@/pages/general-settings/UsersManagement/utils.js";
import { Button } from "@mui/material";

export function ExportCSVLink() {
  const { startDownload } = useDownloadUsers();

  return (
    <Button onClick={() => startDownload()}>
      download your current users in CSV here
    </Button>
  );
}
