import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { MessagingPlatform } from "../SetupChecklistTypes";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAwarenessSetupChecklistState } from "../SetupChecklistSlice";
import { useTranslation } from "react-i18next";

const MessagingPlatformInstalled: React.FC = () => {
  const { messagingPlatform, messagingPlatformInstalled } = useSelector(
    selectAwarenessSetupChecklistState,
  );

  const buttonDisabled =
    messagingPlatform === null || messagingPlatformInstalled === true;
  const alexDeployementLink =
    messagingPlatform === MessagingPlatform.WEBCHAT
      ? "/general-settings#users-provisioning"
      : "/awareness/setup#chatbot-configuration";
  const { t } = useTranslation();

  let alexDeployementText = "";

  switch (messagingPlatform) {
    case null:
      alexDeployementText = t("You must select a messaging platform first.");
      break;
    case MessagingPlatform.SLACK:
      alexDeployementText = t(
        t("You must be a Slack workspace owner to perform this action."),
      );
      break;
    case MessagingPlatform.TEAMS:
      alexDeployementText = t(
        "You must have enough permissions in the Microsoft Teams admin center to deploy Alex.",
      );
      break;
    case MessagingPlatform.WEBCHAT:
      alexDeployementText = t(
        "You must have API permission granted for the Webchat to work.",
      );
      break;
  }

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">Deploy Alex</Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Box>
          <Box mb="1rem">{alexDeployementText}</Box>
          <Box display="flex" gap="1.5rem" alignItems="center">
            {
              <Link
                to={buttonDisabled ? "" : alexDeployementLink}
                style={
                  buttonDisabled
                    ? {
                        pointerEvents: "none",
                        cursor: "auto",
                      }
                    : {}
                }
              >
                <Button disabled={buttonDisabled} variant="contained">
                  {messagingPlatform === MessagingPlatform.WEBCHAT
                    ? t("Grant API Permissions")
                    : t("Deploy Alex")}
                </Button>
              </Link>
            }
            <Box display="flex" gap="0.5rem" alignItems="center">
              {messagingPlatformInstalled ? (
                <>
                  <CheckCircleOutlinedIcon color="success" />
                  <Typography variant="body_extrasmall" color="#2e7d32">
                    {t("Completed")}
                  </Typography>
                </>
              ) : (
                <>
                  <CancelOutlinedIcon color="error" />
                  <Typography variant="body_extrasmall" color="error">
                    {t("Not Completed")}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessagingPlatformInstalled;
