import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import { useState } from "react";
import { validateDomainName, validateEmail } from "@/utils/misc";
import { useSaveNewSafeSenders } from "@/pages/banners/remote-apis/bannersCompanies";
import { ContactType } from "@/pages/banners/preferences/state";

function ErrorMessage({ display, content }) {
  return (
    <i
      style={{
        color: "red",
        visibility: display === false ? "hidden" : "visible", // Change visibility based on the value of display
      }}
    >
      {" "}
      {content}
    </i>
  );
}

export default function ModalAddSafeList({
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  contactType,
  fetchSafeContacts,
  setOpenSuccessSnackBar,
}) {
  const [contentSafeSenders, setContentSafeSenders] = useState("");
  const [displayMessageError, setDisplayMessageError] =
    useState<boolean>(false);

  const { saveNewSafeSenders } = useSaveNewSafeSenders();

  const handleClose = () => {
    setConfirmationDialogOpen(false);
  };

  const onSaveEmailAddresses = async () => {
    const emailAddresses = contentSafeSenders
      .split("\n")
      .map((e) => e.trim())
      .filter((e) => e !== "");

    const cleanList = emailAddresses.filter(validateEmail);
    if (emailAddresses.length > cleanList.length) {
      setDisplayMessageError(true);
    } else {
      await saveNewSafeSenders({
        items: emailAddresses,
        type: ContactType.email_address,
      });
      fetchSafeContacts();
      setOpenSuccessSnackBar(true);
      handleClose();
    }
  };
  const onSaveDomains = async () => {
    const domainsList = contentSafeSenders
      .split("\n")
      .map((e) => e.trim())
      .map((e) => e.toLowerCase())
      .filter((e) => e !== "");

    const cleanList = domainsList.filter(validateDomainName);
    if (domainsList.length > cleanList.length) {
      setDisplayMessageError(true);
    } else {
      await saveNewSafeSenders({ items: cleanList, type: ContactType.domain });
      fetchSafeContacts();
      setOpenSuccessSnackBar(true);
      handleClose();
    }
  };

  const config = {
    [ContactType.email_address]: {
      dialogTypeTitle: "email addresses",
      exampleContent:
        "bob@mozilla.org\njohn@safe-company.com\nhello@mail.example.com",
      action: onSaveEmailAddresses,
    },
    [ContactType.domain]: {
      dialogTypeTitle: "domains",
      exampleContent: "mozilla.org\neff.org",
      action: onSaveDomains,
    },
  };

  const onSave: () => void = config[contactType].action;
  const dialogTypeTitle: string = config[contactType].dialogTypeTitle;
  const exampleContent: string = config[contactType].exampleContent;

  return (
    <Dialog
      open={confirmationDialogOpen}
      onClose={handleClose}
      PaperProps={{ sx: { width: "40%", maxHeight: 300 } }}
    >
      <DialogTitle>Add {dialogTypeTitle} to safe list</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <i style={{ color: "grey", paddingBottom: "10px" }}>
            Put each {dialogTypeTitle} on a line.
          </i>
          <textarea
            style={{ width: "100%", height: "150px" }}
            onChange={(e) => setContentSafeSenders(e.target.value)}
            placeholder={exampleContent}
          ></textarea>

          <ErrorMessage
            display={displayMessageError}
            content={`Items must be ${dialogTypeTitle}`}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="warning"
          autoFocus
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => onSave()}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
