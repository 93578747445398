import React, { useCallback, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useSimulationResource } from "@/utils/ResourceGet";
import { extractErrorMessage } from "@/utils/misc";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const StyledFormControl = styled(FormControl)(() => ({
  "&.MuiFormControl-root": {
    margin: "8px",
    minWidth: 320,
  },
}));

export function PeopleSelector({ users, path, label, mandatory }) {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = React.useState<any>({});
  const [initialUser, initialUserError] = useSimulationResource(
    "company/key_roles/" + path,
    null,
  );

  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  const [title, setTitle] = React.useState("");

  // titleToIgnore is used with title to not save the title upon first load.
  const [titleToIgnore, setTitleToIgnore] = React.useState("");

  const handleChange = async (event, user) => {
    setSelectedUser(user);
    const accessToken = await getAccessTokenSilently();
    try {
      await axios
        .put(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/company/key_roles/" +
            path,
          {
            user: { id: user?.id || null },
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then((response) => setTitle(response.data.user?.title || ""));
      snackbar.showMessage(t("Role successfully saved"));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  const handleTitleChange = async (event) => setTitle(event.target.value);

  const handleTitleUpdate = useCallback(
    async (event) => {
      const accessToken = await getAccessTokenSilently();
      if (selectedUser?.id) {
        try {
          await axios.put(
            import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
              "/company/key_roles_title",
            {
              user: {
                id: selectedUser.id,
                title: title,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          snackbar.showMessage(t("Title updated successfully"));
        } catch (error) {
          snackbar.showMessage(extractErrorMessage(error));
        }
      } else {
        snackbar.showMessage(t("There is nothing to update"));
      }
    },
    [getAccessTokenSilently, selectedUser, snackbar, title, t],
  );

  useEffect(() => {
    if (users?.length && initialUser?.user) {
      setSelectedUser(initialUser.user);
      setTitleToIgnore(initialUser.user.title);
      setTitle(initialUser.user.title);
    } else {
      setTitle("");
    }
  }, [getAccessTokenSilently, initialUser, users]);

  useEffect(() => {
    if (title !== titleToIgnore) {
      const timeoutId = setTimeout(handleTitleUpdate, 1000);
      setTitleToIgnore(null); // at this point title will never be equal to titleToIgnore.
      return () => clearTimeout(timeoutId);
    }
  }, [title, handleTitleUpdate, titleToIgnore]);

  return (
    <>
      <div className="row align-items-center">
        <div>
          <StyledFormControl>
            <Autocomplete
              options={users}
              value={selectedUser}
              getOptionLabel={(user) =>
                user?.firstname ? `${user.firstname} ${user.lastname}` : ""
              }
              isOptionEqualToValue={(option) =>
                users.length && users.findIndex((u) => u.id === option.id) >= 0
              }
              onChange={handleChange}
              loadingText={t("Loading") + "..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={label}
                  error={mandatory && !(selectedUser?.id != null)}
                  helperText={
                    mandatory && !(selectedUser?.id != null)
                      ? t("This allows for optimal simulation targeting")
                      : ""
                  }
                />
              )}
            />
          </StyledFormControl>
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={handleTitleChange}
            placeholder={t("Enter key person title")}
          />
        </div>
      </div>
      {initialUserError !== null && (
        <div className="row">
          <Typography style={{ color: "red" }}>
            {initialUserError.message}
          </Typography>
        </div>
      )}
    </>
  );
}
