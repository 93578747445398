import React, { JSX, useContext, useEffect, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import axios from "axios";
import { SimulationAccess, UserContextType } from "@/types/user";
import { jwtDecode } from "jwt-decode";
import { setEmail, setFormalTone, setToken } from "./AuthSlice";
import store from "@/store";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import i18n from "@/i18n";
import SplashScreen from "@/components/SplashScreen";
import { RedirectToWebsite } from "@/router/RedirectToWebsite";

export const defaultUserContext: UserContextType = {
  email: "",
  firstname: "",
  lastname: "",
  language: "en",
  companies: [],
  is_impersonate: false,
  is_admin: false,
  is_corporate_admin: false,
  permissions: [],
  base_company: {
    id: null,
    name: "",
    mailHost: "OFFICE",
    provider: "",
    domain: "",
    awareness_activated: false,
    banners_activated: false,
    banners_permission_granted: false,
    simulation_access: SimulationAccess.NO_ACCESS,
    banners_controller: null,
  },
  current_company: {
    id: null,
    name: "",
    mailHost: "OFFICE",
    provider: "",
    domain: "",
    awareness_activated: false,
    banners_activated: false,
    banners_permission_granted: false,
    simulation_access: SimulationAccess.NO_ACCESS,
    banners_controller: null,
  },
  is_authenticated: false,
  uses_formal_tone: false,
  is_anonymous: false,
};

export const UserContext =
  React.createContext<UserContextType>(defaultUserContext);

async function retrieveCurrentUserInfo(
  accessToken: string,
  user: User,
): Promise<UserContextType> {
  try {
    const response = await axios.get(
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/current_user/info",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return { ...defaultUserContext, ...response.data };
  } catch (e) {
    // if we can't reach simulation, fallback to the auth0 user
    return { ...defaultUserContext, is_anonymous: true, email: user.email };
  }
}

export function UserContextProvider({ children }: { children: JSX.Element }) {
  const { getAccessTokenSilently, user, isLoading, isAuthenticated } =
    useAuth0();
  const [accessTokenLoading, setAccessTokenLoading] = useState(false);
  const [simulationUser, setSimulationUser] =
    useState<UserContextType>(defaultUserContext);

  useEffect(() => {
    (async () => {
      if (isLoading || !isAuthenticated) {
        return;
      }
      setAccessTokenLoading(true);
      const accessToken = await getAccessTokenSilently();
      const decoded = jwtDecode<{ permissions: Array<string> }>(accessToken);

      // put token in the auth redux store
      store.dispatch(setToken(accessToken));

      const isAdmin = decoded.permissions.includes("basic-admin-permissions");
      const isCorporateAdmin = decoded.permissions.includes("corporate-admin");
      const permissions = decoded.permissions.map(Permission.fromCode);

      let current_user_info = await retrieveCurrentUserInfo(accessToken, user);

      setSimulationUser({
        ...current_user_info,
        is_admin: isAdmin,
        is_corporate_admin: isCorporateAdmin,
        is_authenticated: true,
        permissions: permissions,
      });
      store.dispatch(setEmail(current_user_info.email));
      store.dispatch(setFormalTone(current_user_info.uses_formal_tone));
      setAccessTokenLoading(false);
      setupAxiosDefaultAuthorization(accessToken);
      i18n.changeLanguage(current_user_info.language);
    })();
  }, [
    getAccessTokenSilently,
    setSimulationUser,
    user,
    isLoading,
    isAuthenticated,
  ]);

  if (isLoading || accessTokenLoading) {
    return <SplashScreen />;
  }

  if (simulationUser.is_anonymous) {
    return <RedirectToWebsite />;
  }

  return (
    <UserContext.Provider value={simulationUser}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      "useUserContext should be used only into a UserContextProvider",
    );
  }
  return context;
}

/*
 * Setup authorization header for axios automatically.
 * Will not work in the futur if we decide to refresh token.
 */
function setupAxiosDefaultAuthorization(accessToken: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}
