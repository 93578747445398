import React, { useMemo, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { FilterAssignment } from "@/pages/awareness/courses/utils/filterAssignmentEnum";
import StickyHeader from "@/components/StickyHeader";
import { useLearningDepartments } from "@/utils/misc";
import { SearchField } from "@/components/SearchField";
import { debounce } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { selectUsers } from "@/pages/awareness/userSlice";
import { ConfirmationDialog } from "@/components/modals/ConfirmationDialog";
import { useTranslation } from "react-i18next";

export const AwarenessUsersConfigFilterBar = ({
  setSearchValue,
  selectedDepartment,
  setSelectedDepartment,
  updateUseWebchat,
  isLoadingUpdate,
  messaging_platform,
}) => {
  const { departments } = useLearningDepartments();
  const users = useSelector(selectUsers);
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedUpdateSearch(e);
  };
  const onChangeFilterAssignment = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const contentDialog = t(
    "Are you sure you want to activate webchat for all displayed users ({{nb_users}}) ?",
    { nb_users: users.length },
  );
  const onConfirmActiveAllWebchat = () => {
    updateUseWebchat({
      users_id: users.map((user) => user.id),
      use_webchat: true,
    });
    setOpenDialog(false);
  };

  const debouncedUpdateSearch = useMemo(() => {
    const onSearchChange = (e) => {
      setSearchValue(e.target.value);
    };

    return debounce(onSearchChange, 300);
  }, [setSearchValue]);

  return (
    <StickyHeader height="70px">
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <FormControl sx={{ minWidth: "270px" }}>
            <InputLabel id="assignment-filters-select-label">
              {t("Department filter")}
            </InputLabel>
            <Select
              startAdornment={<PeopleIcon sx={{ marginRight: "10px" }} />}
              labelId="departments-filter-label"
              id="departments-filter-simple-select"
              label="Department filter"
              value={selectedDepartment}
              size={"small"}
              onChange={onChangeFilterAssignment}
              sx={{ borderRadius: "10px", width: "250px" }}
              renderValue={(selected) => {
                const selectedItem = [
                  { id: FilterAssignment.Everyone, name: t("Everyone") },
                  ...departments,
                ].find((item) => item.id === selected);
                return selectedItem ? selectedItem.name : "";
              }}
            >
              <MenuItem
                key={"everyone"}
                id={"select-everyone"}
                value={FilterAssignment.Everyone}
              >
                {t("Everyone")}
              </MenuItem>

              {departments.map((department) => (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SearchField value={searchTerm} onChange={onSearchChange} />
        </Grid>
        {messaging_platform !== "webchat" && (
          <Grid item>
            <LoadingButton
              loading={isLoadingUpdate}
              variant={"outlined"}
              onClick={() => setOpenDialog(true)}
            >
              {t("Activate webchat for all displayed users")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
      <ConfirmationDialog
        isShown={openDialog}
        onConfirm={onConfirmActiveAllWebchat}
        onConfirmText={t("Confirm")}
        onCancel={() => setOpenDialog(false)}
        onCancelText={t("Cancel")}
        title={t("Activate webchat for all displayed users")}
        content={contentDialog}
      />
    </StickyHeader>
  );
};
