import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TimeFrameDropdown({
  dateFromNbWeeksAgo,
  setDateFromNbWeeksAgo,
  setSelectedPeriod,
  intervalType = "weeks",
}) {
  const { t } = useTranslation();

  const weeksInterval = [
    { value: 1, label: `1 ${t("Week")}` },
    { value: 2, label: `2 ${t("Weeks")}` },
    { value: 4, label: `1 ${t("Month")}` },
    { value: 8, label: `2 ${t("Months")}` },
  ];

  const monthsInterval = [
    { value: 1, label: `1 ${t("Month")}` },
    { value: 3, label: `3 ${t("Months")}` },
    { value: 6, label: `6 ${t("Months")}` },
    { value: 12, label: `1 ${t("Year")}` },
  ];

  const handleChange = (event) => {
    setDateFromNbWeeksAgo(event.target.value);
    setSelectedPeriod((current) => ({ ...current, value: null }));
  };

  let intervals = intervalType === "months" ? monthsInterval : weeksInterval;

  return (
    <>
      <FormControl
        sx={{
          width: "150px",
        }}
      >
        <InputLabel size="small" htmlFor="timeframe-select">
          {t("Timeframe")}
        </InputLabel>
        <Select
          label={t("Timeframe")}
          id="timeframe-select"
          size="small"
          value={dateFromNbWeeksAgo}
          onChange={handleChange}
        >
          {intervals.map((item, index) => {
            return (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
