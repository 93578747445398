import SelectOrderByComponent from "./SelectOrderByComponent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { Button, Card, List, ListItem, Popover } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#061123",
          marginLeft: "10px",
          borderColor: "#0611233A",
        },
      },
    },
  },
});

interface Column {
  name: "string";
  label: string;
}
interface OrderBy {
  id: number;
  column: Column | null;
  label: string;
  order: string;
}

export default function DataPerUserSortComponent({ setOrderBy, orderChoices }) {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [listOrderBy, setListOrderBy] = useState<OrderBy[]>([]);

  const openComponent = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget);
    setIsModalOpen(true);
  };

  const closeComponent = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
    applyFilter();
  };

  const handleAddSort = () => {
    const tempListSort = [...listOrderBy];
    tempListSort.push({
      id: tempListSort.length,
      label: "",
      order: "asc",
      column: null,
    });
    setListOrderBy(tempListSort);
  };

  const handleRemoveItem = (index) => {
    const tempListSort = [...listOrderBy];
    tempListSort.splice(index, 1);
    setListOrderBy(tempListSort);
  };

  const handleOrderChange = (index, event) => {
    const tempListSort = [...listOrderBy];
    tempListSort[index].order = event.target.value;
    setListOrderBy(tempListSort);
  };

  const handleOrderLabelChange = (index, value) => {
    const tempListSort = [...listOrderBy];
    tempListSort[index].label = value ? value.name : "";
    tempListSort[index].column = value;
    setListOrderBy(tempListSort);
  };

  const applyFilter = useCallback(() => {
    setOrderBy(listOrderBy);
  }, [listOrderBy, setOrderBy]);

  return (
    <ThemeProvider theme={theme}>
      <Button
        startIcon={<FilterListIcon />}
        endIcon={isModalOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size="medium"
        variant="outlined"
        onClick={openComponent}
      >
        {t("Sort")}
      </Button>
      {isModalOpen && (
        <Popover
          open={isModalOpen}
          anchorEl={anchorEl}
          onClose={closeComponent}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Card sx={{ width: "400px" }} elevation={1}>
            <List dense>
              {listOrderBy.map((orderItem, index) => {
                return (
                  <SelectOrderByComponent
                    key={index + orderItem.toString()}
                    index={index}
                    orderChoice={orderChoices}
                    handleOrderChange={handleOrderChange}
                    handleOrderLabelChange={handleOrderLabelChange}
                    orderItem={orderItem}
                    handleRemoveItem={handleRemoveItem}
                  />
                );
              })}
              <ListItem>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddSort}
                  size="small"
                  sx={{ textTransform: "none" }}
                >
                  {t("Add Sort")}
                </Button>
              </ListItem>
              <ListItem sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={closeComponent}
                  size="small"
                  sx={{ textTransform: "none" }}
                  variant="contained"
                >
                  {t("Apply")}
                </Button>
              </ListItem>
            </List>
          </Card>
        </Popover>
      )}
    </ThemeProvider>
  );
}
