import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const BrowserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ fontSize: props.fontSize ? props.fontSize : 12 }}
      htmlColor={props.color ? props.color : "#98A5B3"}
      {...props}
    >
      <svg viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.1666 12H2.83325M2.83325 10.4L2.83325 21.6C2.83325 23.8402 2.83325 24.9603 3.29647 25.816C3.70394 26.5686 4.3541 27.1805 5.15379 27.564C6.06292 28 7.25303 28 9.63325 28H24.3666C26.7468 28 27.9369 28 28.846 27.564C29.6457 27.1805 30.2959 26.5686 30.7034 25.816C31.1666 24.9603 31.1666 23.8402 31.1666 21.6V10.4C31.1666 8.15979 31.1666 7.03969 30.7034 6.18404C30.2959 5.43139 29.6457 4.81947 28.846 4.43598C27.9369 4 26.7468 4 24.3666 4L9.63325 4C7.25303 4 6.06292 4 5.15379 4.43597C4.3541 4.81947 3.70394 5.43139 3.29647 6.18404C2.83325 7.03968 2.83325 8.15979 2.83325 10.4Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
