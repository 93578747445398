import { BackendSuspense } from "./BackendSuspense";
import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { Box } from "@mui/system";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";

export const NbEngagedUserCard = ({
  engagedUsersPercentage,
  error,
  isPending,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t(
        "Percent of users who started their awareness program (on track or on hold)",
      )}
    >
      <Card>
        <CardHeader
          avatar={
            <HowToRegOutlinedIcon sx={{ fontSize: "32px", color: "#7567FE" }} />
          }
          title={t("Engaged users")}
        />
        <CardContent>
          <Box display="flex" justifyContent="center">
            <BackendSuspense
              hasData={engagedUsersPercentage > 0}
              error={error}
              isPending={isPending}
            >
              <Typography variant="h3">{engagedUsersPercentage}%</Typography>
            </BackendSuspense>
          </Box>
        </CardContent>
      </Card>
    </Tooltip>
  );
};
