import React from "react";
import { Link } from "react-router-dom";

import "@/pages/my-progress/activity/UserActivity.css";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "@mui/material";

const STATUS_MESSAGES = {
  learning_followed: "micro-learning followed",
  clicked: "clicked",
  reported: "reported",
  opened: "opened",
  not_opened: "not opened",
  sent: "sent",
  completed: "course completed",
  viewed: "course followed",
};

export const UserActivity = ({ data, admin = false }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title={t("Activity")} />
      <CardContent>
        <div>
          <StarsCaption />
          <div className="activity-card-container">
            <ActivityCards data={data} admin={admin} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const ActivityCards = ({ data, admin = false }) =>
  data.map((activity) => (
    <ActivityCard key={activity.date} activity={activity} admin={admin} />
  ));

export const ActivityStatus = ({ status, className = "" }) => {
  const { t } = useTranslation();
  return (
    <div className={`activity-status-container ${className}`}>
      <div className={`activity-status activity-status-${status}`}>
        {t(STATUS_MESSAGES[status].toUpperCase())}
      </div>
    </div>
  );
};

export const ActivityDate = ({ date, className = "" }) => (
  <div className={`activity-date ${className}`}>
    <span>{new Date(date).toLocaleDateString()}</span>
  </div>
);

export const ActivityTitle = ({ title, className = "" }) => (
  <div className={`activity-title-zone ${className}`}>
    <div className="activity-title">{title}</div>
  </div>
);

const ActivityCard = ({ activity, admin = false }) => (
  <div className="activity-card">
    <ActivityStatus status={activity.status} />

    <ActivityDate date={activity.date} />

    <ActivityTitle title={activity.title} />

    <div className="activity-stars">
      <span>{"⭐".repeat(activity.stars)}</span>
    </div>

    <ActivityLink activity={activity} admin={admin} />
  </div>
);

const ActivityLink = ({ activity, admin = false }) => {
  let link;
  let external = false;
  const { t } = useTranslation();

  switch (activity.type) {
    case "email":
      const landingLink = new URL(import.meta.env.VITE_APP_LANDING);
      landingLink.searchParams.append("email_id", activity.id);
      landingLink.searchParams.append("admin", (!!admin).toString());
      landingLink.searchParams.append("tracking", "true");
      external = true;

      link = landingLink.toString();
      break;
    case "course":
      link = `/awareness/courses/${activity.id}`;
      break;
    default:
      console.error(`unknown data type ${activity.type}`);
  }

  if (link == null) return null;

  return (
    <div className="activity-link">
      {external ? (
        <a children={t("VIEW")} target="_blank" rel="noreferrer" href={link} />
      ) : (
        <Link children={t("VIEW")} target="_blank" rel="noreferrer" to={link} />
      )}
    </div>
  );
};

const StarsCaption = () => {
  const { t } = useTranslation();
  return (
    <div>
      <table className="activity-caption">
        <tbody>
          <tr>
            <td>
              <span role="img" aria-label="one star">
                ⭐
              </span>
            </td>
            <td>
              &nbsp;
              {t(
                "Email clicked and micro-learning followed OR Awareness module followed",
              )}
            </td>
          </tr>
          <tr>
            <td>
              <span role="img" aria-label="two stars">
                ⭐⭐
              </span>
            </td>
            <td>
              &nbsp;
              {t("Email opened but not clicked OR Awareness module completed")}
            </td>
          </tr>
          <tr>
            <td>
              <span role="img" aria-label="three stars">
                ⭐⭐⭐
              </span>
            </td>
            <td>&nbsp;{t("Email reported")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
