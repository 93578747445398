import axios from "axios";

export const downloadEmailMessages = async (
  getToken: CallableFunction,
  dateFrom: Date,
  dateTo: Date,
  department?: string,
) => {
  let url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/emailmessages/export/csv";
  let params = new URLSearchParams();

  const accessToken = await getToken();
  params.append("date_from", dateFrom.toISOString());
  params.append("date_to", dateTo.toISOString());
  if (department) {
    params.append("department", department);
  }

  url = url + "?" + params.toString();
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let a = document.createElement("a");
      a.href = url;
      a.download = "sent-emails.csv";
      a.click();
    });
};
