import { ButtonSpinner } from "@/components/Spinner";
import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { IconButton, Switch, Tooltip } from "@mui/material";
import ModalEngageAdditionalUsers from "@/pages/banners/users/ModalEngageAdditionalUsers";
import { Warning } from "@mui/icons-material";
import {
  APIBannerUser,
  EngagementStatus,
} from "@/pages/banners/remote-apis/bannerUsers";

type EngageSwitchProps = {
  handleEngageOrDisengageUser: (BannerToggleUsers) => void;
  disabled: boolean;
  status: EngagementStatus;
  email: string;
  disable_reason: string;
};

export const EngageSwitch: React.FC<EngageSwitchProps> = ({
  handleEngageOrDisengageUser,
  disabled = false,
  status,
  email,
  disable_reason = "Unknown",
}: EngageSwitchProps) => {
  const disableState = status === "disabled";

  const isTransientState =
    status === "engage_started" || status === "disengage_started";

  const switch_disabled = disabled || disableState || isTransientState;

  const shouldEngageOnCLick = status === "disengaged";
  const checked = status === "engaged" || status === "engage_started";

  const handleChange = () => {
    handleEngageOrDisengageUser({
      user_email: email,
      active: shouldEngageOnCLick,
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Switch
        size="small"
        disabled={switch_disabled}
        checked={checked}
        onChange={handleChange}
      />
      {isTransientState && (
        <ButtonSpinner size="1rem" color="primary" thickness={8} />
      )}
      {disableState && (
        <Tooltip title={disable_reason}>
          <Warning color="disabled" />
        </Tooltip>
      )}
    </div>
  );
};

export function UsersBannersListTable({
  users,
  handleEngageOrDisengageUser,
  deactivateAllUserActions,
}: {
  users: Array<APIBannerUser>;
  handleEngageOrDisengageUser: (BannerToggleUsers) => void;
  deactivateAllUserActions: boolean;
}) {
  const baseOptions = { filter: false, sort: true, searchable: true };

  const columns = [
    { name: "firstname", label: "Firstname", options: baseOptions },
    { name: "lastname", label: "Lastname", options: baseOptions },
    { name: "email", label: "Email", options: baseOptions },
    { name: "language", label: "Language", options: baseOptions },
    {
      name: "departments",
      label: "Departments",
      options: {
        ...baseOptions,
        customBodyRender: (value) => value.join(", "),
        sortCompare: (order) => (v1, v2) => {
          const o = order === "desc" ? 1 : -1;
          let res = 0;

          if (v1.data.length === 0) {
            res -= 1;
          }
          if (v2.data.length === 0) {
            res += 1;
          }

          if (v1.data.length !== 0 && v2.data.length !== 0) {
            res = v1.data[0].localeCompare(v2.data[0]);
          }

          return res * o;
        },
      },
    },
    {
      name: "banners_engagement_status",
      label: "Engaged",
      options: {
        ...baseOptions,
        empty: true,
        sortCompare: (order) => (v1, v2) => {
          /* We filter by a precise order, allowing state_started to be displayed first, before finished states.
                              This allows the administrator to see pending actions first. */
          const state_order = {
            disengage_started: 0,
            disengaged: 1,
            engaged: 2,
            engage_started: 3,
          };
          const o = order === "desc" ? 1 : -1;
          const value_v1 = state_order[v1.data];
          const value_v2 = state_order[v2.data];

          return (value_v1 - value_v2) * o;
        },
        customBodyRenderLite: (dataIndex) => (
          <EngageSwitch
            status={users[dataIndex].banners_engagement_status}
            email={users[dataIndex].email}
            disable_reason={users[dataIndex].disable_reason}
            handleEngageOrDisengageUser={handleEngageOrDisengageUser}
            disabled={deactivateAllUserActions}
          />
        ),
      },
    },
  ];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  class CustomToolbar extends React.Component {
    handleClick = () => {
      setConfirmationDialogOpen(true);
    };

    render() {
      return (
        <Tooltip title="Engage 100 users" placement="bottom">
          <span>
            <IconButton
              disabled={deactivateAllUserActions}
              onClick={this.handleClick}
            >
              <GroupAddIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
  }

  const options = {
    rowsPerPage,
    onChangeRowsPerPage: setRowsPerPage,
    print: false,
    rowsPerPageOptions: [10, 50, 100, 500],
    selectableRows: "none" as const,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  return (
    <div>
      <MUIDataTable
        title="Users Banners"
        data={users}
        columns={columns}
        options={options}
      />
      <ModalEngageAdditionalUsers
        confirmationDialogOpen={confirmationDialogOpen}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        users={users}
      />
    </div>
  );
}
