import { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export interface ReportThreatParams {
  report_id?: string;
  report_data?: string;
}

export interface MarkAsSafeParams {
  sender_address: string;
  recipient_address: string;
}

export const useReportThreat = () => {
  const [error, setError] = useState(null);
  const isError = useMemo(() => error !== null, [error]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const reportThreat = async (params: ReportThreatParams) => {
    const { report_data = null, report_id = null } = params;

    if (report_data === null && report_id === null) {
      setError({
        status: "INVALID_DATA",
        message: "Invalid Parameters: should have report_id or report_data",
      });
      return;
    }

    const accessToken = await getAccessTokenSilently();

    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/banners/report_as_threat",
        params,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return { reportThreat, isLoading, isError, error };
};

export const useMarkAsSafe = () => {
  const [error, setError] = useState(null);
  const isError = useMemo(() => error !== null, [error]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const markAsSafe = async (params: MarkAsSafeParams) => {
    const accessToken = await getAccessTokenSilently();

    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/banners/mark_as_safe",
        params,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return { markAsSafe, isLoading, isError, error };
};

export const useDisableThreatNotifications = () => {
  const [error, setError] = useState(null);
  const isError = useMemo(() => error !== null, [error]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const disableThreatNotifications = async () => {
    const accessToken = await getAccessTokenSilently();

    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/threats/disable_notifications",
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return { disableThreatNotifications, isLoading, isError, error };
};
