import React, { useEffect, useState } from "react";
import { default as LgThreats } from "@/pages/threatslg/ThreatsLG";
import { Threats as NewThreats } from "@/pages/newthreats/Threats";
import { FullPageSpinner } from "@/components/Spinner";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { parseJwt } from "@/utils/misc";
import { useIsNewThreatsUIEnabledQuery } from "@/pages/threatslg/api";

type ThreatsFeatureFlag = "old" | "lg";

export const ThreatsFeatureGate = () => {
  const [feature, setFeature] = useState<ThreatsFeatureFlag | null>(null);
  const { data: isNewThreatsEnabled } = useIsNewThreatsUIEnabledQuery();

  const userContext = useUserContext();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const f = async () => {
      if (isNewThreatsEnabled?.enabled == null) {
        return;
      }

      const email = userContext.email;
      const isFromMantra = email.endsWith("@mantra.ms");

      const accessToken = await getAccessTokenSilently();
      const decodedToken = parseJwt(accessToken);
      const nonImpersonatedEmail = decodedToken["https://mantra.ms/email"];

      const urlParams = new URLSearchParams(window.location.search);
      const uiParam = urlParams.get("threats-ui");

      if (uiParam === "legacy") {
        setFeature("old");
      } else if (isNewThreatsEnabled?.enabled) {
        setFeature("lg");
      } else if (
        nonImpersonatedEmail === "bruno@mantra.ms" ||
        nonImpersonatedEmail === "guillaume@mantra.ms" ||
        nonImpersonatedEmail === "lucas@mantra.ms"
      ) {
        setFeature("lg");
      } else if (isFromMantra) {
        setFeature("lg");
      } else {
        setFeature("old");
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently, isNewThreatsEnabled, userContext.email]);

  if (feature === null) {
    return <FullPageSpinner />;
  }

  return feature === "lg" ? <LgThreats /> : <NewThreats />;
};
