import React from "react";
import Box from "@mui/material/Box";

type SelectedCountProps = {
  count: number;
};

const SelectedCount = ({ count }: SelectedCountProps) => {
  if (!count) return null;

  return <Box sx={{ marginRight: "10px" }}>{count} selected</Box>;
};

export default SelectedCount;
