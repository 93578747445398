import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { selectSetupChecklistState } from "../SetupChecklistSlice";
import { useTranslation } from "react-i18next";

const ProvisionAtLeastOneOtherUser: React.FC = () => {
  const { t } = useTranslation();

  const { has_at_least_one_non_admin_user: hasAtLeastOneNonAdminUser } =
    useSelector(selectSetupChecklistState);

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        {t("Provision at least one other user")}
      </Typography>
      <Typography>
        {t(
          "To ensure utility of the product, you need to provision at least one non admin user.",
        )}
      </Typography>
      <Box display="flex" alignItems="center" gap="1.5rem">
        <Typography>{t("You can provision them here") + " "}:</Typography>
        <Link to="/general-settings#users-provisioning">
          <Button variant="contained">{t("Go to General Settings")}</Button>
        </Link>
        <Box marginLeft="1.5rem" display="flex" gap="0.5rem">
          {hasAtLeastOneNonAdminUser ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_small" color="#2e7d32">
                {t("It works!")}
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography color="#ed3b5b">{t("Not connected")}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProvisionAtLeastOneOtherUser;
