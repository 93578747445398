import React, { useMemo, useState } from "react";
import { BannerLevel } from "@/pages/threatslg/threatslg_types";
import {
  Autocomplete,
  Button,
  Card,
  ClickAwayListener,
  Popper,
  TextField,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";

type FilterToolsProps = {
  value: {
    bannerLevel: BannerLevel | "none" | null;
    hasAttachments: boolean | null;
  };
  onChange: (
    level: BannerLevel | "none" | null,
    attachments: boolean | null,
  ) => void;
};

export const FilterTools = (props: FilterToolsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bannerLevel, setBannerLevel] = useState(props.value.bannerLevel);
  const [hasAttachments, setHasAttachments] = useState(
    props.value.hasAttachments,
  );

  const selectedBannerLevel = useMemo(() => {
    switch (bannerLevel) {
      case "info":
      case null:
        return "All";
      case "none":
        return "No banner";
      case "suspicious":
        return "Yellow";
      case "very-suspicious":
        return "Red";
    }
  }, [bannerLevel]);

  const selectedAttachments = useMemo(() => {
    switch (hasAttachments) {
      case null:
        return "All";
      case true:
        return "Yes";
      case false:
        return "No";
    }
  }, [hasAttachments]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(!isModalOpen);
  };

  const applyFilter = () => {
    setIsModalOpen(false);
    props.onChange(bannerLevel, hasAttachments);
  };

  return (
    <>
      <Button
        endIcon={isModalOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size={"medium"}
        variant={"outlined"}
        onClick={handleClick}
      >
        <FilterListIcon />
      </Button>
      {isModalOpen && (
        <ClickAwayListener onClickAway={applyFilter}>
          <Popper
            open={isModalOpen}
            anchorEl={anchorEl}
            placement={"bottom-start"}
            style={{ zIndex: 120 }}
            disablePortal={true}
          >
            <Card sx={{ width: "175px" }} elevation={1}>
              <Autocomplete
                sx={{ width: "150px", margin: "10px", marginTop: "20px" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField {...params} label="Banner Level" />
                )}
                value={selectedBannerLevel}
                onChange={(_, value) => {
                  switch (value) {
                    case "All":
                      setBannerLevel(null);
                      break;
                    case "No banner":
                      setBannerLevel("none");
                      break;
                    case "Yellow":
                      setBannerLevel("suspicious");
                      break;
                    case "Red":
                      setBannerLevel("very-suspicious");
                      break;
                  }
                }}
                options={["All", "No banner", "Yellow", "Red"]}
              />
              <Autocomplete
                sx={{ width: "150px", margin: "10px", marginTop: "20px" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField {...params} label="Has attachments" />
                )}
                value={selectedAttachments}
                onChange={(_, value) => {
                  switch (value) {
                    case "All":
                      setHasAttachments(null);
                      break;
                    case "Yes":
                      setHasAttachments(true);
                      break;
                    case "No":
                      setHasAttachments(false);
                      break;
                  }
                }}
                options={["All", "Yes", "No"]}
              />
              <Box
                sx={{
                  width: "150px",
                  margin: "10px",
                  marginTop: "20px",
                  marginBottom: "0px",
                }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant={"contained"}
                  size={"small"}
                  onClick={applyFilter}
                >
                  Apply
                </Button>
              </Box>
            </Card>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
