import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

export function useStopSimulations() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const stopSimulations = async (
    setLoading,
    closeConfirmation,
    refetchState,
  ) => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    try {
      await axios
        .post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/company/simulation/stop",
          {},
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(() => {
          enqueueSnackbar(t("Automatic courses deactivated"), {
            variant: "success",
          });
        });
    } catch (error) {
      enqueueSnackbar(t("Failed to deactivate automatic courses"), {
        variant: "error",
      });
    }
    refetchState();
    setLoading(false);
    closeConfirmation();
  };
  return { stopSimulations };
}
