import React, { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import { UserList } from "@/pages/general-settings/commonUserProvisioning/UserList";
import { GroupList } from "@/pages/general-settings/commonUserProvisioning/GroupList";
import axios from "axios";
import { useTranslation } from "react-i18next";

export function ManualSync() {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const statusColor = (success) => (success ? "black" : "red");

  const syncNow = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await axios.get(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION_HEAVY_DUTY +
          `/provisioning/current_company/provision_now`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      response.data.generalFailure = response.data?.progress?.some(
        (s) => !s.success,
      );
      response.data.membersFailure = response.data?.members?.failed?.length > 0;
      setResult(response.data);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(extractErrorMessage(error));
      setResult(null);
    } finally {
      setLoading(false);
    }
  };
  const { t } = useTranslation();

  return (
    <div>
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => syncNow()}
        disabled={loading}
      >
        {t("Provision now")}
      </Button>
      <div>
        <br />
        {loading && <CircularProgress size={20} />}
        {result && (
          <Typography component={"span"} style={{ color: "black" }}>
            {result.generalFailure && (
              <div className="mb-4">
                <h6 style={{ color: "red" }}>{t("Process failed partway")}</h6>
                {result.progress?.map((p) => {
                  return (
                    <div style={{ color: statusColor(p.success) }} key={p.step}>
                      {p.step} {p.success ? "✔️" : "X"}
                    </div>
                  );
                })}
              </div>
            )}
            {result.membersFailure && (
              <div className="mb-4">
                <h6 style={{ color: "red" }}>
                  Failed to obtain members of some groups
                </h6>
                {result.members?.failed?.map((g) => {
                  return (
                    <div style={{ color: "red" }} key={g}>
                      {g + " X"}
                    </div>
                  );
                })}
                {result.members?.resolved?.map((g) => {
                  return (
                    <div style={{ color: "red" }} key={g}>
                      {g + " ✔"}
                    </div>
                  );
                })}
              </div>
            )}
            <UserList
              title="Users Added"
              users={result.users.filter((u) => u.status === "To add")}
            />
            <UserList
              title="Users Linked"
              users={result.users.filter((u) => u.status === "To link")}
            />
            <UserList
              title="Users Updated"
              users={result.users.filter((u) => u.status === "To update")}
            />
            <UserList
              title="Users Deactivated"
              users={result.users.filter((u) => u.status === "To deactivate")}
            />
            <GroupList title="Groups Added" groups={result.groups.added} />
            <GroupList title="Groups Updated" groups={result.groups.updated} />
            <GroupList title="Groups Deleted" groups={result.groups.deleted} />
            <UserList
              title="Users not in provisioned Groups (deactivated)"
              users={result.deactivation_wlg}
            />
          </Typography>
        )}
        {errorMessage !== null && (
          <Typography style={{ color: "red" }}>{errorMessage}</Typography>
        )}
      </div>
    </div>
  );
}
