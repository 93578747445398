import React, { useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { enqueueSnackbar } from "notistack";

const CallbackButton = styled.button`
  background: #4007ed;
  border-radius: 100px;
  padding: 10px 40px;

  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
`;

const ComingSoonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;
  color: #140040;

  height: 100%;
  padding: 5% 20%;

  & > h1 {
    font-size: 46px;
    font-weight: 600;

    margin-bottom: 5%;
  }

  & > p {
    font-size: 30px;
    font-weight: 400;

    margin-bottom: 10%;
  }

  & > #subtext {
    font-style: italic;
    font-weight: 300;
    font-size: 20px;

    color: #999999;
  }
`;

export function ComingSoon() {
  const [salesNotified, setSalesNotified] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleClick = async (event) => {
    event.preventDefault();
    if (!salesNotified) {
      setSalesNotified(true);
      // make a call to simulation to email the sales team
      const accessToken = await getAccessTokenSilently();
      try {
        await axios.post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/banners/feature_interest",
          null,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (error) {
        enqueueSnackbar(extractErrorMessage(error));
        setSalesNotified(false);
      }
    }
  };

  return !salesNotified ? (
    <ComingSoonContainer>
      <h1>Empower your teams against advanced phishing threats</h1>
      <p>
        Provide employees intuitive in-the-moment feedback about incoming
        attacks bypassing classic native protections.
      </p>
      <span id="subtext">Want to learn more?</span>
      <CallbackButton onClick={handleClick}>Request a callback</CallbackButton>
    </ComingSoonContainer>
  ) : (
    <ComingSoonContainer>
      <h1>Callback request sent &#10004;</h1>
      <p>We will get back to you shortly</p>
    </ComingSoonContainer>
  );
}
