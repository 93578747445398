import React from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS } from "chart.js";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Spinner } from "@/components/Spinner";
import { CircleIcon } from "@/components/icons/CircleIcon";
import { PermissionsIcon } from "@/components/icons/PermissionsIcon";
import { AttachmentsIcon } from "@/components/icons/AttachmentsIcon";
import { DownloadsIcon } from "@/components/icons/DownloadsIcon";
import { CredentialsIcon } from "@/components/icons/CredentialsIcon";
import { useTranslation } from "react-i18next";
import { computeURLFromProps } from "./BasicStats/BasicStats";
import { useBackend } from "@/utils/misc";

ChartJS.register(ArcElement);

const credentialsColor = "#19439e";
const attachmentsColor = "#1662ff";
const downloadsColor = "#4ca2ed";
const permissionsColor = "#a7bef1";
const percentage = (value: number, dataset: number[]) => {
  const sum = dataset.reduce((a, b) => a + b, 0);
  if (sum === 0) {
    return 0;
  }
  return Math.floor((value / sum) * 100);
};

let data = {
  datasets: [
    {
      data: [],
      backgroundColor: [
        credentialsColor,
        attachmentsColor,
        downloadsColor,
        permissionsColor,
      ],
    },
  ],
};

const chartConfig = {
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: (context: any) => {
          return percentage(context.parsed, context.dataset.data) + " %";
        },
      },
    },
    datalabels: {
      color: "#fff",
      formatter: (value: any, context: any) => {
        const formattedValue = percentage(value, context.dataset.data);
        if (formattedValue === 0) {
          return "";
        }
        return formattedValue + "%";
      },
      font: {
        weight: 600,
      },
    },
  },
};

const CompromisingActionLabel = ({
  title,
  color,
  data,
  icon,
}: {
  title: string;
  color: string;
  data: number;
  icon: JSX.Element;
}) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" gap="5px">
        {icon}
        <Typography variant="body_small" color="text.secondary">
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap="5px">
        <CircleIcon color={color} size="12px" />
        <Typography variant="body_small" color="text.secondary">
          {data}
        </Typography>
      </Stack>
    </Box>
  );
};

const CompromisingActions = (props: any) => {
  const { t } = useTranslation();
  const stats_url = computeURLFromProps(props);
  const { data: dataset, error, isPending } = useBackend(stats_url.toString());
  let body: JSX.Element;

  if (dataset == null || isPending || error !== null) {
    return (
      <Card>
        <CardHeader title={t("Compromising actions")} />
        <CardContent>
          <Spinner />
        </CardContent>
      </Card>
    );
  }

  data.datasets[0].data = [
    dataset.nbCredential,
    dataset.nbAttachment,
    dataset.nbDownload,
    dataset.nbPermission,
  ];

  body = (
    <Stack spacing={3}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Doughnut
          key={data.datasets[0].data.reduce((x, y) => x + y, Math.random())}
          data={data}
          height={200}
          width={540}
          options={chartConfig}
          plugins={[ChartDataLabels] as any}
        />
      </Box>
      <Stack direction="row" justifyContent="space-around">
        <CompromisingActionLabel
          title={t("Credentials")}
          color={credentialsColor}
          icon={<CredentialsIcon />}
          data={dataset.nbCredential}
        />
        <CompromisingActionLabel
          title={t("Attachments")}
          color={attachmentsColor}
          icon={<AttachmentsIcon />}
          data={dataset.nbAttachment}
        />
        <CompromisingActionLabel
          title={t("Downloads")}
          color={downloadsColor}
          icon={<DownloadsIcon />}
          data={dataset.nbDownload}
        />
        <CompromisingActionLabel
          title={t("Permissions")}
          color={permissionsColor}
          icon={<PermissionsIcon />}
          data={dataset.nbPermission}
        />
      </Stack>
    </Stack>
  );

  return (
    <Card>
      <CardHeader title={t("Compromising actions")} />
      <CardContent>{body}</CardContent>
    </Card>
  );
};

export default CompromisingActions;
