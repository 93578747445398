import React, { useCallback, useEffect } from "react";
import { useSimulationResource } from "@/utils/ResourceGet";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { SIMULATION_SCRIBEHOW_ADD_COMPANY_SIGNATURE } from "@/utils/constants";

function LinkToDocumentation() {
  const { t } = useTranslation();

  return (
    <p>
      {t("You can follow") + " "}
      <span
        style={{
          color: "#0000FF",
          fontWeight: 600,
        }}
      >
        <a
          href={SIMULATION_SCRIBEHOW_ADD_COMPANY_SIGNATURE}
          target="_blank"
          rel="noreferrer"
        >
          {t("this documentation")}
        </a>
      </span>{" "}
      {t("to find out how to get the HTML code for your signature.")}
    </p>
  );
}

export function SignatureManager() {
  const { t } = useTranslation();

  const [companySignature, setCompanySignature] = React.useState("");
  const [companySignaturePreview, setCompanySignaturePreview] =
    React.useState("");
  const [initialSignature, initialSignatureError] = useSimulationResource(
    "company/signature",
    null,
  );

  // signatureToIgnore is used with initialSignature to not save the signature upon first load.
  const [signatureToIgnore, setSignatureToIgnore] = React.useState("");

  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  const handleSignatureChange = async (event) =>
    setCompanySignature(event.target.value);

  const handleSignatureUpdate = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      await axios
        .put(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company/signature",
          {
            company: {
              signature: companySignature,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        )
        .then(() => snackbar.showMessage("Signature Updated"));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  }, [companySignature, getAccessTokenSilently, snackbar]);

  const handlePreviewUpdate = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      await axios
        .get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/company/preview_signature",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        )
        .then((response) =>
          setCompanySignaturePreview(response.data.company.rendered_signature),
        );
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  }, [getAccessTokenSilently, snackbar]);

  const updateSignatureAndPreview = useCallback(
    async (event) => {
      await handleSignatureUpdate().then(handlePreviewUpdate);
    },
    [handlePreviewUpdate, handleSignatureUpdate],
  );

  useEffect(() => {
    if (initialSignature !== null) {
      setCompanySignature(initialSignature.company.signature);
      setSignatureToIgnore(initialSignature.company.signature);
      handlePreviewUpdate().then();
    } else {
      setCompanySignature("");
    }
  }, [getAccessTokenSilently, initialSignature, handlePreviewUpdate]);

  useEffect(() => {
    if (companySignature !== signatureToIgnore) {
      const timeoutId = setTimeout(updateSignatureAndPreview, 1000);
      setSignatureToIgnore(null); // at this point company signature will never be equal to signatureToIgnore.
      return () => clearTimeout(timeoutId);
    }
  }, [
    companySignature,
    updateSignatureAndPreview,
    initialSignature,
    signatureToIgnore,
  ]);

  const tag1 = "{{ impersonated_firstname }}";
  const tag2 = "{{ impersonated_lastname }}";
  const tag3 = "{{ impersonated_title }}";
  const tag4 = "{{ impersonated_email }}";

  return (
    <>
      <LinkToDocumentation />
      <div className="row">
        <div className="col-md-6">
          <h4>{t("Signature Code (HTML)")}</h4>
          <textarea
            name="textarea"
            className="form-control"
            value={companySignature}
            onChange={handleSignatureChange}
            rows={10}
            placeholder={t("Enter the Company signature here <HTML>")}
          />
        </div>
        <div className="col-md-6">
          <h4>{t("Signature Preview (CEO)")}</h4>
          <iframe
            sandbox=""
            title={t("Signature Preview")}
            srcDoc={companySignaturePreview}
            className="border border-secondary rounded"
            width="100%"
            height="87.7%"
            frameBorder="1"
          />
          <div></div>
        </div>
      </div>
      <div className="col-md-6">
        <div style={{ height: "10px" }} />
        {t(
          "You can use the tags bellow to replace impersonated person's information:",
        )}
        <ul>
          <li>{tag1}</li>
          <li>{tag2}</li>
          <li>{tag3}</li>
          <li>{tag4}</li>
        </ul>
      </div>
      {initialSignatureError !== null && (
        <div className="row">
          <Typography style={{ color: "red" }}>
            {initialSignatureError.message}
          </Typography>
        </div>
      )}
    </>
  );
}
