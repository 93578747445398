import React from "react";
import { Alert, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";
import { useUserContext } from "@/utils/contexts/UserContext";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import DownloadsPolicyForm from "@/pages/browser-defender/setup/DownloadsPolicyForm";
import DownloadsPolicyTable from "@/pages/browser-defender/setup/DownloadsPolicy";
import { Spinner } from "@/components/Spinner";

const DownloadsPolicies = () => {
  const [availableMediaTypes, setAvailableMediaTypes] = React.useState([]);
  const [downloadsPolicies, setDownloadsPolicies] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchAvailableMediaTypes = React.useCallback(async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const url = new URL(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/settings/downloads_media_types`,
      );

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setAvailableMediaTypes(resource.data);
      setLoading(false);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  const fetchDownloadsPolicies = React.useCallback(async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const url = new URL(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/settings/downloads_policies`,
      );

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setDownloadsPolicies(resource.data);
      setLoading(false);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  const createDownloadsPolicy = async (payload) => {
    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/downloads_policies`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage("An error occurred while saving your configuration!");
      sentryCaptureException(error);
    }

    setDownloadsPolicies(resource.data);
  };

  const updateDownloadsPolicy = async (payload) => {
    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.put(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/downloads_policies`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage("An error occurred while saving your configuration!");
      sentryCaptureException(error);
    }

    setDownloadsPolicies(resource.data);
  };

  const deleteDownloadsPolicy = async (policyId) => {
    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.delete(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/downloads_policies/${policyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage("An error occurred while deleting the policy!");
      sentryCaptureException(error);
      return;
    }

    setDownloadsPolicies(resource.data);
  };

  React.useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchDownloadsPolicies();
    fetchAvailableMediaTypes();
  }, [fetchAvailableMediaTypes, fetchDownloadsPolicies, info_user]);

  return (
    <GenericCard title={"Downloads protection policies"}>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box style={{ display: "flex", flexDirection: "row-reverse" }}>
            <DownloadsPolicyForm
              availableMediaTypes={availableMediaTypes}
              actionButtonCallback={createDownloadsPolicy}
              actionButtonText="Save policy"
              callToActionText="Add policy"
              dialogTitle="Add new policy"
              canEdit={true}
            />
          </Box>
          {downloadsPolicies.length > 0 && (
            <DownloadsPolicyTable
              availableMediaTypes={availableMediaTypes}
              actionButtonCallback={updateDownloadsPolicy}
              deleteButtonCallback={deleteDownloadsPolicy}
              downloadsPolicies={downloadsPolicies}
            />
          )}
        </>
      )}
    </GenericCard>
  );
};

export default DownloadsPolicies;
