import { useState } from "react";

export type PaginationData = {
  rowsPerPage: number;
  currentPage: number;
  startIndex: number;
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
};

export const usePaginationData: () => PaginationData = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(0);

  return {
    rowsPerPage: rowsPerPage,
    startIndex: startIndex,
    currentPage: Math.floor(startIndex / rowsPerPage),
    changePage: (pageNumber: number) => setStartIndex(pageNumber * rowsPerPage),
    changePageSize: (pageSize: number) => setRowsPerPage(pageSize),
  };
};
