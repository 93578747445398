import DetailsTabs from "@/pages/threatslg/components/details_pane/details/content/DetailsTabs";
import { DetailsTab, Threat } from "@/pages/threatslg/threatslg_types";
import React from "react";
import Box from "@mui/material/Box";
import DownloadEmlButton from "@/pages/threatslg/components/details_pane/details/content/DownloadEmlButton";

const Toolbar = ({
  threat,
  onChange,
  activeTab,
}: {
  onChange: (tab: DetailsTab) => void;
  activeTab: DetailsTab;
  threat: Threat;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <DetailsTabs onChange={onChange} activeTab={activeTab} />
      <DownloadEmlButton threat={threat} />
    </Box>
  );
};

export default Toolbar;
