import React, { useState } from "react";
import { Button, CardHeader, Card, CardContent } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import { ExportCSVLink } from "@/pages/general-settings/UsersManagement/csvImport/ExportCSVLink";
import axios from "axios";

export function FileUpload({ setStep, setUsers }) {
  const { getAccessTokenSilently } = useAuth0();
  const [errors, setErrors] = useState<any[]>();
  const [networkError, setNetworkError] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);

  const fileSelected = () => {
    setIsFileSelected(true);
  };

  // Upload the file to validate and retrieve the users as a json response
  const uploadFile = async () => {
    let formData = new FormData();
    const file = document.querySelector<HTMLInputElement>("#file");
    formData.append("users", file.files[0]);
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/users/csv_import/validate",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      // no network error
      setNetworkError("");

      const data = await response.data;

      // Clear file to allow re-upload
      (document.getElementById("file") as HTMLInputElement).value = null;
      setIsFileSelected(false);

      // If there is an error, set it for display
      if (data.error) {
        setErrors(data.error);
      } else {
        // No errors
        // Hide the error panel
        setErrors(null);
        // Bubble up the users
        setUsers(data.data);
        // Go to the next step
        setStep(2);
      }
    } catch (error) {
      setNetworkError(extractErrorMessage(error));
    }
  };

  return (
    <div>
      <Card>
        <CardHeader title="Import Users - Step 1/4: File Upload" />
        <CardContent>
          <div>
            You can create or update your users by uploading a{" "}
            <b>UTF-8 encoded CSV file</b> containing all your users. You can
            download a{" "}
            <a href="https://storage.googleapis.com/mantrams-frontend/sample.csv">
              sample CSV file here.
            </a>
            <br />
            You can also <ExportCSVLink />.
            <br />
            <br />
            <input type="file" name="file" id="file" onChange={fileSelected} />
            <br />
            <br />
            {errors && (
              <div>
                The uploaded file contained the following errors:
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Line</th>
                      <th scope="col">Field</th>
                      <th scope="col">Value</th>
                      <th scope="col">Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errors.map((error) => (
                      <tr key={error.name + error.file_row}>
                        <td>{error.file_row}</td>
                        <td>{error.field}</td>
                        <td>{error.value}</td>
                        <td>{error.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {networkError !== "" && (
              <div style={{ color: "red" }}>{networkError}</div>
            )}
            <Button
              variant="contained"
              onClick={uploadFile}
              disabled={!isFileSelected}
            >
              Next
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
