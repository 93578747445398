import React, { useState } from "react";
import EmailBody from "./EmailBody";
import EmailHeaders from "@/pages/threatslg/components/details_pane/details/content/EmailHeaders";
import { Button, Skeleton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DetailsTab, Threat } from "@/pages/threatslg/threatslg_types";
import Box from "@mui/material/Box";
import Toolbar from "@/pages/threatslg/components/details_pane/details/content/Toolbar";

type ContentProps = {
  threat: Threat;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

const Content = ({ threat, expanded, setExpanded }: ContentProps) => {
  const [activeTab, setActiveTab] = useState<DetailsTab>("body");

  const showContent = threat.reporter != null;

  let content: React.ReactElement;

  if (expanded || showContent) {
    switch (activeTab) {
      case "headers":
        content = <EmailHeaders headers={threat.details.headers} />;
        break;
      default:
        content = <EmailBody body={threat.details.body} />;
        break;
    }
  } else {
    content = (
      <>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            variant="outlined"
            color="neutral"
            sx={{ flex: "0 0 auto" }}
            onClick={() => setExpanded(true)}
            startIcon={<VisibilityIcon />}
          >
            View body
          </Button>
        </Box>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
      </>
    );
  }

  return (
    <>
      <Toolbar threat={threat} activeTab={activeTab} onChange={setActiveTab} />
      <Box sx={{ flex: 1, overflowY: "auto" }}>{content}</Box>
    </>
  );
};

export default Content;
