import React, { useCallback, useState } from "react";
import { Button, Grid } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { extractErrorMessage } from "@/utils/misc";
import { useSnackbar } from "material-ui-snackbar-provider";
import { ButtonSpinner } from "@/components/Spinner";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  selectIsDemoAcme,
  selectSearchValue,
  setSearch,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useDispatch, useSelector } from "react-redux";
import { SearchField } from "@/components/SearchField";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

export const ResearchBar = ({ onClickSubscribeAll }) => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const isDemoAcme = useSelector(selectIsDemoAcme);
  const searchValue = useSelector(selectSearchValue);
  const { t } = useTranslation();

  const onSearchChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const [isCreatingCourse, setIsCreatingCourse] = useState(false);
  const addCourse = useCallback(async () => {
    setIsCreatingCourse(true);
    try {
      const { data } = await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/courses/`,
      );
      snackbar.showMessage("Course successfully created");
      navigate(`/awareness/courses/${data.id}/?created=true`);
      return true;
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
    setIsCreatingCourse(false);
  }, [navigate, snackbar]);

  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        gap: "1rem",
        display: "flex",
        paddingBottom: "10px",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Grid item>
        <SearchField value={searchValue} onChange={onSearchChange} />
      </Grid>
      <Grid item>
        <Button
          sx={{ marginLeft: "auto" }}
          size="small"
          color={"primary"}
          onClick={onClickSubscribeAll}
          startIcon={<MenuBookIcon />}
        >
          {t("Assign all courses")}
        </Button>
        <Button
          sx={{ marginLeft: "15px" }}
          size="small"
          variant="outlined"
          color={"primary"}
          startIcon={isCreatingCourse ? <ButtonSpinner /> : <AddIcon />}
          onClick={addCourse}
        >
          {t("Create course")}
        </Button>
        {isDemoAcme && (
          <Button
            size="small"
            variant="contained"
            color={"primary"}
            sx={{
              marginLeft: "5px",
              background: "linear-gradient(90deg, #7CBEE4 0%, #B461F5 100%)",
            }}
            startIcon={<AutoAwesomeIcon />}
          >
            {t("Create course with AI")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
