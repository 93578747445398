import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";
import React from "react";
import { SimulationSetupChecklist } from "./SimulationSetupChecklist";
import { Preferences } from "./Preferences";
import { DeliveryTest } from "./DeliveryTest";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { useTranslation } from "react-i18next";

const InnerSetup: React.FC = () => {
  const { currentTabComponent } = useTabsContext();
  const { t } = useTranslation();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">{t("Simulation Setup")}</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      anchor: "setup-checklist",
      label: t("Setup Checklist"),
      component: <SimulationSetupChecklist />,
    },
    {
      anchor: "delivery-test",
      label: t("Delivery Test"),
      component: <DeliveryTest />,
    },
    {
      anchor: "preferences",
      label: t("Preferences"),
      component: <Preferences />,
    },
  ];

  return (
    <TabsContextProvider value={{ tabs }}>
      <InnerSetup />
    </TabsContextProvider>
  );
};
