import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";
import TaskIcon from "@mui/icons-material/Task";
import { Spinner } from "@/components/Spinner";
import { Link } from "react-router-dom";
import {
  BROWSER_SCRIBEHOW_CHROMEINTUNE_DEPLOYMENT_POLICY,
  BROWSER_SCRIBEHOW_EDGEINTUNE_DEPLOYMENT_POLICY,
  BROWSER_SCRIBEHOW_CHROMEGPO_DEPLOYMENT_POLICY,
  BROWSER_SCRIBEHOW_EDGEGPO_DEPLOYMENT_POLICY,
  BROWSER_SCRIBEHOW_BROWSER_DEPLOYMENT_POLICY,
} from "@/utils/constants";

const IntuneDeployment = ({ enterpriseToken }) => {
  function downloadScript(browser) {
    const browserDependantPath =
      browser === "edge" ? "Microsoft\\Edge" : "Google\\Chrome";
    const scriptContent = `
      New-Item -Path "HKLM:\\Software\\Policies\\${browserDependantPath}\\3rdparty\\extensions\\eohnjdeamkjliemanljkkjcepdfgcggl\\policy" -Force
      New-ItemProperty -Path "HKLM:\\Software\\Policies\\${browserDependantPath}\\3rdparty\\extensions\\eohnjdeamkjliemanljkkjcepdfgcggl\\policy" -Name "EnterpriseToken" -Value "${enterpriseToken}" -PropertyType "String" -Force
    `;
    const blob = new Blob([scriptContent], { type: "text/plain" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `mantra_browser_defender_intune_script_${browser}.ps1`;
    link.click();
  }

  return (
    <Box style={{ padding: "1rem" }}>
      <Divider textAlign="left">
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          Intune
        </Typography>
      </Divider>

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          Documentation for <b>Chrome</b> deployment
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_CHROMEINTUNE_DEPLOYMENT_POLICY,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          PowerShell script for <b>Chrome</b>
        </Typography>
        <IconButton
          onClick={() => downloadScript("chrome")}
          disabled={enterpriseToken ? false : true}
        >
          <FileDownloadIcon />
        </IconButton>
      </Stack>
      <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          Documentation for <b>Edge</b> deployment
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_EDGEINTUNE_DEPLOYMENT_POLICY,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          PowerShell script for <b>Edge</b>
        </Typography>
        <IconButton
          onClick={() => downloadScript("edge")}
          disabled={enterpriseToken ? false : true}
        >
          <FileDownloadIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

const GPODeployment = ({ enterpriseToken }) => {
  const [clipboardCopy, setClipboardCopy] = useState(false);

  function getToken() {
    navigator.clipboard.writeText(enterpriseToken);
    setClipboardCopy(true);
    setTimeout(() => setClipboardCopy(false), 2000);
  }

  return (
    <Box style={{ padding: "1rem", marginBottom: "2rem", marginTop: "2rem" }}>
      <Divider textAlign="left">
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          GPO
        </Typography>
      </Divider>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          Documentation for <b>Chrome</b> deployment
        </Typography>
        <IconButton
          onClick={() =>
            window.open(BROWSER_SCRIBEHOW_CHROMEGPO_DEPLOYMENT_POLICY, "_blank")
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          Documentation for <b>Edge</b> deployment
        </Typography>
        <IconButton
          onClick={() =>
            window.open(BROWSER_SCRIBEHOW_EDGEGPO_DEPLOYMENT_POLICY, "_blank")
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          Copy Enterprise Token
        </Typography>
        <IconButton
          onClick={getToken}
          disabled={enterpriseToken ? false : true}
        >
          {clipboardCopy ? <TaskIcon /> : <FileCopyIcon />}
        </IconButton>
      </Stack>
    </Box>
  );
};

const InternalCommunicationPerProvider = ({ links }) => {
  return (
    <Box>
      <Stack
        direction={"row"}
        spacing={2}
        style={{
          marginTop: "1rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: "#656E77" }}>Auto setup:</Typography>
        <Link to={links.auto.fr} target="_blank" rel="noopener">
          <Chip label="FR" variant="outlined" />
        </Link>
        <Link to={links.auto.en} target="_blank" rel="noopener">
          <Chip label="EN" variant="outlined" />
        </Link>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        style={{
          marginTop: "1rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: "#656E77" }}>Manual setup:</Typography>
        <Link href={links.manual.fr} target="_blank" rel="noopener">
          <Chip label="FR" variant="outlined" />
        </Link>
        <Link href={links.manual.en} target="_blank" rel="noopener">
          <Chip label="EN" variant="outlined" />
        </Link>
      </Stack>
    </Box>
  );
};

const InternalCommunication = ({ idProvider }) => {
  const msLinks = {
    auto: {
      fr: "https://www.notion.so/mantrams/11fe2913b23480bf8daeeaa2716227c0",
      en: "https://www.notion.so/mantrams/11fe2913b2348038b5b4ec1534db5188",
    },
    manual: {
      fr: "https://www.notion.so/mantrams/11fe2913b234801f80e2f5c344e80e13",
      en: "https://www.notion.so/mantrams/11fe2913b2348073b2d8c56f6808fb3c",
    },
  };

  const gwsLinks = {
    auto: {
      fr: "https://www.notion.so/mantrams/11fe2913b2348014933dfecfe175195c",
      en: "https://www.notion.so/mantrams/11fe2913b234801393bfdfda0f777dd8",
    },
    manual: {
      fr: "https://www.notion.so/mantrams/11fe2913b234804e9ecfd67a5e575044",
      en: "https://www.notion.so/mantrams/11fe2913b234803b8860f5c286e65ee8",
    },
  };

  return (
    <>
      <Divider
        textAlign="left"
        style={{ marginBottom: "1rem", marginTop: "3rem" }}
      >
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          Internal communication
        </Typography>
      </Divider>

      <Typography color={"#656E77"}>
        You can customize the following templates to communicate with your
        organization about the Browser Defender deployment.
      </Typography>

      {idProvider === "M365" && (
        <InternalCommunicationPerProvider links={msLinks} />
      )}
      {idProvider === "GWS" && (
        <InternalCommunicationPerProvider links={gwsLinks} />
      )}
    </>
  );
};

const MicrosoftSetup = ({ enterpriseToken }) => {
  return (
    <>
      <IntuneDeployment enterpriseToken={enterpriseToken} />
      <GPODeployment enterpriseToken={enterpriseToken} />
      <InternalCommunication idProvider={"M365"} />
    </>
  );
};

const GoogleSetup = ({ enterpriseToken }) => {
  const [clipboardCopy, setClipboardCopy] = useState(false);

  const jsonContent = JSON.stringify({
    EntrepriseToken: {
      Value: enterpriseToken,
    },
  });

  function copyJsonPolicy() {
    navigator.clipboard.writeText(jsonContent);
    setClipboardCopy(true);
    setTimeout(() => setClipboardCopy(false), 2000);
  }

  return (
    <Box style={{ padding: "1rem" }}>
      <Divider textAlign="left">
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          Google Workspace
        </Typography>
      </Divider>

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          View documentation for Chrome deployment
        </Typography>
        <IconButton
          onClick={() =>
            window.open(BROWSER_SCRIBEHOW_BROWSER_DEPLOYMENT_POLICY, "_blank")
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color={"#656E77"} sx={{ mb: "1rem" }}>
          Copy Enterprise Token policy to clipboard
        </Typography>
        <IconButton
          onClick={copyJsonPolicy}
          disabled={enterpriseToken ? false : true}
        >
          {clipboardCopy ? <TaskIcon /> : <FileCopyIcon />}
        </IconButton>
      </Stack>

      <InternalCommunication idProvider={"GWS"} />
    </Box>
  );
};

export function ManagedDeployment() {
  const [config, setConfig] = useState({
    enterpriseToken: null,
    idProvider: null,
  });
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchConfig = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = new URL(
        `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}/setup/company`,
      );

      let resource;

      try {
        resource = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setConfig({
            enterpriseToken: null,
            idProvider: null,
          });
          setLoading(false);
          return;
        }
      }

      setConfig({
        enterpriseToken: resource.data.enterprise_token,
        idProvider: resource.data.id_provider_configuration,
      });
      setLoading(false);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchConfig();
  }, [fetchConfig, info_user]);

  return (
    <>
      {loading && <Spinner />}

      {!loading && (
        <>
          <Typography variant="h5" style={{ marginBottom: "1rem" }}>
            Documentation & Resources
          </Typography>
          {config.idProvider.provider === "M365" && (
            <MicrosoftSetup enterpriseToken={config.enterpriseToken} />
          )}
          {config.idProvider.provider === "GWS" && (
            <GoogleSetup enterpriseToken={config.enterpriseToken} />
          )}
        </>
      )}
    </>
  );
}
