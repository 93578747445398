import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TrashIcon from "../icons/TrashIcon";

// need to use union type from MUI here.
type IconButtonColors =
  | "inherit"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
type IconButtonProps = {
  onClick: () => void;
  size?: number;
  title?: string;
  children?: React.FC | React.ReactNode;
};
const TrashIconButton = ({
  title = "",
  onClick = () => null,
  size = 16,
}: IconButtonProps) => {
  const [iconColor, setIconColor] = useState<IconButtonColors>("default");

  return (
    <Tooltip title={title && title.charAt(0).toUpperCase() + title.slice(1)}>
      <IconButton
        onClick={onClick}
        onMouseEnter={() => setIconColor("error")}
        onMouseLeave={() => setIconColor("default")}
        color={iconColor}
        sx={{
          alignSelf: "center",
          margin: "0",
        }}
      >
        <TrashIcon size={size} />
      </IconButton>
    </Tooltip>
  );
};

export default TrashIconButton;
