import React from "react";
import { Chart, Legend, Title, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

Chart.register(Legend, Title, Tooltip);

export function TimeCohortsChart({ title, color, values, labels, users }) {
  const { t } = useTranslation();

  const chartConfig = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => null,
          label: (tooltipItem) => tooltipItem.formattedValue + " %",
          afterLabel: function (tooltipItem) {
            switch (tooltipItem.label) {
              case labels[0]:
                return formatUserLabel(users.lt_3_months_users_number);
              case labels[1]:
                return formatUserLabel(users.bt_3_6_months_users_number);
              case labels[2]:
                return formatUserLabel(users.bt_6_9_months_users_number);
              case labels[3]:
                return formatUserLabel(users.gt_9_months_users_number);
              default:
                return tooltipItem;
            }
          },
        },
        displayColors: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        title: {
          display: true,
          text: t("User Seniority"),
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    padding: {
      bottom: 0,
    },
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: [color, color, color, color],
        hoverBackgroundColor: [color, color, color, color],
        borderColor: [color, color, color, color],
        barPercentage: 0.6,
        borderRadius: 5,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__ chart" style={{ height: "250px" }}>
        <Bar data={chartData} options={chartConfig} />
      </div>
    </div>
  );
}

function formatUserLabel(number) {
  return number === 1 ? number + " user" : number + " users";
}
