import React, { useMemo } from "react";
import { Align, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";

Chart.register(Legend, Tooltip);

export default function UserPerLevelChart({ labels, levelPercentages }) {
  const { t } = useTranslation();

  labels = labels.map((label) => {
    let textToAppend = "";
    if (label === "Beginner") {
      textToAppend = " (" + t("click rate") + " >= 20%)";
    } else if (label === "Intermediate") {
      textToAppend = " (" + t("click rate") + " < 20%)";
    } else if (label === "Expert") {
      textToAppend = " (" + t("click rate") + " = 0%)";
    }
    return t(label) + textToAppend;
  });

  const chartData = useMemo(() => {
    let background_color = ["#FF5F7C", "#4CA2ED", "#1dc9b7"];
    for (let i = 0; i <= levelPercentages.length; i++) {
      if (isNaN(levelPercentages[i])) {
        levelPercentages.splice(i, 1);
        background_color.splice(i, 1);
        labels.splice(i, 1);
      }
    }

    return {
      labels: labels,
      datasets: [
        {
          fill: true,
          data: levelPercentages,
          backgroundColor: background_color,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(labels), JSON.stringify(levelPercentages)]);

  const chartConfig = {
    rotation: -90,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "center" as Align,
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => {
          return value + "%";
        },
        font: {
          weight: 600,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            return context.formattedValue + "%";
          },
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 5,
        bottom: 5,
      },
    },
  };

  return (
    <div
      className="kt-widget12__chart"
      style={{ height: "250px", alignItems: "center" }}
    >
      <Doughnut
        data={chartData}
        options={chartConfig}
        width={540}
        height={250}
        plugins={[ChartDataLabels] as any}
      />
    </div>
  );
}
