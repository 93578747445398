import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function BasicTabs({
  tabsLabels,
  tabsContent,
  value,
  onChange,
}) {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box>
      <Box sx={{ paddingBottom: 1 }}>
        <Tabs value={value} onChange={handleChange}>
          {tabsLabels.map((item, index) => (
            <Tab label={item} key={index} />
          ))}
        </Tabs>
      </Box>
      <Box style={{ height: "77vh", overflow: "scroll" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          {tabsContent[value]}
        </Grid>
      </Box>
    </Box>
  );
}
