import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

export function ConsentDialog({
  open,
  onClose,
  onConsent,
  onCopyLink,
  copied,
  fullUrl,
}) {
  const { t } = useTranslation();
  const displayUrl = fullUrl ? fullUrl.substring(0, 40) + "..." : "";
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("Warning: Granting Consent")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            "You must use a Microsoft Global admin account to grant consent to Mantra.",
          )}
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            value={displayUrl}
            InputProps={{
              readOnly: true,
              style: { color: "grey" },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={onCopyLink}
                    color="primary"
                    variant="outlined"
                    startIcon={<ContentCopyIcon />}
                  >
                    {copied ? t("Copied!") : t("Copy link")}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined" color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={onConsent}
            variant="contained"
            color="primary"
            autoFocus
          >
            {t("Grant Consent")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
